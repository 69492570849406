import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import "./RootPage.scss";
import BannerAlert from "../../common/BannerAlert/BannerAlert";
import { useMetadata } from "core/MetadataContext/MetadataContext";
import { Link } from "@mui/material";

export function RootPage() {
  const { activeAlert, applicationVersion, masterDatasetVersion } =
    useMetadata();

  return (
    <Box className="root-page">
      <Link href="#main" className="skip-to-content">
        Skip to content
      </Link>
      <Header />
      {activeAlert ? <BannerAlert activeAlert={activeAlert} /> : null}
      <Box className="content">
        <Box className="outlet" id="main" component="div">
          <Outlet />
        </Box>

        <Box>
          <Footer
            applicationVersion={applicationVersion?.toString()}
            masterDatasetVersion={masterDatasetVersion}
          />
        </Box>
      </Box>
    </Box>
  );
}
