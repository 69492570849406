export const EnvironmentVariable = {
  localEnvConfig: {
    baseAPIURL: "REACT_APP_API_BASE_URL",
    accessToken: "REACT_APP_API_ACCESS_TOKEN",
    refreshToken: "REACT_APP_API_REFRESH_TOKEN",
  },
  idamConfig: {
    appURL: "REACT_APP_DOMAIN_URL",
    idamAPIURL: "REACT_APP_IDAM_API_URL",
    loginURL: "REACT_APP_IDAM_URL",
    idamLogoutURL: "REACT_APP_IDAM_LOGOUT_URL",
    clientId: "REACT_APP_IDAM_CLIENT_ID",
    clientSecret: "REACT_APP_IDAM_CLIENT_SECRET",
    responseType: "code",
    scope: "openid profile email groups role",
  },
  featureToggle: {
    hideMacroTool: "REACT_APP_FEATURE_HIDE_MICRO_TOOL",
    hideMacroAdjustments: "REACT_APP_FEATURE_HIDE_MACRO_ADJUSTMENTS",
  },
};
