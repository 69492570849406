import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ScenarioResults } from "../../components";
import { DateAndCountryInputSection } from "../../components/macro-hero-section";
import { MacroToolRoutesConfig } from "../../navigation/config";
import { CustomIcon, Icons, NoData, useGlobalLoader } from "components/common";
import {
  MacroResults,
  MacroScenarioFilterView,
  UpdateMacroScenarioDto,
  ViewMacroScenarioDto,
  Years,
} from "orval/generated/models";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { ScenarioHeroSection } from "components/common/ScenarioHeroSection/ScenarioHeroSection";
import { useMemberInfo } from "core/MemberInfoProvider/MemberInfoProvider";
import { AxiosError } from "axios";
import {
  generateMacroScenarioEmissionsExportScenariosMacroScenarioIdExportGet,
  getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey,
  useGetMacroScenarioByIdScenariosMacroScenarioIdGet,
  useGetYearsYearsGet,
  useProcessFilterViewForMacroScenarioScenariosMacroScenarioIdFilterViewPost,
  useUpdateMacroScenarioScenariosMacroScenarioIdPatch,
} from "orval/generated/endpoint";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { EnvironmentVariable } from "constants/EnvironmentVariables";
import useDocumentHead from "utils/useDocumentHead";
import ProductSelectionView from "features/macro-tool/components/ProductSelectionView/ProductSelectionView";
import Modal from "components/common/Modal/Modal";
import getFormattedDateForDownloads from "utils/getFormattedDateForDownloads";
import downloadBlob from "utils/downloadBlob/downloadBlob";
import { withCustomAxios } from "orval/mutator/custom-instance";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { ProductConfigurationsMacro } from "features/micro-tool/components/MicroAdjustmentsTable/types";

export type MacroToolScenarioPageProps = {
  getEnvironmentVariableValue: (key: string) => string | boolean | undefined;
};

const redirectURL = MacroToolRoutesConfig.countryLevelAnalytics;

function MacroToolScenarioPage({
  // TODO: remove getEnvironmentVariableValue from props
  // when feature toggle tests are no longer needed
  getEnvironmentVariableValue,
}: MacroToolScenarioPageProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: urlScenarioId } = useParams<{ id: string }>();
  const [resultsHidden, setResultsHidden] = useState<boolean>(false);
  const scenarioId = urlScenarioId || location.state?.id;
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [filterView, setFilterView] = useState<MacroScenarioFilterView | null>(
    null,
  );
  const [filterViewModalIsOpen, setFilterViewModalIsOpen] =
    useState<boolean>(false);

  const {
    data: scenario,
    isPending: scenarioIsPending,
    error: scenarioError,
  } = useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId));

  const [isExporting, setIsExporting] = useState(false);

  const { showGlobalLoader } = useGlobalLoader();

  const { data: years } = useGetYearsYearsGet();
  const [headersIndent, setHeadersIndent] = useState<string>(
    location.state?.headersIndent || "14px",
  );
  if (headersIndent !== "14px") {
    setTimeout(() => setHeadersIndent("14px"), 300);
  }

  useDocumentHead({
    titleAsTranslatedString: `${t("common:documentHeads:macroViewScenario")} ${scenario?.name}`,
  });

  useEffect(() => {
    if (scenarioError) {
      showSnackbar(scenarioError as AxiosError<unknown, any>, "info");
    }
  }, [scenarioError, scenarioIsPending, showSnackbar]);

  const {
    mutateAsync: updateMacroScenario,
    isPending: updateMacroScenarioIsPending,
  } = useUpdateMacroScenarioScenariosMacroScenarioIdPatch({
    mutation: {
      onSuccess: async () => {
        scenario &&
          scenario.id &&
          (await queryClient.invalidateQueries({
            queryKey:
              getGetMacroScenarioByIdScenariosMacroScenarioIdGetQueryKey(
                scenario.id,
              ),
          }));
        showSnackbar(t("notifications.scenarioUpdated"), "success");
      },
      onError: (error: AxiosError) => {
        showSnackbar(t("errorMessages.updateScenario"), "error");
        console.warn(error);
        return error;
      },
    },
  });

  const {
    mutateAsync: processFilterView,
    isPending: processFilterViewIsPending,
  } =
    useProcessFilterViewForMacroScenarioScenariosMacroScenarioIdFilterViewPost({
      mutation: {
        onError: (error: AxiosError) => {
          showSnackbar(t("errorMessages.processFilterViewError"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const handleRenameScenario = async (value: string) => {
    if (scenario && scenario.name !== value && scenario.id) {
      const _updateScenarioPayload: UpdateMacroScenarioDto = {
        name: value,
      };
      if (scenario.id) {
        try {
          await updateMacroScenario({
            scenarioId: scenario.id,
            data: _updateScenarioPayload,
          });
        } catch (error) {
          showSnackbar(t("errorMessages.duplicateName"), "error");
        }
      }
    }
  };

  const handleRenameDescription = async (value: string) => {
    const scenarioDescriptionHasBeenChanged =
      scenario && scenario.description !== value;
    if (scenarioDescriptionHasBeenChanged) {
      const scenarioUpdates: UpdateMacroScenarioDto = {
        description: value,
      };

      try {
        scenario.id &&
          (await updateMacroScenario({
            scenarioId: scenario.id,
            data: scenarioUpdates,
          }));
      } catch (e) {
        showSnackbar(t("errorMessages.updateScenario"), "error");
        console.warn(e);
      }
    }
  };

  // ? Hides results if no base year or target year
  useEffect(() => {
    const allFieldsFilled =
      scenario &&
      scenario.results &&
      (scenario as ViewMacroScenarioDto)?.inputs?.years?.base_year &&
      (scenario as ViewMacroScenarioDto)?.inputs?.years?.target_year &&
      ((scenario as ViewMacroScenarioDto)?.inputs?.countries ?? []).length > 0;
    if (allFieldsFilled) {
      setResultsHidden(false);
    } else {
      setResultsHidden(true);
    }
  }, [scenario]);

  const handleUpdateScenario = async (
    scenarioUpdateModel: UpdateMacroScenarioDto,
  ) => {
    if (scenario?.id) {
      try {
        await updateMacroScenario({
          scenarioId: scenario.id,
          data: scenarioUpdateModel,
        });
      } catch (error) {
        showSnackbar(t("errorMessages.updateScenario"), "error");
      }
    }
  };

  const selectedProductsRef = useRef(selectedProducts);
  useEffect(() => {
    selectedProductsRef.current = selectedProducts;
  }, [selectedProducts]);

  const handleProcessFilterView = useCallback(async () => {
    if (scenario?.id) {
      try {
        const data = await processFilterView({
          scenarioId: scenario.id,
          data: selectedProductsRef.current,
        });
        setFilterView(data);
        setFilterViewModalIsOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  }, [processFilterView, scenario?.id]);

  //
  // IMPORTANT - TO BE REMOVED WHEN WE RELEASE MACRO ADJUSTMENTS
  const { memberInfo } = useMemberInfo();
  const isPwCUser = memberInfo?.email?.includes("@pwc.com");
  const hideMacroAdjustments = getEnvironmentVariableValue(
    EnvironmentVariable.featureToggle.hideMacroAdjustments,
  );
  // IMPORTANT - TO BE REMOVED WHEN WE RELEASE MACRO ADJUSTMENTS
  //

  const handleNavigateToAdjustmentsPage = () => {
    if (scenario?.id) {
      navigate(
        MacroToolRoutesConfig.adjustmentsPage.replace(
          ":id",
          String(scenario.id),
        ),
        {
          state: {
            id: scenario.id,
          },
        },
      );
    }
  };

  const handleExport = async () => {
    setIsExporting(true);
    showGlobalLoader(true, true);
    try {
      const fileName = `PEF-MacroScenario-${scenario?.name}-Emissions-${getFormattedDateForDownloads()}.xlsx`;

      const response: typeof generateMacroScenarioEmissionsExportScenariosMacroScenarioIdExportGet =
        await withCustomAxios({
          url: `/scenarios/macro/${scenarioId}/export`,
          method: "GET",
          responseType: "blob",
        });

      downloadBlob(response as unknown as BlobPart, fileName);

      showSnackbar(
        t("macroScenarioPage.exportScenario.successMessage"),
        "success",
      );
    } catch (error) {
      console.warn(error);
      showSnackbar(t("macroScenarioPage.exportScenario.errorMessage"), "error");
    } finally {
      setIsExporting(false);
      showGlobalLoader(false);
    }
  };

  const notAdjustable =
    scenario?.inputs?.countries?.length === 0 ||
    scenario?.inputs?.years?.base_year === null ||
    scenario?.inputs?.years?.target_year === null;

  return (
    <>
      <Box>
        <ScenarioHeroSection
          scenario={scenario as ViewMacroScenarioDto}
          onRenameScenario={handleRenameScenario}
          handleRenameDescription={handleRenameDescription}
          headersIndent={headersIndent}
          updateScenarioIsPending={updateMacroScenarioIsPending}
          backBtnHandler={() => {
            navigate(redirectURL, {
              state: {
                headersIndent: headersIndent,
              },
            });
          }}
          backBtnText={t("createScenarioSection.backToMacroHome")}
          actionsSection={
            <Box>
              {isPwCUser && (
                <Button
                  sx={{ ml: 2 }}
                  variant="outlined"
                  onClick={handleExport}
                  disabled={notAdjustable || isExporting}
                >
                  {t("macroScenarioPage.exportScenario.exportButtonLabel")}
                </Button>
              )}

              {(isPwCUser || !hideMacroAdjustments) && (
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={handleNavigateToAdjustmentsPage}
                  endIcon={
                    <CustomIcon
                      name={Icons.LIST}
                      width={24}
                      height={24}
                      fill={"white"}
                    />
                  }
                  disabled={notAdjustable}
                  //TODO: disable on editName || editDescription
                >
                  {t("buttonLabel.viewAdjustments")}
                </Button>
              )}
            </Box>
          }
        />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          <Box width="50%">
            <DateAndCountryInputSection
              years={years?.map((year) => year.year) ?? []}
              baseYear={
                (scenario as ViewMacroScenarioDto)?.inputs?.years?.base_year ??
                null
              }
              targetYear={
                (scenario as ViewMacroScenarioDto)?.inputs?.years
                  ?.target_year ?? null
              }
              countries={
                (scenario as ViewMacroScenarioDto)?.inputs?.countries ?? []
              }
              handleUpdateScenario={handleUpdateScenario}
              disabled={false}
              hasAdjustments={Boolean(scenario?.has_adjustments)}
            />
          </Box>

          <Button
            variant="text"
            disabled={Boolean(filterView)}
            onClick={() => setFilterViewModalIsOpen(true)}
          >
            {t("macro:createFilteredView")}
          </Button>
        </Box>

        {filterView && (
          <Alert
            icon={<CustomIcon name={Icons.FILTER} />}
            severity="info"
            sx={{
              backgroundColor: "#F3F3F3",
              ".MuiAlert-icon": { alignItems: "center" },
            }}
            onClose={() => {
              setSelectedProducts([]);
              setFilterView(null);
            }}
          >
            {`${t("macro:macroScenarioPage.filterViewMessagePartOne")} ${filterView.metadata.base.filtered_product_count}/${filterView.metadata.base.total_product_count} ${t("macro:macroScenarioPage.in")} ${scenario?.inputs?.years?.base_year} ${t("macro:macroScenarioPage.and")} ${filterView.metadata.glide.filtered_product_count}/${filterView.metadata.glide.total_product_count} ${t("macro:macroScenarioPage.in")} ${scenario?.inputs?.years?.target_year} ${t("macro:macroScenarioPage.filterViewMessagePartTwo")} ${formatNumber(filterView.metadata.base.filtered_sales_volume_pct, ProductConfigurationsMacro.TWO_DP)}% ${t("macro:macroScenarioPage.and")} ${formatNumber(filterView.metadata.glide.filtered_sales_volume_pct, ProductConfigurationsMacro.TWO_DP)}% ${t("macro:macroScenarioPage.filterViewMessagePartThree")}`}
          </Alert>
        )}

        {updateMacroScenarioIsPending || scenarioIsPending ? (
          <Box mt={2} ml={2}>
            <CircularProgress size={"32px"} />
          </Box>
        ) : resultsHidden ? (
          <NoData label={t("noResultsTitle")}></NoData>
        ) : scenario ? (
          <ScenarioResults
            scenarioResults={
              filterView?.results || (scenario.results as MacroResults)
            }
            scenarioYears={scenario.inputs?.years as Years}
            disabled={
              !(
                !!(scenario as ViewMacroScenarioDto)?.inputs?.countries ||
                !!scenarioId
              )
            }
          />
        ) : null}
      </Box>

      <Modal
        content={
          <Box p={4} width="80vw">
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h2" sx={{ mb: 2 }}>
                {t("macro:filterViewModal.title")}
              </Typography>
              <Typography variant="h4" sx={{ mb: 4 }}>
                {t("macro:filterViewModal.description")}
              </Typography>
            </Box>
            <ProductSelectionView
              countriesList={scenario?.inputs?.countries || []}
              targetYear={scenario?.inputs?.years?.target_year || 0}
              showBaseProducts={true}
              initialSelectedProducts={[]}
              updateSelectedProducts={(newSelectedProducts) => {
                setSelectedProducts(newSelectedProducts);
              }}
              selectionType="multiple"
            />

            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Button
                variant="outlined"
                onClick={() => setFilterViewModalIsOpen(false)}
                sx={{ mr: 2 }}
              >
                {t("common:actions.cancel")}
              </Button>

              <Button
                variant="contained"
                disabled={
                  !selectedProducts.length || processFilterViewIsPending
                }
                onClick={handleProcessFilterView}
              >
                {t("common:actions.apply")}
                {processFilterViewIsPending && (
                  <CircularProgress size={10} sx={{ ml: 1 }} />
                )}
              </Button>
            </Box>
          </Box>
        }
        closeModal={() => setFilterViewModalIsOpen(false)}
        isOpen={filterViewModalIsOpen}
        navigationAwayLocked={false}
        width={"xl"}
      />
    </>
  );
}

export default MacroToolScenarioPage;
