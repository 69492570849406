import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo } from "react";
import {
  AccordionsViewModel,
  CountryViewModel,
  FunctionalAreaItemViewModel,
  MemberDetails,
} from "orval/generated/models";
import { Loader } from "components/common";
import { Box, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generateTableHeight } from "assets/styles/helpers/tables";
import { useModal } from "components/common/Modal";
import { IdamUser, useAuthUser } from "core";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import AccessChip from "./AccessChip/AccessChip";
import Header from "./UserManagementTableHeader";
import {
  getAdjustmentAccessItems,
  getFunctionalAccessItems,
} from "../UserManagementHelperFunctions";
import { OnChangeFn, RowSelectionState } from "@tanstack/table-core";

export type Pagination = {
  pageIndex: number;
  pageSize: number;
};

export const paginationOptions = [20, 50, 100];

export interface UserManagementTableProps {
  data: MemberDetails[];
  pagination: Pagination;
  isLoading: boolean;
  countryData: CountryViewModel[];
  functionalAccessData: FunctionalAreaItemViewModel[];
  adjustmentAccessData: AccordionsViewModel[];
  functionalAccessFilters: string[];
  handleFunctionalAccessChange: (selectedItems: string[]) => void;
  countryAccessFilters: string[];
  handleCountryAccessChange: (selectedItems: string[]) => void;
  adjustmentAccessFilters: string[];
  handleAdjustmentAccessChange: (selectedItems: string[]) => void;
  handleOpenUpsertUserModal: Function;
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
  setSelectedUsers: (selectedUsers: number[]) => void;
}

const UserManagementTable = ({
  data,
  pagination,
  isLoading,
  functionalAccessFilters,
  handleFunctionalAccessChange,
  countryAccessFilters,
  handleCountryAccessChange,
  adjustmentAccessFilters,
  handleAdjustmentAccessChange,
  countryData,
  functionalAccessData,
  adjustmentAccessData,
  handleOpenUpsertUserModal,
  rowSelection,
  setRowSelection,
  setSelectedUsers,
}: UserManagementTableProps) => {
  const { t } = useTranslation(["toolConfiguration"]);
  const { openModal } = useModal();
  const userInfo: IdamUser | undefined = useAuthUser();
  const USER_ACCESS_COLUMN_SIZE = 100;

  const countryFiltersList = useMemo(
    () =>
      countryData?.map((country: CountryViewModel) => country.display_name) ??
      [],
    [countryData],
  );
  const functionalAccessFiltersList = useMemo(
    () => getFunctionalAccessItems(functionalAccessData),
    [functionalAccessData],
  );
  const adjustmentAccessFiltersList = useMemo(
    () => getAdjustmentAccessItems(adjustmentAccessData),
    [adjustmentAccessData],
  );

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`, // Concatenate first_name and last_name
        header: "Name",
        Header: <Header name="Name" />,
        size: 180,
        subRows: (row: any) => `${row.first_name} ${row.last_name}`,
      },
      {
        accessorKey: "email",
        header: "Email",
        Header: <Header name="Email" />,
        size: 310,
      },
      {
        accessorFn: (row) =>
          functionalAccessData ? (
            <AccessChip
              items={row.permissions.functional_access.map(
                (functionalAccess: FunctionalAreaItemViewModel) => {
                  return functionalAccess.name;
                },
              )}
              itemType={"Functional Areas"}
              totalItems={
                functionalAccessData &&
                functionalAccessData.flatMap(
                  (item: FunctionalAreaItemViewModel) => {
                    if (item.id !== null) {
                      return item.name;
                    } else
                      return (
                        item.subItem &&
                        item.subItem.map(
                          (subItem: FunctionalAreaItemViewModel) =>
                            subItem.name,
                        )
                      );
                  },
                ).length
              }
              passThroughTestId={`${row.first_name}-${row.last_name}-functional`}
            ></AccessChip>
          ) : (
            <Typography>
              {t("userManagementPage.unavailableAccessData")}
            </Typography>
          ),
        Header: (
          <Header
            name="Functional Access"
            filtersList={functionalAccessFiltersList}
            selectedFilters={functionalAccessFilters}
            handleFilterChange={handleFunctionalAccessChange}
          />
        ),
        header: "Functional Access",
        maxSize: USER_ACCESS_COLUMN_SIZE,
      },
      {
        accessorFn: (row) =>
          countryData ? (
            <AccessChip
              items={row.permissions.countries.map(
                (country: CountryViewModel) => country.display_name,
              )}
              itemType={"Countries"}
              totalItems={countryData?.length ?? 0}
              passThroughTestId={`${row.first_name}-${row.last_name}-countries`}
            ></AccessChip>
          ) : (
            <Typography>
              {t("userManagementPage.unavailableAccessData")}
            </Typography>
          ),
        header: "Country Access",
        Header: (
          <Header
            name="Country Access"
            filtersList={countryFiltersList}
            selectedFilters={countryAccessFilters}
            handleFilterChange={handleCountryAccessChange}
          />
        ),
        maxSize: USER_ACCESS_COLUMN_SIZE,
      },
      {
        accessorFn: (row) =>
          adjustmentAccessData ? (
            <AccessChip
              items={row.permissions.adjustment_access.map(
                (adjustmentAccess: FunctionalAreaItemViewModel) => {
                  return adjustmentAccess.name;
                },
              )}
              itemType={"Adjustment Types"}
              totalItems={
                adjustmentAccessData &&
                adjustmentAccessData.flatMap((item) => {
                  if (item.id !== null) {
                    return item.name;
                  } else
                    return (
                      item.subItem &&
                      item.subItem.map(
                        (subItem: FunctionalAreaItemViewModel) => subItem.name,
                      )
                    );
                }).length
              }
              passThroughTestId={`${row.first_name}-${row.last_name}-adjustment`}
            ></AccessChip>
          ) : (
            <Typography>
              {t("userManagementPage.unavailableAccessData")}
            </Typography>
          ),
        header: "Adjustment Access",
        Header: (
          <Header
            name="Adjustment Access"
            filtersList={adjustmentAccessFiltersList}
            selectedFilters={adjustmentAccessFilters}
            handleFilterChange={handleAdjustmentAccessChange}
          />
        ),
        maxSize: USER_ACCESS_COLUMN_SIZE,
      },
      {
        accessorFn: (row) => {
          if (!row.terms_accepted_at) {
            return;
          }
          return new Date(row.terms_accepted_at).toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
        },
        header: "Terms Accepted",
        Header: <Header name="Terms Accepted" />,
        size: 180,
      },
      {
        accessorFn: (row) => {
          if (!row.last_logged_in_at) {
            return;
          }
          return new Date(row.last_logged_in_at).toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
        },
        header: "Last Login",
        Header: <Header name="Last Login" />,
        size: 180,
      },
    ],
    [
      functionalAccessFiltersList,
      functionalAccessFilters,
      handleFunctionalAccessChange,
      countryFiltersList,
      countryAccessFilters,
      handleCountryAccessChange,
      adjustmentAccessFiltersList,
      adjustmentAccessFilters,
      handleAdjustmentAccessChange,
      functionalAccessData,
      t,
      countryData,
      adjustmentAccessData,
    ],
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: data.length > paginationOptions[0],
    enableSorting: false,
    enableTopToolbar: false,
    enableExpandAll: false, //disable expand all button
    enableRowActions: true,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (originalRow) => originalRow.id,
    state: { rowSelection },
    muiTableContainerProps: {
      sx: {
        height: generateTableHeight("255px"),
      },
    },
    enableStickyHeader: true,
    paginationDisplayMode: "pages",
    autoResetPageIndex: true,
    manualPagination: false,
    muiPaginationProps: {
      color: "primary",
      rowsPerPageOptions: paginationOptions,
      shape: "rounded",
      variant: "outlined",
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    initialState: {
      columnPinning: {
        right: ["mrt-row-actions"],
      },
      pagination: pagination,
    },
    muiTableBodyRowProps: {
      hover: false,
    },
    muiTableProps: {
      sx: {
        boxShadow: "none !important",
        border: "none !important",
      },
    },
    muiTableBodyProps: {
      sx: {
        maxHeight: "20vh",
      },
    },
    defaultColumn: {
      muiTableHeadCellProps: {
        sx: {
          borderBottom: "1px solid #6A6A6A",
          borderRight: "none !important",
          fontWeight: 500,
          verticalAlign: "center",
        },
      },
      muiTableBodyCellProps: ({ row, column }) => {
        return {
          sx: {
            borderBottom: "1px solid #CBCBCB",
            borderRight: "none !important",
            verticalAlign: "center",
            borderTop: "none !important",
            boxShadow: "none",
            maxWidth: "50px",
            fontWeight: 400,
          },
          "data-testid":
            column.id === "mrt-row-select"
              ? `checkbox-${row.index}`
              : undefined,
        };
      },
    },
    muiTablePaperProps: {
      sx: {
        elevation: 0,
        boxShadow: "none",
      },
    },
    localization: {
      actions: "",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key={0}
          onClick={() => {
            handleOpenUpsertUserModal(true, row.original);
            closeMenu();
          }}
          sx={{ m: 0, minWidth: "150px" }}
          disabled={
            userInfo === undefined ||
            userInfo?.preferredMail === row.original.email ||
            userInfo?.email === row.original.email
          }
        >
          {t("common:actions.edit")}
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            handleOpenDeleteUserModal(row.original);
            closeMenu();
          }}
          sx={{ m: 0, minWidth: "150px" }}
          disabled={
            userInfo === undefined ||
            userInfo?.preferredMail === row.original.email ||
            userInfo?.email === row.original.email
          }
        >
          {t("common:actions.delete")}
        </MenuItem>,
      ];
    },
    renderEmptyRowsFallback: () => (
      <Box p={3} sx={{ borderBottom: "1px solid grey", opacity: 0.8 }}>
        <Typography variant={"body2"}>
          {t("userManagementPage.noResultsMessage")}
        </Typography>
      </Box>
    ),
  });

  const handleOpenDeleteUserModal = (userInformation: MemberDetails) => {
    openModal(<DeleteUserModal userInformation={userInformation} />);
  };

  useEffect(() => {
    setSelectedUsers(Object.keys(rowSelection).map((key) => Number(key)));
  }, [rowSelection, setSelectedUsers]);

  return (
    <>
      {!isLoading ? (
        <Box data-testid={"user-management-table-box"}>
          <MaterialReactTable table={table} />{" "}
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default UserManagementTable;
