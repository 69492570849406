import {
  ProductConfigurationsMacro,
  ProductConfigurationsMicro,
} from "features/micro-tool/components/MicroAdjustmentsTable/types";
import { AppConstant } from "constants/AppConstant";
import { OptionalMetricsTableResultsFilterDataType } from "features/macro-tool/components/MacroOptionalMetricsTable/Types";
import { MacroAdjustmentCombinations } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";

const getLocale = () =>
  Intl.DateTimeFormat().resolvedOptions().locale || "en-GB";

const decimalPlacesMap: Record<string, number> = {
  [ProductConfigurationsMacro.WATERFALL]: 0,
  [ProductConfigurationsMicro.SERVING_SIZE]: 0,
  [ProductConfigurationsMacro.ABSOLUTE]: 0,
  [OptionalMetricsTableResultsFilterDataType.ABSOLUTE]: 0,
  [ProductConfigurationsMacro.SALES_VOLUME_BAR_ZERO_DP]: 0,
  [ProductConfigurationsMacro.ZERO_DP]: 0,
  [ProductConfigurationsMicro.INGREDIENTS]: 1,
  [ProductConfigurationsMicro.BODY_AND_LID_PACKAGING]: 1,
  [ProductConfigurationsMicro.LOGISTICS]: 1,
  [ProductConfigurationsMicro.OTHER_PACKAGING]: 1,
  [ProductConfigurationsMicro.OTHER_PRIMARY]: 1,
  [ProductConfigurationsMicro.SECONDARY]: 1,
  [ProductConfigurationsMicro.TERTIARY]: 1,
  [ProductConfigurationsMacro.PER_LITRE]: 1,
  [ProductConfigurationsMacro.PERCENTAGE]: 1,
  [ProductConfigurationsMacro.SALES]: 1,
  [ProductConfigurationsMacro.MEKKO]: 1,
  [ProductConfigurationsMicro.RESULTS]: 1,
  [OptionalMetricsTableResultsFilterDataType.PER_LITRE]: 1,
  [ProductConfigurationsMacro.SALES_VOLUME_BAR_ONE_DP]: 1,
  [ProductConfigurationsMacro.ONE_DP]: 1,
  [MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment]: 1,
  [MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment]: 1,
  [MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment]: 1,
  [MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment]: 1,
  [MacroAdjustmentCombinations.Packaging_RecycledContent]: 1,
  [MacroAdjustmentCombinations.Packaging_RecoveryRate]: 1,
  [MacroAdjustmentCombinations.Packaging_NetLossRate]: 1,
  [MacroAdjustmentCombinations.Logistics_DistanceTravelled]: 1,
  [MacroAdjustmentCombinations.CDE_CDEFleetBreakdown]: 1,
  [ProductConfigurationsMacro.TWO_DP]: 2,
  [ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS]: 3,
  [ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS]: 3,
  [ProductConfigurationsMicro.MANUFACTURING]: 3,
  [ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS]: 3,
  [ProductConfigurationsMacro.THREE_DP]: 3,
  [MacroAdjustmentCombinations.Manufacturing_ManufacturingEur]: 3,
  [MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors]: 3,
  [MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors]: 3,
  [MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors]: 3,
  [MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors]: 3,
  [MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors]: 3,
  [MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors]: 3,
  [ProductConfigurationsMacro.FOUR_DP]: 4,
};

const getDecimalPlacesForDataStructureKey = (
  dataStructureKey: string,
  value?: number | string,
): number => {
  if (decimalPlacesMap[dataStructureKey] !== undefined) {
    return decimalPlacesMap[dataStructureKey];
  }

  if (!value) {
    return 0;
  }

  const format = new Intl.NumberFormat(getLocale());
  const parts = format.formatToParts(-12345.6);
  const decimalPart = parts.find((d) => d.type === "decimal");
  const decimalRegExp = new RegExp(`[${decimalPart?.value}]`);
  const indexInArr = value
    .toString()
    .split("")
    .findIndex((element) => decimalRegExp.test(element));

  return indexInArr === -1 ? 0 : value.toString().length - indexInArr - 1;
};

const formatWithLocale = (
  number: number,
  decimalPlaces: number,
  locale: string,
): string => {
  const formattedNumber = number.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  return /^-0(\.0+)?$/.test(formattedNumber)
    ? formattedNumber.replace("-", "")
    : formattedNumber;
};

const formatNumber = (
  value: number | null | undefined | string,
  dataStructureKey?: string,
  parentAccordion?: string,
): string => {
  if (value === null || value === undefined || value === "") {
    return AppConstant.emptyCell;
  }

  dataStructureKey = parentAccordion ?? dataStructureKey;

  const numberToFormat = typeof value === "string" ? parseFloat(value) : value;
  const decimalPlaces = getDecimalPlacesForDataStructureKey(
    dataStructureKey ?? "",
    value,
  );

  return formatWithLocale(numberToFormat, decimalPlaces, getLocale());
};

const formatNumberOrEmpty = (
  value: number | null | undefined | string,
  dataStructureKey?: string,
): string => {
  return value !== 0
    ? formatNumber(value, dataStructureKey)
    : AppConstant.emptyCell;
};

const formatNumberButNotIncreaseDecimalPlaces = (
  value: number | string,
  dataStructureKey: string,
): string => {
  if (value === null || value === undefined || value === "") {
    return AppConstant.emptyCell;
  }

  const numberToFormat = typeof value === "string" ? parseFloat(value) : value;
  const originalDecimalPlaces = (value.toString().split(".")[1] || "").length;
  const requiredDecimalPlaces =
    getDecimalPlacesForDataStructureKey(dataStructureKey);
  const decimalPlaces = Math.min(originalDecimalPlaces, requiredDecimalPlaces);

  return formatWithLocale(numberToFormat, decimalPlaces, getLocale());
};

export {
  getDecimalPlacesForDataStructureKey,
  formatNumber,
  formatNumberOrEmpty,
  formatNumberButNotIncreaseDecimalPlaces,
};
