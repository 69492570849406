import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useModal } from "components/common/Modal";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  AccordionsViewModel,
  BulkMemberPermissionsModel,
  CountryViewModel,
  FunctionalAreaItemViewModel,
  PermissionItem,
} from "orval/generated/models";
import SelectDropdown from "../../../../../components/common/SelectDropdown/SelectDropdown";
import Checkbox from "@mui/material/Checkbox";
import {
  AccessField,
  getAccessFields,
  getAdjustmentAccessItems,
  getFunctionalAccessItems,
  useAccessHandlers,
} from "../UserManagementHelperFunctions";
import { useQueryClient } from "@tanstack/react-query";
import { CustomIcon, Icons, useGlobalLoader } from "components/common";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import {
  getGetAllMembersMembersGetQueryKey,
  useEditBulkPermissionsMembersPermissionsPatch,
} from "orval/generated/endpoint";
import { OnChangeFn, RowSelectionState } from "@tanstack/table-core";
import { AppConstant } from "constants/AppConstant";
import { AxiosError } from "axios";

export type EditMultipleUsersModalProps = {
  selectedUsers: number[];
  countryData: CountryViewModel[];
  functionalAccessData: FunctionalAreaItemViewModel[];
  adjustmentAccessData: AccordionsViewModel[];
  flattenedFunctionalAccess: PermissionItem[];
  flattenedAdjustmentAccess: PermissionItem[];
  setRowSelection: OnChangeFn<RowSelectionState>;
};

const EditMultipleUsersModal = ({
  selectedUsers,
  countryData,
  functionalAccessData,
  adjustmentAccessData,
  flattenedFunctionalAccess,
  flattenedAdjustmentAccess,
  setRowSelection,
}: EditMultipleUsersModalProps) => {
  const { t } = useTranslation(["toolConfiguration", "common"]);
  const [formSubmissionDisabled, setFormSubmissionDisabled] = useState(false);
  const [confirmActionCheckboxDisabled, setConfirmActionCheckboxDisabled] =
    useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [accessFieldsDisabled, setAccessFieldsDisabled] = useState<string[]>([
    "FUNCTIONAL_ACCESS",
    "COUNTRY_ACCESS",
    "ADJUSTMENT_ACCESS",
  ]);
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const { closeModal } = useModal();
  const { palette } = useTheme();
  const { showGlobalLoader } = useGlobalLoader();

  const functionalAccessItems = useMemo(
    () => getFunctionalAccessItems(functionalAccessData || []),
    [functionalAccessData],
  );

  const adjustmentAccessItems = useMemo(
    () => getAdjustmentAccessItems(adjustmentAccessData || []),
    [adjustmentAccessData],
  );

  const { mutateAsync: bulkEditUsers, isPending: bulkEditUserIsPending } =
    useEditBulkPermissionsMembersPermissionsPatch({
      mutation: {
        onSuccess: async () => {
          showSnackbar(
            t(
              "toolConfiguration:userManagementPage.editMultipleUsers.successToast",
            ),
            "success",
          );
          await queryClient.invalidateQueries({
            queryKey: getGetAllMembersMembersGetQueryKey(),
          });
        },
        onError: (error: AxiosError) => {
          showSnackbar(error, "error");
        },
      },
    });

  const handleFormSubmit = async (patchObject: BulkMemberPermissionsModel) => {
    try {
      await bulkEditUsers({ data: patchObject });
      setRowSelection({});
      closeModal();
    } catch (error) {
      console.warn("Error editing multiple users", error);
    } finally {
      showGlobalLoader(false);
    }
  };

  const formik = useFormik<{
    adjustment_access: number[];
    countries: string[];
    functional_access: number[];
  }>({
    initialValues: {
      adjustment_access: AppConstant.defaultAdjustmentAccess,
      countries: [],
      functional_access: [],
    },
    validationSchema: yup.object({
      adjustment_access: yup.array().of(yup.number()),
      countries: yup.array().of(yup.string()),
      functional_access: yup.array().of(yup.number()),
    }),
    onSubmit: (values) => {
      const patchObject = {
        member_ids: selectedUsers,
        permissions: {
          ...(!accessFieldsDisabled.includes("ADJUSTMENT_ACCESS") && {
            adjustment_access: values.adjustment_access,
          }),
          ...(!accessFieldsDisabled.includes("COUNTRY_ACCESS") && {
            countries: values.countries,
          }),
          ...(!accessFieldsDisabled.includes("FUNCTIONAL_ACCESS") && {
            functional_access: values.functional_access,
          }),
        },
      };
      handleFormSubmit(patchObject).catch((error) => {
        console.warn(error);
      });
    },
  });

  const {
    handleFunctionalAccessChange,
    handleCountryAccessChange,
    handleAdjustmentAccessChange,
  } = useAccessHandlers(
    formik,
    flattenedFunctionalAccess,
    countryData,
    flattenedAdjustmentAccess,
  );

  const accessFields = useMemo(
    () =>
      getAccessFields(
        formik,
        countryData,
        flattenedAdjustmentAccess,
        flattenedFunctionalAccess,
        adjustmentAccessItems,
        functionalAccessItems,
        handleCountryAccessChange,
        handleAdjustmentAccessChange,
        handleFunctionalAccessChange,
        t,
      ),
    [
      adjustmentAccessItems,
      countryData,
      flattenedAdjustmentAccess,
      flattenedFunctionalAccess,
      formik,
      functionalAccessItems,
      handleAdjustmentAccessChange,
      handleCountryAccessChange,
      handleFunctionalAccessChange,
      t,
    ],
  );

  useEffect(() => {
    if (accessFieldsDisabled.length > 2) {
      setFormSubmissionDisabled(true);
      setConfirmActionCheckboxDisabled(true);
      setConfirmed(false);
    } else {
      setConfirmActionCheckboxDisabled(
        (!accessFieldsDisabled.includes("ADJUSTMENT_ACCESS") &&
          formik.values.adjustment_access.length < 1) ||
          (!accessFieldsDisabled.includes("FUNCTIONAL_ACCESS") &&
            formik.values.functional_access.length < 1) ||
          (!accessFieldsDisabled.includes("COUNTRY_ACCESS") &&
            formik.values.countries.length < 1),
      );
      setFormSubmissionDisabled(!formik.isValid || !confirmed);
    }
  }, [
    formik.values,
    formik.dirty,
    formik.isValid,
    accessFieldsDisabled,
    confirmed,
  ]);

  useEffect(() => {
    bulkEditUserIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [bulkEditUserIsPending, showGlobalLoader]);

  const handlePermissionTypeSelectionChange = (
    checked: boolean,
    fieldType: string,
  ) => {
    if (checked) {
      setAccessFieldsDisabled((prev) =>
        prev.filter((type) => type !== fieldType),
      );
      setConfirmed(false);
    } else {
      setAccessFieldsDisabled((prev) => [...prev, fieldType]);
    }
  };

  return (
    <Box maxWidth={500}>
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label={t("common:actions.cancel")}
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={closeModal}
        >
          <CustomIcon
            name={Icons.CLOSE}
            width={24}
            height={24}
            fill={palette.text.primary}
          />
        </IconButton>
      </Box>
      <Box paddingX={5} paddingBottom={4}>
        <Typography textAlign="center" variant="h2" marginBottom={3}>
          {t("toolConfiguration:userManagementPage.editMultipleUsers.title")}
        </Typography>
        <Typography textAlign="center" variant="body1" marginBottom={3}>
          {t(
            "toolConfiguration:userManagementPage.editMultipleUsers.description",
          )}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          {accessFields.map((field: AccessField) => (
            <React.Fragment key={field.title}>
              <Box
                display={"flex"}
                alignItems={"center"}
                mb={field.title === "Adjustment Access" ? 0 : 2}
              >
                <Checkbox
                  data-testid={`checkbox-${field.type}`}
                  onChange={(_, checked) =>
                    handlePermissionTypeSelectionChange(checked, field.type)
                  }
                />
                <FormControl required fullWidth key={field.title}>
                  <SelectDropdown
                    listItems={field.listItems}
                    onSave={field.onSave}
                    savedSelectedItems={field.savedSelectedItems}
                    title={field.title}
                    requireSave={false}
                    showSearchBar
                    selectAll
                    formikLabel={field.formikLabel}
                    helperText={field.helperText}
                    onOpen={field.onOpen}
                    disabledItems={field.disabledItems}
                    disabled={accessFieldsDisabled.includes(field.type)}
                  ></SelectDropdown>
                </FormControl>{" "}
              </Box>
              {field.title ===
                t("userManagementPage.upsertUser.adjustmentAccessLabel") && (
                <Box mb={3} pl={5.2}>
                  <Typography variant={"body1"}>
                    {t("userManagementPage.servingSizeSelectedByDefault")}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmed}
                  onChange={(event) => setConfirmed(event.target.checked)}
                  name="confirmAction"
                  color="primary"
                  disabled={confirmActionCheckboxDisabled}
                  data-testid="confirm-action-checkbox"
                />
              }
              label={t(
                "toolConfiguration:userManagementPage.editMultipleUsers.confirmActionLabel",
              )}
              slotProps={{ typography: { variant: "body2" } }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              sx={{ mr: 2 }}
              variant="outlined"
              onClick={closeModal}
              disabled={bulkEditUserIsPending}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formSubmissionDisabled || bulkEditUserIsPending}
            >
              {t("common:actions.save")}
              {bulkEditUserIsPending ? (
                <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
              ) : null}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditMultipleUsersModal;
