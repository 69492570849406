import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import { useFormikContext } from "formik";
import { MacroFiltersAndFilteredRTDL } from "orval/generated/models/macroFiltersAndFilteredRTDL";
import { useTranslation } from "react-i18next";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentPillars,
  MacroAdjustmentWizardFormikValues,
} from "../../MacroAdjustmentTypes";
import { MacroFilter, MacroFiltersForProducts } from "orval/generated/models";
import { useMemo } from "react";
import { AppConstant } from "constants/AppConstant";

export type EntitiesDropdownStepTwoProps = {
  adjustmentCombination: MacroAdjustmentCombination | undefined;
  filtersData: MacroFiltersAndFilteredRTDL | undefined;
  filtersByProducts: MacroFiltersForProducts | undefined;
  handleEntitiesSave: Function;
};

const EntitiesDropdownStepTwo = ({
  adjustmentCombination,
  filtersData,
  filtersByProducts,
  handleEntitiesSave,
}: EntitiesDropdownStepTwoProps) => {
  const { t } = useTranslation("macro");
  const { values } = useFormikContext<MacroAdjustmentWizardFormikValues>();

  const title = useMemo(() => {
    switch (values.selectedPillar) {
      case MacroAdjustmentPillars.Packaging:
        return t("adjustmentsPage.wizard.stepTwo.selectPartTypeMaterials");
      case MacroAdjustmentPillars.Manufacturing:
        return t("adjustmentsPage.wizard.stepTwo.selectEnergyType");
      case MacroAdjustmentPillars.Logistics:
        return t("adjustmentsPage.wizard.stepTwo.selectTransportMode");
      case MacroAdjustmentPillars.CDE:
        return t("adjustmentsPage.wizard.stepTwo.selectCdeGroup");

      default:
        return t("adjustmentsPage.wizard.stepTwo.selectIngredients");
    }
  }, [values.selectedPillar, t]);

  const description = useMemo(() => {
    switch (values.selectedPillar) {
      case MacroAdjustmentPillars.Packaging:
        return t(
          "adjustmentsPage.wizard.stepTwo.selectPartTypeMaterialsDescription",
        );
      case MacroAdjustmentPillars.Manufacturing:
        return t("adjustmentsPage.wizard.stepTwo.selectEnergyTypeDescription");
      case MacroAdjustmentPillars.Logistics:
        return t(
          "adjustmentsPage.wizard.stepTwo.selectTransportModeDescription",
        );
      case MacroAdjustmentPillars.CDE:
        return t("adjustmentsPage.wizard.stepTwo.selectCdeGroupDescription");

      default:
        return t("adjustmentsPage.wizard.stepTwo.selectIngredientsDescription");
    }
  }, [values.selectedPillar, t]);

  const dropdown = useMemo(() => {
    if (!adjustmentCombination) return null;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
        return (
          <SelectDropdown
            listItems={
              filtersData?.filters.ingredients?.map(
                (ingredient: MacroFilter) => ingredient.value,
              ) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "ingredients");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.ingredients")}*`}
            compact
            selectAll={true}
            disabled={
              adjustmentCombination.combo ===
                MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment &&
              values.selectedCountries.length === 0
            }
          />
        );
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return (
          <SelectDropdown
            listItems={
              (adjustmentCombination.combo ===
              MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors
                ? filtersData?.filters?.part_materials?.map(
                    (part_material: MacroFilter) => part_material.value,
                  )
                : filtersByProducts?.part_materials?.map(
                    (part_material: MacroFilter) => part_material.value,
                  )) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "part_materials");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.partMaterials")}*`}
            compact
            selectAll={true}
            disabled={
              (!values.selectedProducts.length &&
                adjustmentCombination.combo !==
                  MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors) ||
              (!values.selectedCountries.length &&
                adjustmentCombination.combo ===
                  MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors)
            }
          />
        );
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
        return (
          <SelectDropdown
            listItems={AppConstant.eurEnergyTypesList}
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "energy_types");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.energyTypes")}*`}
            compact
            selectAll={true}
            disabled={
              (adjustmentCombination?.combo ===
                MacroAdjustmentCombinations.Manufacturing_ManufacturingEur &&
                values.selectedProducts.length === 0) ||
              (adjustmentCombination?.combo !==
                MacroAdjustmentCombinations.Manufacturing_ManufacturingEur &&
                values.selectedCountries.length === 0)
            }
          />
        );
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
        return (
          <SelectDropdown
            listItems={
              (adjustmentCombination?.combo ===
              MacroAdjustmentCombinations.Logistics_DistanceTravelled
                ? filtersByProducts?.transport_modes?.map(
                    (transport_mode: MacroFilter) => transport_mode.value,
                  )
                : adjustmentCombination?.combo ===
                    MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors
                  ? filtersData?.filters.transport_modes
                      ?.map(
                        (transport_mode: MacroFilter) => transport_mode.value,
                      )
                      .filter((transport_mode) =>
                        AppConstant.roadTransportModesAllowList.includes(
                          transport_mode as string,
                        ),
                      )
                  : filtersData?.filters.transport_modes
                      ?.map(
                        (transport_mode: MacroFilter) => transport_mode.value,
                      )
                      .filter((transport_mode) =>
                        AppConstant.otherTransportModesAllowList.includes(
                          transport_mode as string,
                        ),
                      )) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "transport_modes");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.transportModes")}*`}
            compact
            selectAll={true}
            disabled={
              (adjustmentCombination?.combo ===
                MacroAdjustmentCombinations.Logistics_DistanceTravelled &&
                values.selectedProducts.length === 0) ||
              (adjustmentCombination?.combo !==
                MacroAdjustmentCombinations.Logistics_DistanceTravelled &&
                values.selectedCountries.length === 0)
            }
          />
        );

      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        return (
          <SelectDropdown
            listItems={
              filtersData?.filters?.cde_groups.map(
                (cde_group: MacroFilter) => cde_group.value,
              ) || []
            }
            savedSelectedItems={values?.selectedEntities?.map(
              (entity) => entity?.value,
            )}
            onSave={(selectedItems) => {
              handleEntitiesSave(selectedItems, "cde_groups");
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.cdeGroups")}*`}
            compact
            selectAll={true}
            disabled={values.selectedCountries.length === 0}
          />
        );
      default:
        return null;
    }
  }, [
    adjustmentCombination,
    filtersData?.filters,
    values?.selectedEntities,
    values.selectedCountries.length,
    values.selectedProducts.length,
    t,
    filtersByProducts,
    handleEntitiesSave,
  ]);

  if (!adjustmentCombination) return null;
  else
    return (
      <>
        <Box mt={4} mb={1} tabIndex={0}>
          <Typography variant={"h3"} className="text-ellipsis">
            {title}
          </Typography>
        </Box>
        <Box mb={2} tabIndex={0}>
          <Typography variant={"body1"}>{description}</Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid size={4}>{dropdown}</Grid>
          </Grid>
        </Box>
      </>
    );
};

export default EntitiesDropdownStepTwo;
