import Box from "@mui/material/Box";
import { AccordionComponent } from "../../AccordionComponent";
import { useTranslation } from "react-i18next";
import { MacroResults, Years } from "orval/generated/models";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import EmissionTimeSection from "../../EmissionTimeSection/EmissionTimeSection";
import { MacroResultsAccordionEnum, TableResultsType } from "../types";
import WaterfallChartSection from "../../WaterfallChart/WaterfallChartSection";
import { MekkoAccordion } from "../../MekkoChartSection/MekkoAccordion/MekkoAccordion";
import { Button } from "@mui/material";
import { accordionMap } from "../ScenarioResults";

export type DataVisualisationTabSectionProps = {
  disabled: boolean;
  scenarioResults: MacroResults | null;
  scenarioYears: Years;
  expandedIds: string[];
  handleExpandCollapseAll: (
    parentAccordionKey: MacroResultsAccordionEnum,
  ) => void;
  isExpanded: (id: string) => boolean;
  handleToggle: (id: string) => void;
};

export function DataVisualisationTabSection({
  disabled,
  scenarioResults,
  scenarioYears,
  expandedIds,
  handleExpandCollapseAll,
  isExpanded,
  handleToggle,
}: DataVisualisationTabSectionProps) {
  const { t } = useTranslation("macro");

  const subAccordionLabelsTimeEvolution = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      id: MacroResultsAccordionEnum.TimeEvolutionByCategory,
      component: (
        <EmissionTimeSection
          scenarioResults={scenarioResults}
          sectionType={TableResultsType.BY_CATEGORY}
        />
      ),
      testId: "time-evolution-by-category",
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      id: MacroResultsAccordionEnum.TimeEvolutionByPackType,
      component: (
        <EmissionTimeSection
          scenarioResults={scenarioResults}
          sectionType={TableResultsType.BY_PACK_TYPE}
        />
      ),
      testId: "time-evolution-by-pack",
    },
  ];

  const subAccordionLabelsWaterfall = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      id: MacroResultsAccordionEnum.WaterfallByCategory,
      component: (
        <WaterfallChartSection
          scenarioResults={scenarioResults}
          sectionType={TableResultsType.BY_CATEGORY}
          scenarioYears={scenarioYears}
        />
      ),
      testId: "waterfall-by-category",
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      id: MacroResultsAccordionEnum.WaterfallByPackType,
      component: (
        <WaterfallChartSection
          scenarioResults={scenarioResults}
          sectionType={TableResultsType.BY_PACK_TYPE}
          scenarioYears={scenarioYears}
        />
      ),
      testId: "waterfall-by-pack",
    },
  ];

  return (
    <Box mb={10}>
      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleExpandCollapseAll(
              MacroResultsAccordionEnum.DataVisualisations,
            );
          }}
          variant={"text"}
          size={"small"}
        >
          {accordionMap[MacroResultsAccordionEnum.DataVisualisations].every(
            (accordion) => {
              return expandedIds.includes(accordion);
            },
          )
            ? t("common:actions.collapseAll")
            : t("common:actions.expandAll")}
        </Button>
      </Box>

      <AccordionComponent
        title={t(
          "dataVisualisationTabSection.accordionLabels.emissionsHeatMap",
        )}
        disabled={disabled}
        testId={"accordion-emissions-heat-map"}
        expanded={isExpanded(MacroResultsAccordionEnum.EmissionsHeatMap)}
        onChange={() =>
          handleToggle(MacroResultsAccordionEnum.EmissionsHeatMap)
        }
      >
        <MekkoAccordion
          scenarioResults={scenarioResults}
          scenarioYears={scenarioYears}
        />
      </AccordionComponent>

      <AccordionComponent
        title={t("dataVisualisationTabSection.accordionLabels.timeEvolution")}
        disabled={disabled}
        testId={"accordion-time-evolution"}
        expanded={isExpanded(MacroResultsAccordionEnum.TimeEvolution)}
        onChange={() => handleToggle(MacroResultsAccordionEnum.TimeEvolution)}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleExpandCollapseAll(MacroResultsAccordionEnum.TimeEvolution);
            }}
            variant={"text"}
            size={"small"}
          >
            {expandedIds.includes(
              MacroResultsAccordionEnum.TimeEvolutionByPackType,
            ) &&
            expandedIds.includes(
              MacroResultsAccordionEnum.TimeEvolutionByCategory,
            )
              ? t("common:actions.collapseAll")
              : t("common:actions.expandAll")}
          </Button>
        </Box>
        {subAccordionLabelsTimeEvolution.map((item) => (
          <Box key={item.id} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent
              title={t(item.label)}
              disabled={disabled}
              testId={`accordion-${item.testId}`}
              expanded={isExpanded(item.id)}
              onChange={() => handleToggle(item.id)}
            >
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
      <AccordionComponent
        title={t("dataVisualisationTabSection.accordionLabels.waterfall")}
        disabled={disabled}
        testId={"accordion-waterfall"}
        expanded={isExpanded(MacroResultsAccordionEnum.Waterfall)}
        onChange={() => handleToggle(MacroResultsAccordionEnum.Waterfall)}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleExpandCollapseAll(MacroResultsAccordionEnum.Waterfall);
            }}
            variant={"text"}
            size={"small"}
          >
            {expandedIds.includes(
              MacroResultsAccordionEnum.WaterfallByCategory,
            ) &&
            expandedIds.includes(MacroResultsAccordionEnum.WaterfallByPackType)
              ? t("common:actions.collapseAll")
              : t("common:actions.expandAll")}
          </Button>
        </Box>
        {subAccordionLabelsWaterfall.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent
              title={t(item.label)}
              disabled={disabled}
              testId={`accordion-${item.testId}`}
              expanded={isExpanded(item.id)}
              onChange={() => handleToggle(item.id)}
            >
              <Box gap="32px">{item.component}</Box>
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
    </Box>
  );
}
