import Grid from "@mui/material/Grid2";
import TableDataTypesAccordion from "../TableDataTypesAccordion";
import { ApiResponse, DataTypeSelectValue } from "../Types";
import { ChangeEvent } from "react";
import { FormikProps } from "formik";
import { DataExtractRequest } from "orval/generated/models";

export type DataExtractionFormPartTwoProps = {
  dataTypeSelectValue: DataTypeSelectValue;
  accordionData: ApiResponse;
  formik: FormikProps<DataExtractRequest>;
  handleAccordionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  reset: boolean;
  expandAll: boolean;
};

export default function DataExtractionFormPartTwo({
  dataTypeSelectValue,
  accordionData,
  formik,
  handleAccordionChange,
  reset,
  expandAll,
}: DataExtractionFormPartTwoProps) {
  // Split accordionData into two equal parts
  const entries = Object.entries(accordionData);
  const midIndex = Math.ceil(entries.length / 2);
  const firstColumnData = entries.slice(0, midIndex);
  const secondColumnData = entries.slice(midIndex);

  return (
    <>
      <Grid
        container
        component="main"
        spacing={3}
        className={dataTypeSelectValue === "" ? "disabledStyling" : ""}
      >
        <Grid size={6}>
          {firstColumnData.map(([key, value]) => (
            <TableDataTypesAccordion
              key={key}
              id={key}
              accordionHeader={value.display_name}
              accordionContent={value.columns}
              value={formik.values.tables}
              onChange={handleAccordionChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.tables && formik.errors.tables)}
              reset={reset}
              expandAll={expandAll}
            />
          ))}
        </Grid>
        <Grid size={6}>
          {secondColumnData.map(([key, value]) => (
            <TableDataTypesAccordion
              key={key}
              id={key}
              accordionHeader={value.display_name}
              accordionContent={value.columns}
              value={formik.values.tables}
              onChange={handleAccordionChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.tables && formik.errors.tables)}
              reset={reset}
              expandAll={expandAll}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
