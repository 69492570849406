import {
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { CustomIcon, Icons } from "components/common/CustomIcon";
import { Box } from "@mui/system";

export interface ThreeDotMenuProps {
  menuItems?: ThreeDotMenuItem[];
  disabled?: boolean;
  menuTitle?: string;
}

export type ThreeDotMenuItem = {
  label: string;
  onClick: (event: Event | SyntheticEvent) => void | Promise<void>;
  disabled?: boolean;
  id?: number;
  isAsync?: boolean;
};

const ThreeDotMenu = (props: ThreeDotMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const menuListRef = useRef<HTMLUListElement>(null);
  const { palette } = useTheme();
  const [itemIsPending, setItemIsPending] = useState<number | null>(null);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    const items = menuListRef.current?.querySelectorAll('[role="menuitem"]');
    if (!items) return;

    const currentIndex =
      Array.from(items).indexOf(document.activeElement as HTMLElement) - 1;

    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = (currentIndex + 1) % items.length;
      (items[nextIndex] as HTMLElement).focus();
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      const prevIndex = (currentIndex + 1 + items.length) % items.length;
      (items[prevIndex] as HTMLElement).focus();
    }
  }

  const handleOnClick = async (
    event: React.MouseEvent,
    onClickFunction: Function,
    index: number,
    isAsync: boolean,
  ) => {
    try {
      if (isAsync) {
        setItemIsPending(index);
        await onClickFunction(event);
      } else {
        onClickFunction(event);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      if (isAsync) {
        setTimeout(() => {
          setItemIsPending(null);
        }, 500);
      }
      setOpen(false);
    }
  };

  const wasPreviouslyOpen = useRef(open);

  useEffect(() => {
    if (wasPreviouslyOpen.current && !open) {
      anchorRef.current!.focus();
    }

    wasPreviouslyOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (open && menuListRef.current) {
      const firstItem = menuListRef.current.querySelector('[role="menuitem"]');
      if (firstItem) {
        (firstItem as HTMLElement).focus();
      }
    }
  }, [open]);

  return (
    <Box aria-label={`${props.menuTitle}`}>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        data-testid="menu-button"
        aria-label="Menu"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ aspectRatio: 1 }}
        color="primary"
        disabled={props.disabled}
      >
        <Box data-testid={"MoreVertIcon"} display="flex" alignItems="center">
          <CustomIcon
            name={Icons.MORE_VERTICAL}
            width={24}
            height={24}
            fill={palette.primary.main}
          />
        </Box>
      </IconButton>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          sx={{ zIndex: 20 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper sx={{ zIndex: 20 }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{ zIndex: 2 }}
                    ref={menuListRef}
                  >
                    {props.menuItems?.map((item, index) => {
                      return (
                        <MenuItem
                          key={item.label}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOnClick(
                              event,
                              item.onClick,
                              index,
                              item.isAsync || false,
                            );
                          }}
                          disabled={item.disabled}
                          id={item?.id?.toString() || item.label}
                          data-testid={`three-dot-menu-item-${item.id || item.label}`}
                          sx={{ height: "43px" }}
                        >
                          {item.label}
                          {item.isAsync &&
                          itemIsPending !== null &&
                          itemIsPending === index ? (
                            <CircularProgress
                              size={16}
                              sx={{ color: "black", ml: 1 }}
                            />
                          ) : null}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

export default ThreeDotMenu;
