import { AuthenticationProviders } from "./AuthenticationProviders";

export const AppConstant = {
  appName: "Product Emissions Forecaster",
  authentication: {
    provider: AuthenticationProviders.IDAM,
  },
  features: {
    generateTemplate: false,
  },
  localStorageKeys: {
    dismissedBannerMessage: "dismissedBannerMessage",
    dismissedMacroNotification: "dismissedMacroNotification",
    appVersion: "appVersion",
    masterDataVersion: "masterDataVersion",
    sandboxMacroScenarioIdKey: "sandboxMacroScenarioIdKey",
    sortValue: "sortValue",
  },
  emptyCell: "--",
  fileMIMETypes: {
    jpeg: "image/jpeg",
    png: "image/png",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    csv: "text/csv",
  },
  fileMIMETypesExtentions: {
    "image/jpeg": ".jpeg",
    "image/png": ".png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "text/csv": ".csv",
  },
  defaultAdjustmentAccess: [1],
  eurEnergyTypesList: ["Electricity", "Pasteurisation", "Steam"],
  roadTransportModesAllowList: ["Primary road"],
  otherTransportModesAllowList: ["Train", "Ship"],
};
