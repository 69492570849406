import { Box, Tooltip, IconButton, useTheme } from "@mui/material";
import { MRT_Row, MRT_RowData, MRT_TableInstance } from "material-react-table";
import { AppConstant } from "constants/AppConstant";
import { CustomIcon, Icons } from "components/common";
import React from "react";

export type MicroAdjustmentsTableRowActionsProps = {
  row: MRT_Row<MRT_RowData>;
  table: MRT_TableInstance<MRT_RowData>;
  setIsEditing: ((value: boolean) => void) | undefined;
  handleClearAdjustmentsForRow: Function;
};

const MicroAdjustmentsTableRowActions = ({
  row,
  table,
  setIsEditing,
  handleClearAdjustmentsForRow,
}: MicroAdjustmentsTableRowActionsProps) => {
  const originalRowsEntries = Object.entries(row.original);
  const adjustmentsPresent = originalRowsEntries.some(
    ([key, value]) =>
      key.startsWith("Product") && value !== AppConstant.emptyCell,
  );
  const { palette } = useTheme();

  const getAdustmentsIds = (): string[] | null => {
    const ids = JSON.parse(row.original.adjustmentsIds);

    if (ids && ids.length) {
      return ids;
    } else {
      return null;
    }
  };

  return (
    <>
      {row ? (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                table.setEditingRow(row);
                if (setIsEditing) {
                  setIsEditing(true);
                }
              }}
            >
              <Box
                data-testid={"EditIcon"}
                display={"flex"}
                alignItems={"center"}
                id={`Edit-${row.original.metric}`}
              >
                <CustomIcon
                  name={Icons.EDIT}
                  width={24}
                  height={24}
                  fill={palette.primary.main}
                />
              </Box>
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear current adjustments">
            <span>
              <IconButton
                disabled={!adjustmentsPresent}
                onClick={() => handleClearAdjustmentsForRow(getAdustmentsIds())}
              >
                <Box
                  data-testid={"DeleteIcon"}
                  display={"flex"}
                  alignItems={"center"}
                  id={`Delete-${row.original.metric}`}
                >
                  <CustomIcon
                    name={Icons.DELETE}
                    width={24}
                    height={24}
                    fill={
                      adjustmentsPresent
                        ? palette.primary.main
                        : palette.action.disabled
                    }
                  />
                </Box>
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
};

export default MicroAdjustmentsTableRowActions;
