import { useCallback, useEffect, useState } from "react";
import MacroAdjustmentStepThreeBasicTable from "./MacroAdjustmentStepThreeBasicTable";
import {
  EnergyTypeEmissionFactor,
  IngredientEmissionFactor,
  PartMaterialEmissionFactor,
  VehicleMixEmissionFactor,
} from "orval/generated/models";
import { useFormikContext } from "formik";
import {
  AccordionArrayAggredatedDataType,
  BasicTableAgreggatedWeightsType,
  CountryAccordionAggredatedDataType,
  MacroAdjustmentCombinations,
  MacroAdjustmentTableAccordion,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { deepClone } from "utils";
import { CustomIcon, Icons } from "components/common";
import {
  convertIsocodeToCountry,
  useCountries,
} from "utils/CountriesProvider/CountriesProvider";
import MacroAdjustmentStepThreeCompositeTable from "./MacroAdjustmentStepThreeCompositeTable";

export type MacroAdjustmentStepThreeAccordionArrayProps = {
  aggregatedData: AccordionArrayAggredatedDataType;
  adjustmentCombination: MacroAdjustmentCombinations;
  baseYear: number;
  targetYear: number;
  validationErrors: { [key: string]: string | undefined };
  setValidationErrors: (
    errors: (prev: { [key: string]: string | undefined }) => {
      [key: string]: string | undefined;
    },
  ) => void;
};

function MacroAdjustmentStepThreeAccordionArray({
  aggregatedData,
  adjustmentCombination,
  targetYear,
  baseYear,
  validationErrors,
  setValidationErrors,
}: MacroAdjustmentStepThreeAccordionArrayProps) {
  const { palette } = useTheme();
  const { values } = useFormikContext<MacroAdjustmentWizardFormikValues>();
  const { t } = useTranslation(["macro"]);
  const { countries } = useCountries();

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(true);

  const getAccordionName = useCallback(
    (accordion: MacroAdjustmentTableAccordion) => {
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
          return values.selectedEntities.find(
            (entity) =>
              // eslint-disable-next-line eqeqeq
              entity.id ==
              (accordion as PartMaterialEmissionFactor).part_material_id,
          )?.value;
        case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
          return values.selectedEntities.find(
            (entity) =>
              // eslint-disable-next-line eqeqeq
              entity.id ==
              (accordion as IngredientEmissionFactor).ingredient_id,
          )?.value;
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
          return values.selectedEntities.find(
            (entity) =>
              // eslint-disable-next-line eqeqeq
              entity.id ==
              (accordion as EnergyTypeEmissionFactor).energy_type_id,
          )?.value;
        case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
        case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
          return values.selectedEntities.find(
            (entity) =>
              // eslint-disable-next-line eqeqeq
              entity.id ==
              (accordion as VehicleMixEmissionFactor).transport_mode_id,
          )?.value;
        default:
          return convertIsocodeToCountry(
            countries,
            (accordion as CountryAccordionAggredatedDataType).country,
          );
      }
    },
    [adjustmentCombination, countries, values.selectedEntities],
  );

  useEffect(() => {
    const expandedObject: { [key: string]: boolean } = {};

    aggregatedData?.forEach(
      (
        accordion:
          | CountryAccordionAggredatedDataType
          | EnergyTypeEmissionFactor
          | VehicleMixEmissionFactor
          | PartMaterialEmissionFactor
          | IngredientEmissionFactor,
      ) => {
        switch (adjustmentCombination) {
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
          case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
          case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
            if (
              !expandedObject[
                (accordion as CountryAccordionAggredatedDataType).country
              ]
            ) {
              expandedObject[
                (accordion as CountryAccordionAggredatedDataType).country
              ] = true;
            }
            break;
          case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
            if (
              !expandedObject[
                (accordion as PartMaterialEmissionFactor).part_material_id
              ]
            ) {
              expandedObject[
                (accordion as PartMaterialEmissionFactor).part_material_id
              ] = true;
            }
            break;
          case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
            if (
              !expandedObject[
                (accordion as IngredientEmissionFactor).ingredient_id
              ]
            ) {
              expandedObject[
                (accordion as IngredientEmissionFactor).ingredient_id
              ] = true;
            }
            break;
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
            if (
              !expandedObject[
                (accordion as EnergyTypeEmissionFactor).energy_type_id
              ]
            ) {
              expandedObject[
                (accordion as EnergyTypeEmissionFactor).energy_type_id
              ] = true;
            }
            break;
          case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
          case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
            if (
              !expandedObject[
                (accordion as VehicleMixEmissionFactor).transport_mode_id
              ]
            ) {
              expandedObject[
                (accordion as VehicleMixEmissionFactor).transport_mode_id
              ] = true;
            }
            break;
          default:
            break;
        }
      },
    );

    setExpanded(expandedObject);
  }, [adjustmentCombination, aggregatedData]);

  const handleExpandedChange = useCallback((country: string) => {
    setExpanded((prev) => {
      const accordionsLength = Object.keys(prev).length;
      let expandedLength = 0;
      let collapsedLength = 0;

      Object.values(prev).forEach((value) =>
        value ? expandedLength++ : collapsedLength++,
      );

      //If this expand makes all accordions expanded switch to 'Collapse all' (Update isAllExpanded to be true)
      if (!prev[country] && expandedLength + 1 === accordionsLength) {
        setIsAllExpanded(true);
        //If this collapse makes all accordions collapsed switch to 'Expand all' (Update isAllExpanded to be false)
      } else if (prev[country] && collapsedLength + 1 === accordionsLength) {
        setIsAllExpanded(false);
      }

      return { ...prev, [country]: !prev[country] };
    });
  }, []);

  const handleExpandAll = useCallback(() => {
    setIsAllExpanded((prevIsAllExpanded) => {
      setExpanded((prevExpanded) => {
        const expandedObject: { [key: string]: boolean } =
          deepClone(prevExpanded);

        Object.keys(expandedObject).forEach((key) => {
          expandedObject[key] = !prevIsAllExpanded;
        });

        return expandedObject;
      });
      return !prevIsAllExpanded;
    });
  }, []);

  const renderTable = useCallback(
    (accordion: MacroAdjustmentTableAccordion) => {
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          return (
            <MacroAdjustmentStepThreeBasicTable
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              base_year={baseYear}
              target_year={targetYear}
              adjustmentCombination={adjustmentCombination}
              aggregatedWeights={accordion as BasicTableAgreggatedWeightsType}
            />
          );

        default:
          return (
            <MacroAdjustmentStepThreeCompositeTable
              adjustmentCombination={adjustmentCombination}
              aggregatedData={accordion}
              baseYear={baseYear}
              targetYear={targetYear}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
            />
          );
      }
    },
    [
      adjustmentCombination,
      baseYear,
      setValidationErrors,
      targetYear,
      validationErrors,
    ],
  );

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid>
          <Button variant="text" onClick={handleExpandAll}>
            {isAllExpanded
              ? t("macro:adjustmentsPage.wizard.stepThree.collapseAll")
              : t("macro:adjustmentsPage.wizard.stepThree.expandAll")}
          </Button>
        </Grid>
      </Grid>
      {aggregatedData?.map((accordion: MacroAdjustmentTableAccordion) => (
        <Accordion
          key={
            (accordion as CountryAccordionAggredatedDataType).country ||
            (accordion as PartMaterialEmissionFactor).part_material_id ||
            (accordion as IngredientEmissionFactor).ingredient_id ||
            (accordion as EnergyTypeEmissionFactor).energy_type_id ||
            (accordion as VehicleMixEmissionFactor).transport_mode_id
          }
          expanded={
            expanded?.[
              (accordion as CountryAccordionAggredatedDataType).country ||
                (accordion as PartMaterialEmissionFactor).part_material_id ||
                (accordion as IngredientEmissionFactor).ingredient_id ||
                (accordion as EnergyTypeEmissionFactor).energy_type_id ||
                (accordion as VehicleMixEmissionFactor).transport_mode_id
            ] || false
          }
          onChange={() =>
            handleExpandedChange(
              ((accordion as CountryAccordionAggredatedDataType).country ||
                (accordion as PartMaterialEmissionFactor).part_material_id ||
                (accordion as IngredientEmissionFactor).ingredient_id ||
                (accordion as EnergyTypeEmissionFactor).energy_type_id ||
                (accordion as VehicleMixEmissionFactor)
                  .transport_mode_id) as string,
            )
          }
        >
          <AccordionSummary
            expandIcon={
              <CustomIcon
                name={Icons.CHEVRON_DOWN}
                width={20}
                height={20}
                fill={palette.primary.main}
              />
            }
          >
            <Typography variant={"h3"}>
              {getAccordionName(accordion)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{renderTable(accordion)}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default MacroAdjustmentStepThreeAccordionArray;
