import { MacroResults, SalesVolumeResult } from "orval/generated/models";
import { formatNumberOrEmpty } from "utils/formatNumber/formatNumber";
import getItemName from "./getItemName";
import {
  OptionalMetricsTableDataItem,
  OptionalMetricsTableResultsType,
} from "../Types";
import { ProductConfigurationsMacro } from "features/micro-tool/components/MicroAdjustmentsTable/types";

function getResultsOverviewSalesTableData(
  type: OptionalMetricsTableResultsType,
  scenarioResults: MacroResults,
) {
  if (type === "by_pillar") {
    return [];
  }

  const salesData =
    scenarioResults?.sales_volume?.[type as keyof SalesVolumeResult];
  const totalsData = scenarioResults?.sales_volume.total;

  const totalItem: {
    [x: string]: string;
    base: string;
    glide: string;
    scenario: string;
  } = {
    [type]: "Total",
    base: totalsData
      ? formatNumberOrEmpty(totalsData.base, ProductConfigurationsMacro.SALES)
      : "",
    glide: totalsData
      ? formatNumberOrEmpty(totalsData.glide, ProductConfigurationsMacro.SALES)
      : "",
    scenario: totalsData
      ? formatNumberOrEmpty(
          totalsData.scenario,
          ProductConfigurationsMacro.SALES,
        )
      : "",
  };

  const filteredDataArea = salesData
    ? Object.entries(salesData).map(([key, value]) => {
        return {
          [key]: value,
        };
      })
    : [];

  const tableData: OptionalMetricsTableDataItem[] = filteredDataArea
    .map((item) => {
      const itemValues = Object.values(item)[0];
      if (!itemValues) return null;

      const base = formatNumberOrEmpty(
        itemValues.base,
        ProductConfigurationsMacro.SALES,
      );
      const glide = formatNumberOrEmpty(
        itemValues.glide,
        ProductConfigurationsMacro.SALES,
      );
      const scenario = formatNumberOrEmpty(
        itemValues.scenario,
        ProductConfigurationsMacro.SALES,
      );

      const itemName = getItemName(item);

      return {
        [type]: itemName,
        base,
        glide,
        scenario,
      };
    })
    .filter((item): item is OptionalMetricsTableDataItem => item !== null);

  if (tableData && totalItem) {
    tableData.push(totalItem);
  }

  tableData &&
    tableData.sort((a, b) => {
      // Alphabetical with Total always at the end
      if (a[type] === "Total") return 1;
      if (b[type] === "Total") return -1;
      return a[type].localeCompare(b[type]);
    });

  return tableData as OptionalMetricsTableDataItem[];
}

export default getResultsOverviewSalesTableData;
