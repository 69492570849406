import { useEffect, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";
import {
  Accordion,
  AccordionDetails,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomIcon, Icons, NoData } from "components/common";
import { MicroProductsTable } from "./MicroProductsTable/MicroProductsTable";
import getProductsTableData from "./MicroProductsTable/getProductsTableData";
import { ProductColumnData } from "../../types";
import { ViewMicroScenarioDto } from "orval/generated/models";
import { COLUMN_WIDTH, TITLE_COLUMN_WIDTH } from "../../constants";
import AccordionSummary from "@mui/material/AccordionSummary";
import getTableColumns from "../HelperFunctions/getTableColumns";

export type MicroProductsTableSectionProps = {
  products: ProductColumnData[] | undefined;
  scenario: ViewMicroScenarioDto | undefined;
  disabled: boolean;
};

export function MicroProductsTableSection({
  products,
  scenario,
  disabled,
}: MicroProductsTableSectionProps) {
  const { t } = useTranslation("micro");
  const [data, setData] = useState<any>();
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const [expanded, setExpanded] = useState<boolean>(
    !!(products && products.length < 1),
  );
  const { palette } = useTheme();

  useEffect(() => {
    if (products?.length && scenario && scenario?.products) {
      setData(getProductsTableData(products, scenario.products));
      setColumns(
        getTableColumns(products, undefined, TITLE_COLUMN_WIDTH, false),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [products, scenario]);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Accordion
        disabled={disabled}
        expanded={expanded}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={
            <CustomIcon
              name={Icons.CHEVRON_RIGHT}
              width={18}
              height={18}
              fill={palette.primary.main}
            />
          }
          className="dropdown-left"
          tabIndex={0}
        >
          <Typography variant={"h2"}>{t("productsSection.title")}</Typography>
        </AccordionSummary>
        <>
          {!products || products?.length === 0 ? (
            <Box mt={2}>
              <NoData
                label={t("micro:productsSection.noDataMessage1")}
                label2={t("micro:productsSection.noDataMessage2")}
              ></NoData>
            </Box>
          ) : (
            <AccordionDetails>
              <>
                {products?.length && data?.length && columns?.length ? (
                  <Box
                    sx={{
                      width:
                        products.length * COLUMN_WIDTH + TITLE_COLUMN_WIDTH,
                      minWidth: "100%",
                    }}
                  >
                    <MicroProductsTable
                      data={data}
                      columns={columns}
                    ></MicroProductsTable>
                  </Box>
                ) : null}
              </>
            </AccordionDetails>
          )}
        </>
      </Accordion>
    </Box>
  );
}
