import { RouteObject } from "react-router-dom";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { MacroToolRoutesConfig } from "./config";
import MacroToolPage from "../routes/MacroToolPage/MacroToolPage";
import CreateScenarioPage from "../routes/MacroToolScenarioPage/MacroToolScenarioPage";
import { RootPage } from "components/sections";
import MacroAdjustmentsPage from "../routes/MacroAdjustmentsPage/MacroAdjustmentsPage";
import MacroAdjustmentWizard from "../routes/MacroAdjustmentWizard/MacroAdjustmentWizard";
import { getEnvironmentVariableValue } from "utils/environmentVariable";
import { EnvironmentVariable } from "constants/EnvironmentVariables";

//
// IMPORTANT - TO BE REMOVED WHEN WE RELEASE MACRO ADJUSTMENTS
const hideMacroAdjustments =
  getEnvironmentVariableValue(
    EnvironmentVariable.featureToggle.hideMacroAdjustments,
  ) === true;
// IMPORTANT - TO BE REMOVED WHEN WE RELEASE MACRO ADJUSTMENTS
//

export const MacroToolRoutes: RouteObject = {
  path: MacroToolRoutesConfig.countryLevelAnalytics,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    { index: true, element: <MacroToolPage /> },
    {
      path: MacroToolRoutesConfig.scenarioPage,
      element: (
        <CreateScenarioPage
          getEnvironmentVariableValue={getEnvironmentVariableValue}
        />
      ),
    },
    {
      path: MacroToolRoutesConfig.adjustmentsPage,
      element: (
        <ProtectedRoutes restrictToPwCEmail={hideMacroAdjustments}>
          <MacroAdjustmentsPage />
        </ProtectedRoutes>
      ),
    },
    {
      path: MacroToolRoutesConfig.createAdjustment,
      element: (
        <ProtectedRoutes restrictToPwCEmail={hideMacroAdjustments}>
          <MacroAdjustmentWizard />
        </ProtectedRoutes>
      ),
    },
    {
      path: MacroToolRoutesConfig.editAdjustment,
      element: (
        <ProtectedRoutes restrictToPwCEmail={hideMacroAdjustments}>
          <MacroAdjustmentWizard />
        </ProtectedRoutes>
      ),
    },
  ],
};
