import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_Row,
  MRT_Column,
} from "material-react-table";
import React, { ReactNode, useMemo, useState } from "react";
import {
  ProductConfigurationsMicro,
  ProductsEditableColumnConfigItem,
  rowValidationType,
} from "./types";
import { CustomIcon, Icons, MicroTableWrapper } from "components/common";
import {
  COLUMN_WIDTH,
  ROW_ACTION_COLUMN_WIDTH,
  TITLE_COLUMN_WIDTH,
} from "../../constants";
import MicroAdjustmentsCustomPill from "./MicroAdjustmentsCustomPill/MicroAdjustmentsCustomPill";
import { AppConstant } from "constants/AppConstant";
import { UserDataTypes } from "orval/generated/models";
import MicroAdjustmentsTableRowActions from "./MicroAdjustmentsTableRowActions/MicroAdjustmentsTableRowActions";
import { Typography, useTheme } from "@mui/material";
import { numberParser } from "utils/numberParser";
import validateData from "./HelperFunctions/validateData";
import { Box } from "@mui/system";
import { getDecimalPlacesForDataStructureKey } from "utils/formatNumber/formatNumber";

export type ProductsEditableTableProps = {
  columns: MRT_ColumnDef<any>[];
  data: any;
  onValueUpdate: Function;
  dataStructureKey: ProductConfigurationsMicro;
  metrics: ProductsEditableColumnConfigItem[];
  setIsEditing?: (value: boolean) => void;
  handleClearAdjustmentsForRow: Function;
  isSubAccordionTable: boolean;
  parentAccordion?: ProductConfigurationsMicro;
};

export const MicroAdjustmentsTable = ({
  columns,
  data,
  onValueUpdate,
  dataStructureKey,
  metrics,
  setIsEditing,
  handleClearAdjustmentsForRow,
  isSubAccordionTable,
  parentAccordion,
}: ProductsEditableTableProps) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const { palette } = useTheme();

  const getRegex = (decimalPlaces: number) => {
    return new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`);
  };

  const handleSave = (
    rowId: number,
    updatedValues: Record<string, string>,
    rowType: UserDataTypes,
    validationType: rowValidationType,
  ): void => {
    // disabled error handling for table row save
    const newValidationErrors = validateData(
      updatedValues,
      rowType,
      validationType,
    );
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});

    // Remove locale from product values
    const parsedValues = Object.fromEntries(
      Object.entries(updatedValues).map(([key, value]) => {
        return [
          key,
          isNaN(numberParser.parse(value))
            ? AppConstant.emptyCell
            : numberParser.parse(value),
        ];
      }),
    );

    onValueUpdate(rowId, parsedValues, rowType);
    table.setEditingRow(null); //exit editing mode
    if (setIsEditing) {
      setIsEditing(false);
    }
  };

  const columns_: any = useMemo(
    () =>
      columns.map((column) => {
        if (column.id === "metric")
          return {
            ...column,
            size: TITLE_COLUMN_WIDTH - ROW_ACTION_COLUMN_WIDTH,
          };
        const columnId = column.id?.toString() ?? "";
        const validationError = validationErrors[columnId];
        return {
          ...column,
          muiEditTextFieldProps: {
            required: true,
            error: !!validationError,
            helperText: validationError,
            placeholder: "",
            inputProps: {
              inputMode: "decimal",
              pattern: "-?[0-9]*[.,]?[0-9]*",
            },
            onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              const decimalPlaces =
                getDecimalPlacesForDataStructureKey(dataStructureKey);
              const regex = getRegex(decimalPlaces);
              if (decimalPlaces === 0 && value.includes(".")) {
                event.target.value = value.replace(".", "");
              } else if (!regex.test(value)) {
                event.target.value = value.slice(0, -1);
              }
            },
            onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
              const paste = event.clipboardData.getData("text");
              const decimalPlaces =
                getDecimalPlacesForDataStructureKey(dataStructureKey);
              const regex = getRegex(decimalPlaces);
              if (!regex.test(paste)) {
                event.preventDefault();
              }
            },
            onClick: (event: any) => {
              if (event.target.value === AppConstant.emptyCell) {
                event.target.value = "";
              }
            },
            onFocus: () => {
              setValidationErrors((prev) => ({
                ...prev,
                [columnId]: undefined,
              }));
            },
          },
          Cell: ({
            row,
            column,
            renderedCellValue,
          }: {
            row: MRT_Row<any>;
            column: MRT_Column<any, any>;
            renderedCellValue: string;
          }): ReactNode => {
            const isAdjustedValuePresent =
              row.original.subRows &&
              row.original.subRows.length &&
              row.original.subRows[0].type !== null;
            if (isAdjustedValuePresent) {
              return (
                <MicroAdjustmentsCustomPill
                  row={row.original}
                  column={column.columnDef}
                  value={renderedCellValue}
                  parentAccordion={parentAccordion}
                />
              );
            }
            const renderCellValue = (value: string) => (
              <Typography id={`${row.original.productName}-${row.original.metric}`} variant="body1">{value}</Typography>
            );
            if (renderedCellValue === AppConstant.emptyCell) {
              return renderCellValue(renderedCellValue);
            }
            return row.original.type === UserDataTypes.percentage
              ? renderCellValue(`${renderedCellValue}%`)
              : renderCellValue(renderedCellValue);
          },
        };
      }),
    [columns, dataStructureKey, validationErrors, parentAccordion],
  );

  const table = useMaterialReactTable({
    layoutMode: "grid",
    columns: columns_,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want\
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row",
    enableColumnPinning: true,
    enableEditing: (row) => !row.subRows?.length,
    enableExpanding: true,
    enableColumnResizing: false,
    icons: {
      SaveIcon: () => (
        <Box data-testid={"SaveIcon"} display={"flex"} alignItems={"center"}>
          <CustomIcon
            name={Icons.SAVE}
            width={24}
            height={24}
            fill={palette.primary.main}
          />
        </Box>
      ),
      CancelIcon: () => (
        <CustomIcon
          name={Icons.CIRCLE_DELETE}
          width={24}
          height={24}
          fill={palette.primary.main}
        />
      ),
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: ROW_ACTION_COLUMN_WIDTH, //adjust the size of the row select column
        grow: false,
        enableResizing: false,
      },
      "mrt-row-expand": {
        size: ROW_ACTION_COLUMN_WIDTH,
        grow: false, //new in v2.8 (allow this column to grow to fill in remaining space)
        enableResizing: false,
      },
    },
    defaultColumn: {
      size: COLUMN_WIDTH, //make columns wider by default
    },
    muiTableBodyCellProps: ({ column, row }) => {
      const iconColumns = ["mrt-row-expand", "mrt-row-actions", "metric"];
      return {
        sx: {
          display: row.subRows?.length
            ? column.id === "mrt-row-actions"
              ? "none"
              : "block"
            : column.id === "mrt-row-expand"
              ? "none"
              : "block",
          justifyContent: iconColumns.includes(column.id)
            ? "flex-start"
            : "center",
          borderLeft:
            iconColumns.includes(column.id) && column.id !== "metric"
              ? "none !important"
              : "",
          borderRight:
            iconColumns.includes(column.id) && column.id !== "metric"
              ? "none !important"
              : "",
          textAlign: "center",
          alignContent: "center",
          "& .css-1s5xrzf .MuiSvgIcon-root": {
            display: row.subRows?.length ? "initial" : "none",
          },
          borderTop: isSubAccordionTable
            ? `1px solid ${palette.textColor.light}`
            : "",
        },
      };
    },
    meta: {
      id: dataStructureKey,
    },
    state: {
      columnOrder: [
        "mrt-row-expand",
        "mrt-row-actions",
        ...columns_.map((column: any) => column.accessorKey),
      ],
    },
    renderRowActions: ({ row, table }) => {
      return (
        <MicroAdjustmentsTableRowActions
          row={row}
          table={table}
          setIsEditing={setIsEditing}
          handleClearAdjustmentsForRow={handleClearAdjustmentsForRow}
        />
      );
    },
    onEditingRowSave: ({ values, row }) => {
      const validationType: rowValidationType = {
        metric: row.original.metric,
        field_key: row.original.field_key,
        type: row.original.validationType,
        isNonZero: row.original.validationIsNonZero,
      };

      handleSave(
        metrics[Math.floor(row.id as unknown as number)]?.field_id as number,
        values,
        row.original.type,
        validationType,
      );
    },
    onEditingRowCancel: () => {
      if (setIsEditing) {
        setIsEditing(false);
      }
    },
    enableExpandAll: false,
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.grey[100],
        width: "100%",
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        table.setExpanded({ [row.id]: !row.getIsExpanded() });
        if (setIsEditing) {
          setIsEditing(false);
        }
        table.setEditingRow(null);
      },
      children: row.getIsExpanded() ? (
        <Box
          data-testid={"ExpandMoreIcon"}
          id={`ExpandMore-${row.original.metric}`}
        >
          <CustomIcon
            name={Icons.CHEVRON_DOWN}
            width={18}
            height={18}
            fill={palette.primary.main}
          />
        </Box>
      ) : (
        <Box
          data-testid={"ExpandMoreIcon"}
          id={`ExpandMore-${row.original.metric}`}
        >
          <CustomIcon
            name={Icons.CHEVRON_RIGHT}
            width={18}
            height={18}
            fill={palette.primary.main}
          />
        </Box>
      ),
      sx: {
        transition: "transform 0.2s",
      },
    }),
  });

  return (
    <MicroTableWrapper>
      <MaterialReactTable table={table} />
    </MicroTableWrapper>
  );
};
