import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { ProductColumnData } from "../../types";
import { useTranslation } from "react-i18next";
import { ViewMicroScenarioDto } from "orval/generated/models";
import MicroAdjustmentAccordion from "./MicroAdjustmentAccordion/MicroAdjustmentAccordion";
import { CustomIcon, Icons } from "components/common";
import { SUB_ACCORDION_INDENT } from "features/macro-tool";
import { ProductConfigurationsMicro } from "features/micro-tool/components/MicroAdjustmentsTable/types";

export type AdjustmentsSectionProps = {
  disabled: boolean;
  productColumnData: ProductColumnData[] | undefined;
  scenario: ViewMicroScenarioDto | undefined;
};

function MicroAdjustmentsSection({
  disabled,
  productColumnData,
  scenario,
}: AdjustmentsSectionProps) {
  const { t } = useTranslation("micro");
  const { palette } = useTheme();

  return (
    <Accordion disabled={disabled}>
      <AccordionSummary
        expandIcon={
          <CustomIcon
            name={Icons.CHEVRON_RIGHT}
            width={18}
            height={18}
            fill={palette.primary.main}
          />
        }
        className="dropdown-left"
      >
        <Typography variant={"h2"}>{t("adjustmentsSection.title")}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ marginLeft: SUB_ACCORDION_INDENT }}>
        {scenario?.products[0]?.adjustments &&
        scenario?.products[0]?.adjustments.length ? (
          scenario?.products[0]?.adjustments?.map((adjustment) => (
            <Box key={adjustment.accordion} data-testid="accordion">
              <MicroAdjustmentAccordion
                products={scenario?.products}
                productColumnData={productColumnData}
                accordion={adjustment}
                scenario={scenario}
                dataStructureKey={
                  adjustment.accordion as ProductConfigurationsMicro
                }
              />
            </Box>
          ))
        ) : (
          <Typography>
            {t("adjustmentsSection.unableToMakeAdjustments")}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default MicroAdjustmentsSection;
