import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MacroAdjustmentStepOne } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepOne/MacroAdjustmentStepOne";
import { MacroAdjustmentStepTwo } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/MacroAdjustmentStepTwo";
import { MacroAdjustmentStepThree } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepThree/MacroAdjustmentStepThree";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MacroToolRoutesConfig } from "../../navigation/config";
import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import { useModal } from "components/common/Modal";
import _ from "lodash";
import MacroAdjustmentsWizardHeaderAndStepper from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentsWizardHeaderAndStepper/MacroAdjustmentsWizardHeaderAndStepper";
import MacroAdjustmentsWizardFooter from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentsWizardFooter/MacroAdjustmentsWizardFooter";
import {
  AdjustmentActions,
  AdjustmentEntities,
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentInputAreas,
  MacroAdjustmentPayload,
  MacroAdjustmentPillars,
  MacroAdjustmentStep,
  MacroAdjustmentTypes,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import {
  useEditScenariosMacroScenarioIdAdjustmentsAdjustmentIdPatch,
  useGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGet,
  getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey,
  useGetMacroScenarioByIdScenariosMacroScenarioIdGet,
  useMacroIngredientMultiWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightMultiPost,
  useMacroIngredientSpecificWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightSpecificPost,
  getGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGetQueryKey,
  useMacroPackagingMultiWeightAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingWeightMultiPost,
  useMacroPackagingRecycledContentAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecycledContentPost,
  useMacroPackagingNetLossRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingNetLossRatePost,
  useMacroPackagingRecoveryRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecoveryRatePost,
  useMacroManufacturingEurAdjustmentScenariosMacroScenarioIdAdjustmentsManufacturingEurPost,
  useMacroLogisticsDistanceTravelledAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsDistanceTravelledPost,
  useMacroPackagingEfAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingEmissionFactorPost,
  useMacroIngredientEfAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsEmissionFactorPost,
  useMacroManufacturingSourceMixEfScenariosMacroScenarioIdAdjustmentsManufacturingSourceMixEfPost,
  useMacroLogisticsRoadVehicleMixEfAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsRoadVehicleMixEfPost,
  useMacroCdeEnergyMixEfAdjustmentScenariosMacroScenarioIdAdjustmentsCdeEnergyMixEfPost,
  useMacroCdeFleetBreakdownAdjustmentScenariosMacroScenarioIdAdjustmentsCdeFleetBreakdownPost,
  useMacroPackagingSpecificWeightAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingWeightSpecificPost,
  useMacroLogisticsOtherVehicleMixEfAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsOtherVehicleMixEfPost,
} from "orval/generated/endpoint";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import {
  CountryViewModel,
  IngredientWeightEntity,
  IngredientMultiWeightMacroAdjustmentDto,
  IngredientSpecificWeightMacroAdjustmentDto,
  type GetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGetParams,
  PackagingWeightEntityDto,
  PackagingRecoveryRateEntityWithCountryDto,
  PackagingRecycledContentEntityDto,
  PackagingRecycledContentMacroAdjustmentDto,
  PackagingRecoveryRateMacroAdjustmentDto,
  UserDataTypes,
  ManufacturingEurEntityWithCountryDto,
  ManufacturingEurMacroAdjustmentDto,
  LogisticsDistanceMacroAdjustmentDto,
  LogisticsDistanceEntityWithCountryDto,
  PackagingEfEntityDto,
  IngredientEfEntityDto,
  ManufacturingSourceMixEfEntityDto,
  ManufacturingSourceMixEfMacroAdjustmentDto,
  LogisticsVehicleMixEfEntityDto,
  LogisticsRoadVehicleMixEfMacroAdjustmentDto,
  CdeEnergyMixEfEntityDto,
  CdeFleetBreakdownEntityDto,
  CdeFleetBreakdownMacroAdjustmentDto,
  CdeFleetBreakdownActions,
  EmissionFactorActions,
  PackagingEfActions,
  PackagingNetLossRateMacroAdjustmentDto,
  PackagingNetLossRateEntityDto,
} from "orval/generated/models";
import { useQueryClient } from "@tanstack/react-query";
import useDocumentHead from "utils/useDocumentHead";

const MacroAdjustmentWizard = () => {
  const { t } = useTranslation(["macro", "common"]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();

  const { id: urlScenarioId, adjustmentId } = useParams<{
    id: string;
    adjustmentId: string;
  }>();
  const scenarioId = urlScenarioId as string;

  const [activeStep, setActiveStep] = useState(0);
  const headersIndent: string = location.state?.headersIndent || "0px";
  const showSnackbar = useSnackbar();
  const [adjustmentCombination, setAdjustmentCombination] =
    useState<MacroAdjustmentCombination | null>(null);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string | undefined;
  }>({});

  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const { data: scenarioDetails } =
    useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId), {
      query: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    });

  useDocumentHead({
    titleAsTranslatedString: `${t("common:documentHeads:macroCreateOrEditAdjustment")} ${scenarioDetails?.name}`,
  });

  const { data: adjustmentDetails } =
    useGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGet(
      parseInt(scenarioId),
      parseInt(adjustmentId as string),
      {
        query: {
          enabled: Boolean(adjustmentId),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        },
      },
    );

  const [macroFiltersParams, setMacroFiltersParams] =
    useState<GetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGetParams>({
      countries: [],
      target_year: 2030,
      categories: [],
      brands: [],
      brand_groups: [],
    });

  useEffect(() => {
    setMacroFiltersParams(
      Boolean(adjustmentId)
        ? {
            countries:
              (adjustmentDetails?.filters.countries as string[]) ||
              scenarioDetails?.inputs?.countries?.map(
                (country) => country.country,
              ) ||
              [],
            target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
            categories: adjustmentDetails?.filters.categories as number[],
            brands: adjustmentDetails?.filters.brands as number[],
            brand_groups: adjustmentDetails?.filters.brand_groups as number[],
          }
        : {
            countries:
              scenarioDetails?.inputs?.countries?.map(
                (country) => country.country,
              ) || [],
            target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
          },
    );
  }, [adjustmentDetails, adjustmentId, scenarioDetails]);

  const getStepTitle = useCallback(
    (
      stepIndex: number,
      formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
    ) => {
      if (stepIndex === 0) {
        return Boolean(adjustmentId)
          ? t("macro:adjustmentsPage.wizard.stepOneUpdateTitle")
          : t("macro:adjustmentsPage.wizard.stepOneTitle");
      } else if (stepIndex === 1 || stepIndex === 2) {
        return (
          formikProps.values.payload.name ||
          t("macro:adjustmentsPage.wizard.stepOneTitle")
        );
      }
    },
    [adjustmentId, t],
  );

  const getStepSubtitle = useCallback(() => {
    return (
      (adjustmentCombination && adjustmentCombination.name) ||
      t("macro:adjustmentsPage.wizard.stepOneTitle")
    );
  }, [adjustmentCombination, t]);

  const handleLeftButtonClick = () => {
    if (activeStep === 0) {
      navigateToAdjustments();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const macroAdjustmentValidationSchema = yup.object({
    payload: yup.object({
      description: yup.string().nullable(),
      entities: yup
        .array()
        .of(
          yup.object().shape({
            ingredient_id: yup.number().nullable(),
            part_material_id: yup.number().nullable(),
            adjusted_value: yup.number(),
            action: yup
              .mixed<UserDataTypes>()
              .oneOf(["percentage", "new_value"]),
          }),
        )
        .required(),
      filters: yup.object({
        brand_groups: yup.array().of(yup.number()),
        brands: yup.array().of(yup.number()),
        categories: yup.array().of(yup.number()),
        countries: yup.array().of(yup.string()),
        products: yup.array().of(yup.number()),
      }),
      name: yup
        .string()
        .min(
          3,
          t(
            "macro:adjustmentsPage.wizard.validationErrors.adjustmentNameCharactersMinLimit",
          ),
        )
        .max(
          100,
          t(
            "macro:adjustmentsPage.wizard.validationErrors.adjustmentNameCharactersMaxLimit",
          ),
        )
        .required(
          t("macro:adjustmentsPage.wizard.validationErrors.adjustmentName"),
        ),
    }),
    selectedPillar: yup
      .mixed<MacroAdjustmentPillars>()
      .oneOf(Object.values(MacroAdjustmentPillars))
      .nullable(),
    selectedInputArea: yup
      .mixed<MacroAdjustmentInputAreas>()
      .oneOf(Object.values(MacroAdjustmentInputAreas))
      .nullable(),
    selectedAdjustmentType: yup
      .mixed<MacroAdjustmentTypes>()
      .oneOf(Object.values(MacroAdjustmentTypes))
      .nullable(),
    selectedCountries: yup.array().of(
      yup.object().shape({
        country: yup.string().required(),
      }),
    ),
    selectedEntities: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
      }),
    ),
    selectedAction: yup
      .mixed<UserDataTypes>()
      .oneOf(["percentage", "new_value"]),
    selectedProducts: yup.array().of(yup.number()),
  });

  const updateAdjustmentCombination = useCallback(
    (
      selectedPillar: MacroAdjustmentPillars | null,
      selectedInputArea: MacroAdjustmentInputAreas | null,
      selectedAdjustmentType: MacroAdjustmentTypes | null,
    ) => {
      if (selectedPillar && selectedInputArea && selectedAdjustmentType) {
        const newSelectedInputArea = _.upperFirst(
          _.camelCase(selectedInputArea),
        );
        const newSelectedAdjustmentType = selectedAdjustmentType.includes("SKU")
          ? selectedAdjustmentType.replace(" SKU adjustment", "SKUAdjustment")
          : _.upperFirst(_.camelCase(selectedAdjustmentType));
        const combination: MacroAdjustmentCombination = {
          combo:
            `${selectedPillar}_${newSelectedInputArea}${selectedAdjustmentType !== MacroAdjustmentTypes.NotApplicable ? "_" + newSelectedAdjustmentType : ""}` as MacroAdjustmentCombinations,
          name: `${selectedPillar} - ${selectedInputArea}${selectedAdjustmentType !== MacroAdjustmentTypes.NotApplicable ? " - " + selectedAdjustmentType : ""}`,
        };
        setAdjustmentCombination(combination);
      } else {
        setAdjustmentCombination(null);
      }
    },
    [],
  );

  const steps = useCallback(
    (
      formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
    ): MacroAdjustmentStep[] => [
      {
        index: 0,
        label: adjustmentId
          ? t("macro:adjustmentsPage.wizard.stepOne.updateLabel")
          : t("macro:adjustmentsPage.wizard.stepOne.label"),
        component: (
          <MacroAdjustmentStepOne
            updateAdjustmentCombination={updateAdjustmentCombination}
            isEditWizard={Boolean(adjustmentId)}
            setMacroFiltersParams={setMacroFiltersParams}
            scenarioDetails={scenarioDetails}
          />
        ),
        leftBtnText: t("common:actions.cancel"),
        rightBtnText: t("common:actions.next"),
        stepTitle: adjustmentId
          ? t("macro:adjustmentsPage.wizard.stepOneUpdateTitle")
          : t("macro:adjustmentsPage.wizard.stepOneTitle"),
      },
      {
        index: 1,
        label: t("macro:adjustmentsPage.wizard.stepTwo.label"),
        component: (
          <MacroAdjustmentStepTwo
            adjustmentCombination={adjustmentCombination}
            scenario={scenarioDetails}
            setMacroFiltersParams={setMacroFiltersParams}
            macroFiltersParams={macroFiltersParams}
            adjustmentDetails={adjustmentDetails}
          />
        ),
        leftBtnText: t("common:actions.back"),
        rightBtnText: t("common:actions.next"),
        stepTitle: getStepTitle(1, formikProps) || "",
        stepSubtitle: getStepSubtitle(),
      },
      {
        index: 2,
        label: t("macro:adjustmentsPage.wizard.stepThree.label"),
        component: (
          <MacroAdjustmentStepThree
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            scenario={scenarioDetails}
            adjustmentCombination={adjustmentCombination?.combo}
          />
        ),
        leftBtnText: t("common:actions.back"),
        rightBtnText: t("common:actions.save"),
        stepTitle: getStepTitle(2, formikProps) || "",
        stepSubtitle: getStepSubtitle(),
      },
    ],
    [
      adjustmentCombination,
      adjustmentDetails,
      adjustmentId,
      getStepSubtitle,
      getStepTitle,
      macroFiltersParams,
      scenarioDetails,
      t,
      updateAdjustmentCombination,
      validationErrors,
    ],
  );

  const navigateToAdjustments = useCallback(() => {
    navigate(MacroToolRoutesConfig.adjustmentsPage.replace(":id", scenarioId), {
      state: {
        headersIndent: headersIndent,
      },
    });
  }, [headersIndent, navigate, scenarioId]);

  const handleNavigateToAdjustmentsPage = (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => {
    const showModal = !_.isEqual(formikProps.values, formikProps.initialValues);
    const modalProps = {
      title: t("macro:adjustmentsPage.wizard.lockInModal.title"),
      description: t("macro:adjustmentsPage.wizard.lockInModal.description"),
      actionTitle: t(
        "macro:adjustmentsPage.wizard.lockInModal.backToAdjustments",
      ),
      confirmAction: () => {
        navigateToAdjustments();
        closeModal();
      },
      cancelAction: closeModal,
    };
    showModal
      ? openModal(<ConfirmationModal {...modalProps} />)
      : navigateToAdjustments();
  };

  const createOptions = useMemo(
    () => ({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
              parseInt(scenarioId),
            ),
          });

          navigateToAdjustments();

          showSnackbar(
            t("macro:adjustmentsPage.wizard.successMessage"),
            "success",
          );
        },
      },
    }),
    [navigateToAdjustments, queryClient, scenarioId, showSnackbar, t],
  );

  const editOptions = useMemo(
    () => ({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
              parseInt(scenarioId),
            ),
          });

          await queryClient.invalidateQueries({
            queryKey:
              getGetByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGetQueryKey(
                parseInt(scenarioId),
                adjustmentId as unknown as number,
              ),
          });

          navigateToAdjustments();

          showSnackbar(
            t("macro:adjustmentsPage.wizard.updateSuccessMessage"),
            "success",
          );
        },
      },
    }),
    [
      adjustmentId,
      navigateToAdjustments,
      queryClient,
      scenarioId,
      showSnackbar,
      t,
    ],
  );

  const { mutateAsync: createIngredientsWeightMultiAdjustment } =
    useMacroIngredientMultiWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightMultiPost(
      createOptions,
    );

  const { mutateAsync: createIngredientsWeightSpecificAdjustment } =
    useMacroIngredientSpecificWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightSpecificPost(
      createOptions,
    );

  const { mutateAsync: createPackagingWeightMultiAdjustment } =
    useMacroPackagingMultiWeightAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingWeightMultiPost(
      createOptions,
    );

  const { mutateAsync: createPackagingWeightSpecificAdjustment } =
    useMacroPackagingSpecificWeightAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingWeightSpecificPost(
      createOptions,
    );

  const { mutateAsync: createRecycledContentAdjustment } =
    useMacroPackagingRecycledContentAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecycledContentPost(
      createOptions,
    );

  const { mutateAsync: createNetLossAdjustment } =
    useMacroPackagingNetLossRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingNetLossRatePost(
      createOptions,
    );

  const { mutateAsync: createRecoveryRateAdjustment } =
    useMacroPackagingRecoveryRateAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingRecoveryRatePost(
      createOptions,
    );

  const { mutateAsync: createEurAdjustment } =
    useMacroManufacturingEurAdjustmentScenariosMacroScenarioIdAdjustmentsManufacturingEurPost(
      createOptions,
    );

  const { mutateAsync: createDistanceTranveledAdjustment } =
    useMacroLogisticsDistanceTravelledAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsDistanceTravelledPost(
      createOptions,
    );

  const { mutateAsync: createPackagingEfAdjustment } =
    useMacroPackagingEfAdjustmentScenariosMacroScenarioIdAdjustmentsPackagingEmissionFactorPost(
      createOptions,
    );

  const { mutateAsync: createIngredientEfAdjustment } =
    useMacroIngredientEfAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsEmissionFactorPost(
      createOptions,
    );

  const { mutateAsync: createManufacturingEfAdjustment } =
    useMacroManufacturingSourceMixEfScenariosMacroScenarioIdAdjustmentsManufacturingSourceMixEfPost(
      createOptions,
    );

  const { mutateAsync: createLogisticsRoadEfAdjustment } =
    useMacroLogisticsRoadVehicleMixEfAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsRoadVehicleMixEfPost(
      createOptions,
    );

  const { mutateAsync: createLogisticsOtherEfAdjustment } =
    useMacroLogisticsOtherVehicleMixEfAdjustmentScenariosMacroScenarioIdAdjustmentsLogisticsOtherVehicleMixEfPost(
      createOptions,
    );

  const { mutateAsync: createCdeEnergyMixEfAdjustment } =
    useMacroCdeEnergyMixEfAdjustmentScenariosMacroScenarioIdAdjustmentsCdeEnergyMixEfPost(
      createOptions,
    );

  const { mutateAsync: createCdeFleetBreakdownAdjustment } =
    useMacroCdeFleetBreakdownAdjustmentScenariosMacroScenarioIdAdjustmentsCdeFleetBreakdownPost(
      createOptions,
    );

  const { mutateAsync: editAdjustment } =
    useEditScenariosMacroScenarioIdAdjustmentsAdjustmentIdPatch(editOptions);

  const getSaveFunction = useCallback(() => {
    if (!adjustmentCombination) return undefined;

    if (adjustmentId) return editAdjustment;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return createIngredientsWeightMultiAdjustment;

      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return createIngredientsWeightSpecificAdjustment;

      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        return createPackagingWeightMultiAdjustment;

      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return createPackagingWeightSpecificAdjustment;

      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return createRecycledContentAdjustment;

      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return createNetLossAdjustment;

      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return createRecoveryRateAdjustment;

      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return createEurAdjustment;

      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return createDistanceTranveledAdjustment;

      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return createPackagingEfAdjustment;

      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
        return createIngredientEfAdjustment;

      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
        return createManufacturingEfAdjustment;

      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
        return createLogisticsRoadEfAdjustment;

      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        return createLogisticsOtherEfAdjustment; //TODO: return create losistics Other instead

      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
        return createCdeEnergyMixEfAdjustment;

      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        return createCdeFleetBreakdownAdjustment;

      default:
        return undefined;
    }
  }, [
    adjustmentCombination,
    adjustmentId,
    createCdeEnergyMixEfAdjustment,
    createCdeFleetBreakdownAdjustment,
    createDistanceTranveledAdjustment,
    createEurAdjustment,
    createIngredientEfAdjustment,
    createIngredientsWeightMultiAdjustment,
    createIngredientsWeightSpecificAdjustment,
    createLogisticsOtherEfAdjustment,
    createLogisticsRoadEfAdjustment,
    createManufacturingEfAdjustment,
    createNetLossAdjustment,
    createPackagingEfAdjustment,
    createPackagingWeightMultiAdjustment,
    createPackagingWeightSpecificAdjustment,
    createRecoveryRateAdjustment,
    createRecycledContentAdjustment,
    editAdjustment,
  ]);

  const getPayload = useCallback(
    (
      formikValues: MacroAdjustmentWizardFormikValues,
    ): MacroAdjustmentPayload => {
      if (!adjustmentCombination) return undefined;

      switch (adjustmentCombination.combo) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment: {
          const payloadIngredientEntities: IngredientWeightEntity[] =
            formikValues.payload.entities as IngredientWeightEntity[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadIngredientEntities.map(
              (entity: IngredientWeightEntity) => ({
                weight: entity.weight || 0,
                ingredient_id: entity.ingredient_id,
              }),
            ),
            filters: {
              brand_groups: formikValues.payload.filters.brand_groups || [],
              brands: formikValues.payload.filters.brands || [],
              categories: formikValues.payload.filters.categories || [],
              countries: formikValues.payload.filters.countries || [],
            },
          } as IngredientMultiWeightMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
          const payloadIngredientEntities: IngredientWeightEntity[] =
            formikValues.payload.entities as IngredientWeightEntity[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadIngredientEntities.map(
              (entity: IngredientWeightEntity) => ({
                weight: entity.weight || 0,
                ingredient_id: entity.ingredient_id,
              }),
            ),
            filters: {
              brand_groups: formikValues.payload.filters.brand_groups || [],
              brands: formikValues.payload.filters.brands || [],
              categories: formikValues.payload.filters.categories || [],
              countries: formikValues.payload.filters.countries || [],
            },
            actions: {
              weight: formikValues.selectedAction,
            },
          } as IngredientSpecificWeightMacroAdjustmentDto;

        case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment: {
          const payloadPackagingEntities: PackagingWeightEntityDto[] =
            formikValues.payload.entities as PackagingWeightEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadPackagingEntities
              .map((entity: PackagingWeightEntityDto) => ({
                weight: entity.weight || 0,
                part_material_id: entity.part_material_id,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.part_material_id) && !isNaN(entity.weight),
              ) as PackagingWeightEntityDto[],
            filters: {
              products: formikValues.selectedProducts || [],
            },
            ...(adjustmentCombination.combo ===
            MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment
              ? {
                  actions: {
                    weight: formikValues.selectedAction,
                  },
                }
              : {}),
          };
        }

        case MacroAdjustmentCombinations.Packaging_NetLossRate: {
          const payloadPackagingEntities: PackagingNetLossRateEntityDto[] =
            formikValues.payload.entities as PackagingNetLossRateEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadPackagingEntities
              .map((entity: PackagingNetLossRateEntityDto) => ({
                net_loss_rate: entity.net_loss_rate || 0,
                part_material_id: entity.part_material_id,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.part_material_id) &&
                  !isNaN(entity.net_loss_rate),
              ) as PackagingNetLossRateEntityDto[],
            filters: {
              products: formikValues.selectedProducts || [],
            },
          } as PackagingNetLossRateMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Packaging_RecycledContent: {
          const payloadPackagingEntities: PackagingRecycledContentEntityDto[] =
            formikValues.payload
              .entities as PackagingRecycledContentEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadPackagingEntities
              .map((entity: PackagingRecycledContentEntityDto) => ({
                recycled_content: entity.recycled_content || 0,
                part_material_id: entity.part_material_id,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.part_material_id) &&
                  !isNaN(entity.recycled_content),
              ) as PackagingRecycledContentEntityDto[],
            filters: {
              products: formikValues.selectedProducts || [],
            },
          } as PackagingRecycledContentMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Packaging_RecoveryRate: {
          const payloadPackagingEntities: PackagingRecoveryRateEntityWithCountryDto[] =
            formikValues.payload
              .entities as PackagingRecoveryRateEntityWithCountryDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadPackagingEntities
              .map((entity: PackagingRecoveryRateEntityWithCountryDto) => ({
                recovery_rate: entity.recovery_rate || 0,
                part_material_id: entity.part_material_id,
                country: entity.country,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.part_material_id) &&
                  !isNaN(entity.recovery_rate),
              ) as PackagingRecoveryRateEntityWithCountryDto[],
            filters: {
              products: formikValues.selectedProducts || [],
            },
          } as PackagingRecoveryRateMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors: {
          const payloadEntities: PackagingEfEntityDto[] = formikValues.payload
            .entities as PackagingEfEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadEntities.map((entity) => ({
              part_material_id: entity.part_material_id,
              supplier: entity.supplier,
              supply_share: entity.supply_share || 0,
              virgin_ef: entity.virgin_ef || 0,
              recycled_ef: entity.recycled_ef || 0,
            })),
            filters: {
              country: formikValues.payload.filters.countries[0] as string,
            },
            actions: {
              virgin_ef:
                (formikValues.payload.actions as PackagingEfActions)
                  ?.virgin_ef || UserDataTypes.percentage,
              recycled_ef:
                (formikValues.payload.actions as PackagingEfActions)
                  ?.recycled_ef || UserDataTypes.percentage,
            },
          };
        }

        case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors: {
          const payloadEntities: IngredientEfEntityDto[] = formikValues.payload
            .entities as IngredientEfEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadEntities.map((entity) => ({
              ingredient_id: entity.ingredient_id,
              supplier: entity.supplier,
              supply_share: entity.supply_share || 0,
              ef: entity.ef || 0,
            })),
            filters: {
              country: formikValues.payload.filters.countries[0] as string,
            },
            actions: {
              ef:
                (formikValues.payload.actions as EmissionFactorActions)?.ef ||
                UserDataTypes.percentage,
            },
          };
        }

        case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors: {
          const payloadEntities: ManufacturingSourceMixEfEntityDto[] =
            formikValues.payload
              .entities as ManufacturingSourceMixEfEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadEntities.map((entity) => ({
              energy_type_id: entity.energy_type_id,
              energy_source: entity.energy_source,
              supply_share: entity.supply_share || 0,
              ef: entity.ef || 0,
            })),
            filters: {
              country: formikValues.payload.filters.countries[0] as string,
            },
            actions: {
              ef:
                (formikValues.payload.actions as EmissionFactorActions)?.ef ||
                UserDataTypes.percentage,
            },
          } as ManufacturingSourceMixEfMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors: {
          const payloadEntities: LogisticsVehicleMixEfEntityDto[] = formikValues
            .payload.entities as LogisticsVehicleMixEfEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadEntities.map((entity) => ({
              transport_mode_id: entity.transport_mode_id,
              vehicle: entity.vehicle,
              usage_share: entity.usage_share || 0,
              ef: entity.ef || 0,
            })),
            filters: {
              country: formikValues.payload.filters.countries[0] as string,
            },
            actions: {
              ef:
                (formikValues.payload.actions as EmissionFactorActions)?.ef ||
                UserDataTypes.percentage,
            },
          } as LogisticsRoadVehicleMixEfMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors: {
          const payloadEntities: CdeEnergyMixEfEntityDto[] = formikValues
            .payload.entities as CdeEnergyMixEfEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadEntities.map((entity) => ({
              country: entity.country,
              energy_source: entity.energy_source,
              supply_share: entity.supply_share || 0,
              ef: entity.ef || 0,
            })),
            filters: {
              countries: formikValues.payload.filters.countries as string[],
            },
            actions: {
              ef:
                (formikValues.payload.actions as EmissionFactorActions)?.ef ||
                UserDataTypes.percentage,
            },
          };
        }

        case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown: {
          const payloadEntities: CdeFleetBreakdownEntityDto[] = formikValues
            .payload.entities as CdeFleetBreakdownEntityDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadEntities.map((entity) => ({
              country: entity.country,
              cde_group: entity.cde_group,
              number_of_units: entity.number_of_units || 0,
              avg_energy_consumption: entity.avg_energy_consumption || 0,
            })),
            filters: {
              countries: formikValues.payload.filters.countries as string[],
            },

            actions: {
              number_of_units:
                (formikValues.payload.actions as CdeFleetBreakdownActions)
                  ?.number_of_units || UserDataTypes.percentage,
              avg_energy_consumption:
                (formikValues.payload.actions as CdeFleetBreakdownActions)
                  ?.avg_energy_consumption || UserDataTypes.percentage,
            },
          } as CdeFleetBreakdownMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur: {
          const payloadManufacturingEntities: ManufacturingEurEntityWithCountryDto[] =
            formikValues.payload
              .entities as ManufacturingEurEntityWithCountryDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadManufacturingEntities
              .map((entity: ManufacturingEurEntityWithCountryDto) => ({
                eur: entity.eur || 0,
                energy_type_id: entity.energy_type_id,
                country: entity.country,
              }))
              .filter(
                (entity) => !isNaN(entity.energy_type_id) && !isNaN(entity.eur),
              ) as AdjustmentEntities,
            filters: {
              products: formikValues.selectedProducts || [],
            },
            actions: {
              eur: formikValues.selectedAction,
            },
          } as ManufacturingEurMacroAdjustmentDto;
        }

        case MacroAdjustmentCombinations.Logistics_DistanceTravelled: {
          const payloadDistanceTravelledEntities: LogisticsDistanceEntityWithCountryDto[] =
            formikValues.payload
              .entities as LogisticsDistanceEntityWithCountryDto[];
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description || null,
            entities: payloadDistanceTravelledEntities
              .map((entity: LogisticsDistanceEntityWithCountryDto) => ({
                distance: entity.distance || 0,
                transport_mode_id: entity.transport_mode_id,
                country: entity.country,
              }))
              .filter(
                (entity) =>
                  !isNaN(entity.transport_mode_id) && !isNaN(entity.distance),
              ),
            filters: {
              products: formikValues.selectedProducts || [],
            },
            actions: {
              distance: formikValues.selectedAction,
            },
          } as LogisticsDistanceMacroAdjustmentDto;
        }
        default:
          return undefined;
      }
    },
    [adjustmentCombination],
  );

  const handleSaveAdjustment = useCallback(
    async (formikValues: MacroAdjustmentWizardFormikValues): Promise<void> => {
      try {
        const saveFunction: any = getSaveFunction();
        const payload = getPayload(formikValues);
        if (saveFunction) {
          await saveFunction({
            scenarioId: scenarioId,
            adjustmentId: adjustmentId,
            data: payload,
          });
        }
      } catch (error) {
        showSnackbar(
          t("macro:adjustmentsPage.wizard.failedToUpdateAdjustment"),
          "error",
        );
        console.warn(
          `Error ${adjustmentId ? "updating" : "creating"} adjustment: `,
          error,
        );
      }
    },
    [getSaveFunction, getPayload, adjustmentId, scenarioId, showSnackbar, t],
  );

  const formikInitialValues: MacroAdjustmentWizardFormikValues = useMemo(() => {
    return {
      payload: {
        description: adjustmentDetails?.description || "",
        entities: [],
        filters: {
          brand_groups:
            (adjustmentDetails?.filters.brand_groups as number[]) || [],
          brands: (adjustmentDetails?.filters.brands as number[]) || [],
          categories: (adjustmentDetails?.filters.categories as number[]) || [],
          countries:
            (adjustmentDetails?.filters.country
              ? ([adjustmentDetails?.filters.country] as string[])
              : (adjustmentDetails?.filters.countries as string[])) || [],
          products: (adjustmentDetails?.filters.products as number[]) || [],
        },
        name: adjustmentDetails?.name || "",
        actions: (adjustmentDetails?.actions ??
          {}) as unknown as AdjustmentActions,
      },
      selectedPillar:
        (adjustmentDetails?.pillar as MacroAdjustmentPillars) || null,
      selectedInputArea:
        (adjustmentDetails?.input_area as MacroAdjustmentInputAreas) || null,
      selectedAdjustmentType: adjustmentDetails?.adjustment_type
        ? (adjustmentDetails?.adjustment_type as MacroAdjustmentTypes)
        : MacroAdjustmentTypes.NotApplicable || null,
      selectedCountries:
        (adjustmentDetails?.filters.country
          ? ([adjustmentDetails?.filters.country] as string[])
          : (adjustmentDetails?.filters.countries as string[])
        )?.map(
          (country) =>
            scenarioDetails?.inputs?.countries?.find(
              (c) => c.country === country,
            ) as CountryViewModel,
        ) || [],
      selectedEntities: [],
      selectedAction:
        ((adjustmentDetails?.actions.eur ||
          adjustmentDetails?.actions.weight ||
          adjustmentDetails?.actions.recycled_content ||
          adjustmentDetails?.actions.distance) as UserDataTypes) ||
        "percentage",
      selectedProducts:
        (adjustmentDetails?.filters.products as number[]) || ([] as number[]),
    };
  }, [adjustmentDetails, scenarioDetails?.inputs?.countries]);

  const handleRightButtonClick = useCallback(
    async (
      formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
    ): Promise<void> => {
      if (activeStep < steps(formikProps).length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        await handleSaveAdjustment(formikProps.values);
      }
    },
    [activeStep, handleSaveAdjustment, steps],
  );

  useEffect(() => {
    if (adjustmentDetails) {
      updateAdjustmentCombination(
        adjustmentDetails.pillar as MacroAdjustmentPillars,
        adjustmentDetails.input_area as MacroAdjustmentInputAreas,
        adjustmentDetails?.adjustment_type
          ? (adjustmentDetails?.adjustment_type as MacroAdjustmentTypes)
          : MacroAdjustmentTypes.NotApplicable,
      );
    }
  }, [adjustmentDetails, updateAdjustmentCombination]);

  useEffect(() => {
    if (scenarioDetails && adjustmentDetails) {
      setIsFormInitialized(true);
    }
  }, [adjustmentDetails, scenarioDetails]);

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={macroAdjustmentValidationSchema}
      enableReinitialize={!isFormInitialized}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Box>
              <MacroAdjustmentsWizardHeaderAndStepper
                handleNavigateToAdjustmentsPage={
                  handleNavigateToAdjustmentsPage
                }
                formikProps={formikProps}
                headersIndent={headersIndent}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                adjustmentCombination={adjustmentCombination?.combo}
              ></MacroAdjustmentsWizardHeaderAndStepper>

              {/*IMPORTANT: This is the actual step component*/}
              <Box mb={4}>{steps(formikProps)[activeStep].component}</Box>

              <MacroAdjustmentsWizardFooter
                handleLeftButtonClick={handleLeftButtonClick}
                steps={steps}
                formikProps={formikProps}
                activeStep={activeStep}
                handleRightButtonClick={handleRightButtonClick}
                adjustmentCombination={adjustmentCombination?.combo || null}
                validationErrors={validationErrors}
              ></MacroAdjustmentsWizardFooter>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MacroAdjustmentWizard;
