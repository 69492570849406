import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { CustomIcon, Icons } from "components/common";
import { useNavigate } from "react-router-dom";
import MacroAdjustmentsPageTable from "../MacroAdjustmentsPageTable/MacroAdjustmentsPageTable";
import { MacroToolRoutesConfig } from "features/macro-tool/navigation";
import { ViewMacroScenarioDto } from "orval/generated/models";

export type MacroAdjustmentsBodyProps = {
  scenario: ViewMacroScenarioDto;
};

const MacroAdjustmentsPageBody = ({ scenario }: MacroAdjustmentsBodyProps) => {
  const { t } = useTranslation("macro");
  const navigate = useNavigate();
  const handleCreateAdjustment = () => {
    navigate(
      MacroToolRoutesConfig.createAdjustment.replace(
        ":id",
        String(scenario.id),
      ),
    );
  };

  return (
    <Box mt={3}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box tabIndex={0}>
          <Typography className="text-ellipsis" variant={"h2"}>
            {t("adjustmentsPage.title")}
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={handleCreateAdjustment}
            startIcon={
              <CustomIcon
                name={Icons.ADD}
                width={"24px"}
                height={"24px"}
                fill="white"
              />
            }
          >
            {t("adjustmentsPage.btnCreateNewAdjustment")}
          </Button>
        </Box>
      </Box>
      <Box width={"90%"} minHeight="45px" mt={1} tabIndex={0}>
        <Typography overflow="hidden">
          {t("adjustmentsPage.description")}
        </Typography>
      </Box>
      {scenario ? <MacroAdjustmentsPageTable scenario={scenario} /> : null}
    </Box>
  );
};

export default MacroAdjustmentsPageBody;
