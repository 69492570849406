import { Box } from "@mui/material";
import AccountMenu from "../AccountMenu/AccountMenu";
import pwcLogo from "../../../assets/images/PwC_PEF_Logotype.svg";
import { useTranslation } from "react-i18next";
import ReportIssue from "../ReportIssue/ReportIssue";
import { HomeRoutesConfig } from "../../../features/home/navigation/config";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "core/navigation/usePermissions";
import { NavigationMenuOptions } from "./NavigationMenuConstants";
import NavigationMenuOption from "./NavigationMenuOption";

export default function Header() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { hasFunctionalAccess } = usePermissions();
  return (
    <Box className="header" data-testid={"header"}>
      <Box
        onClick={() => navigate(HomeRoutesConfig.homePage)}
        sx={{ cursor: "pointer" }}
      >
        <img
          src={pwcLogo as string}
          alt={t("appLogotypeAltText")}
          aria-labelledby={t("appLogotypeAltText")}
          loading="lazy"
          style={{ minHeight: "26px" }}
        />
      </Box>
      <Box display="flex" alignItems="center">
        {hasFunctionalAccess && (
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {NavigationMenuOptions.map((option) => (
              <NavigationMenuOption key={option.title} option={option} />
            ))}
          </Box>
        )}

        <ReportIssue />
        <AccountMenu />
      </Box>
    </Box>
  );
}
