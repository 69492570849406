import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { CustomTabPanel, CustomTabs } from "components/common";
import { scenarioResultsTabs } from "../../constants";
import { MacroResults, Years } from "orval/generated/models";
import { ResultsTabSection } from "./ResultsTabSection/ResultsTabSection";
import { DataVisualisationTabSection } from "./DataVisualisationTabSection";
import { MacroResultsAccordionEnum } from "features/macro-tool/components/ScenarioResults/types";

type ScenarioResultsProps = {
  scenarioResults: MacroResults;
  scenarioYears: Years;
  disabled: boolean;
};

export const accordionMap: { [key: string]: MacroResultsAccordionEnum[] } = {
  [MacroResultsAccordionEnum.Overview]: [
    MacroResultsAccordionEnum.OverviewByCategories,
    MacroResultsAccordionEnum.OverviewByPackType,
    MacroResultsAccordionEnum.OverviewByPillar,
  ],
  [MacroResultsAccordionEnum.PillarDeepDive]: [
    MacroResultsAccordionEnum.PillarDeepDiveByPackType,
    MacroResultsAccordionEnum.PillarDeepDiveByCategories,
  ],
  [MacroResultsAccordionEnum.EmissionsHeatMap]: [],
  [MacroResultsAccordionEnum.TimeEvolution]: [
    MacroResultsAccordionEnum.TimeEvolutionByCategory,
    MacroResultsAccordionEnum.TimeEvolutionByPackType,
  ],
  [MacroResultsAccordionEnum.Waterfall]: [
    MacroResultsAccordionEnum.WaterfallByCategory,
    MacroResultsAccordionEnum.WaterfallByPackType,
  ],
  [MacroResultsAccordionEnum.DataVisualisations]: [
    MacroResultsAccordionEnum.EmissionsHeatMap,
    MacroResultsAccordionEnum.TimeEvolution,
    MacroResultsAccordionEnum.TimeEvolutionByCategory,
    MacroResultsAccordionEnum.TimeEvolutionByPackType,
    MacroResultsAccordionEnum.Waterfall,
    MacroResultsAccordionEnum.WaterfallByCategory,
    MacroResultsAccordionEnum.WaterfallByPackType,
  ],
  [MacroResultsAccordionEnum.Results]: [
    MacroResultsAccordionEnum.Overview,
    MacroResultsAccordionEnum.OverviewByCategories,
    MacroResultsAccordionEnum.OverviewByPackType,
    MacroResultsAccordionEnum.OverviewByPillar,
    MacroResultsAccordionEnum.PillarDeepDive,
    MacroResultsAccordionEnum.PillarDeepDiveByPackType,
    MacroResultsAccordionEnum.PillarDeepDiveByCategories,
  ],
};

export function ScenarioResults({
  scenarioResults,
  scenarioYears,
  disabled,
}: ScenarioResultsProps) {
  const { t } = useTranslation("macro");
  const [value, setValue] = useState(0);
  const [expandedIds, setExpandedIds] = useState<string[]>([
    ...accordionMap[MacroResultsAccordionEnum.Results],
    ...accordionMap[MacroResultsAccordionEnum.DataVisualisations],
  ]);

  const handleToggle = (id: string) => {
    setExpandedIds((prev) =>
      prev.includes(id)
        ? prev.filter((expandedId) => expandedId !== id)
        : [...prev, id],
    );
  };

  const isExpanded = (id: string) => expandedIds.includes(id);

  const handleExpandCollapseAll = (
    parentAccordionKey: MacroResultsAccordionEnum,
  ) => {
    const selectedAccordions: string[] = accordionMap[parentAccordionKey];

    setExpandedIds((prev) => {
      let newExpandedIds: string[];
      const allSelectedAccordionsExpanded = selectedAccordions.every(
        (accordion) => prev.includes(accordion),
      );

      if (allSelectedAccordionsExpanded) {
        // collapse selected accordions
        newExpandedIds = prev.filter(
          (accordion) => !selectedAccordions.includes(accordion),
        );
      } else {
        // expand selected accordions
        newExpandedIds = [...prev];
        selectedAccordions.forEach((accordion) => {
          if (!newExpandedIds.includes(accordion)) {
            newExpandedIds.push(accordion);
          }
        });
      }

      return newExpandedIds;
    });
  };

  return (
    <Box pt={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontWeight={500}>
          {t("createScenarioSection.scenarioResultsHeading")}
        </Typography>
      </Box>
      <Box>
        <Box borderBottom={1} borderColor="divider">
          <CustomTabs
            tabList={scenarioResultsTabs.tabs}
            value={value}
            setValue={setValue}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          <DataVisualisationTabSection
            disabled={disabled}
            scenarioResults={scenarioResults}
            scenarioYears={scenarioYears}
            expandedIds={expandedIds}
            handleExpandCollapseAll={handleExpandCollapseAll}
            isExpanded={isExpanded}
            handleToggle={handleToggle}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ResultsTabSection
            disabled={disabled}
            scenarioResults={scenarioResults}
            scenarioYears={scenarioYears}
            expandedIds={expandedIds}
            handleExpandCollapseAll={handleExpandCollapseAll}
            isExpanded={isExpanded}
            handleToggle={handleToggle}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
