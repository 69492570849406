import { Box } from "@mui/material";
import { Bar } from "react-chartjs-2";
import Chart, { ChartData, LegendItem, TooltipItem } from "chart.js/auto";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { DATALABEL_PROPORTION_DISPLAY_THRESHOLD } from "features/macro-tool/constants";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { timeChartData } from "../../../types";
import { StackAxisLabelsPlugin } from "../../emission-time/plugins/StackAxisLabelsPlugin";
import { TotalLabelsPlugin } from "../../emission-time/plugins/TotalLabelsPlugin";
import { TableResultsFilterDataType } from "../../ScenarioResults/types";
import getStackColor from "../HelperFunctions/getStackColor";
import { LegendMarginPlugin } from "../../emission-time/plugins/LegendMarginPlugin";
import { t } from "i18next";

export type EmissionTimeChartProps = {
  labels: string[];
  timeChartData: timeChartData;
  units: TableResultsFilterDataType;
};

Chart.register(ChartDataLabels);

function EmissionTimeChart({
  labels,
  timeChartData,
  units,
}: EmissionTimeChartProps) {
  const data = {
    labels,
    datasets: timeChartData,
  } as const;

  const mainAxis = {
    ticks: {
      padding: 25,
    },
    grid: {
      display: false,
    },
  };

  const shouldDatalabelDisplay = (context: Context) => {
    let highestBarValue = 0;
    timeChartData[0].data.forEach((dataPoint, index: number) => {
      const barTotal = timeChartData.reduce((sum, chartItem) => {
        return sum + (chartItem.data[index] as number);
      }, 0);

      highestBarValue = Math.max(barTotal, highestBarValue);
    });

    const chartItemValue = context.dataset.data[context.dataIndex];
    const chartItemProportion = Number(chartItemValue) / highestBarValue;

    return chartItemProportion >= DATALABEL_PROPORTION_DISPLAY_THRESHOLD;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // for chart to take full width of screen
    scaleShowVerticalLines: false,
    scales: {
      x: {
        stacked: true,

        ...mainAxis,
      },
      y: {
        ...mainAxis,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        align: "start" as const,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          font: { size: 14 },
          padding: 50,
          filter: (item: LegendItem, chart: ChartData) => {
            // checks if the item is the first dataset with the same label
            // to display only one label for each dataset
            const firstDatasetIndex = chart.datasets.findIndex(
              (dataset) => dataset.label === item.text,
            );
            return item.datasetIndex === firstDatasetIndex;
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: shouldDatalabelDisplay,
        color: (context: Context) => {
          const item = context.chart.data.datasets[context.datasetIndex];
          return getStackColor(item.label).textColor;
        },
        font: {
          size: 12,
        },
        anchor: "center" as const,
        align: "center" as const,
        formatter: (value: any) => {
          return typeof value === "number" && !isNaN(value)
            ? formatNumber(value, units)
            : value;
        },
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<"bar">) => {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += formatNumber(context.parsed.y, units);
            }
            return label;
          },
        },
      },
      totalLabels: {
        units: units,
      },
    },
  };

  return (
    <Box
      sx={{ height: "528px", overflowX: "auto" }}
      title={t("common:a11y.macroTimeEvolutionCategoryChartExplanation")}
    >
      <div style={{ width: "3000px", height: "100%" }}>
        <Bar
          options={options}
          data={data}
          plugins={[
            StackAxisLabelsPlugin,
            TotalLabelsPlugin,
            LegendMarginPlugin,
          ]}
        />
      </div>
    </Box>
  );
}

export default EmissionTimeChart;
