import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useModal } from "components/common/Modal";
import UpdateDetailsModal from "../../../../components/common/UpdateDetailsModal/UpdateDetailsModal";
import {
  getGetAllMicroScenariosScenariosMicroGetQueryKey,
  useDeleteMicroScenarioByIdScenariosMicroScenarioIdDelete,
  useDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost,
  useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch,
} from "orval/generated/endpoint";
import React, { SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ListMicroScenarioDto } from "orval/generated/models";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { CustomIcon, Icons } from "components/common";
import ThreeDotMenu from "components/common/ThreeDotMenu/ThreeDotMenu";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import AdjustmentCountChip from "features/micro-tool/components/MicroAdjustmentsSection/MicroCustomAccordion/AdjustmentCountChip/AdjustmentCountChip";

export type MicroSingleScenarioProps = {
  scenario: ListMicroScenarioDto;
  showDescription?: boolean;
};

function MicroSingleScenario({
  scenario,
  showDescription = true,
}: MicroSingleScenarioProps) {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "micro"]);
  const { openModal, closeModal } = useModal();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { palette } = useTheme();

  const { mutate: duplicateScenario } =
    useDuplicateMicroScenarioScenariosMicroScenarioIdDuplicatePost({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMicroScenariosScenariosMicroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioDuplicated"), "success");
        },
        onError: () => {
          showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
        },
      },
    });

  const { mutateAsync: updateMicroScenarioDetails } =
    useUpdateMicroScenarioDetailsScenariosMicroScenarioIdPatch({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMicroScenariosScenariosMicroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioUpdated"), "success");
        },
        onError: (error: AxiosError) => {
          showSnackbar(t("micro:errorMessages.errorUpdating"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const { mutateAsync: deleteMicroScenario } =
    useDeleteMicroScenarioByIdScenariosMicroScenarioIdDelete({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllMicroScenariosScenariosMicroGetQueryKey(),
          });
          showSnackbar(
            t(
              "micro:microViewAllScenariosPage.deleteScenarioModal.scenarioDeleted",
            ),
            "success",
          );
          closeModal();
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(error, "error");
        },
      },
    });

  const openHandleUpdateDetailsModal = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      if (scenario) {
        openModal(
          <UpdateDetailsModal
            open={true}
            scenario={scenario}
            updateMicroScenarioDetails={updateMicroScenarioDetails}
          ></UpdateDetailsModal>,
        );
      }
    },
    [openModal, scenario, updateMicroScenarioDetails],
  );

  const onHandleDeleteScenario = useCallback(() => {
    if (scenario && scenario.id) {
      openModal(
        <ConfirmationModal
          title={`${t("micro:microViewAllScenariosPage.deleteScenarioModal.title")} ${scenario?.name}${t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.questionMark",
          )}`}
          description={t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.description",
          )}
          actionTitle={t("common:actions.delete")}
          confirmAction={async () => {
            if (scenario?.id) {
              try {
                await deleteMicroScenario({ scenarioId: scenario?.id }).then(
                  closeModal,
                );
              } catch {
                console.warn("Error deleting scenario");
              }
            }
          }}
          cancelAction={closeModal}
        />,
      );
    }
  }, [closeModal, deleteMicroScenario, openModal, scenario, t]);

  const onHandleDuplicateScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      try {
        const scenarioId: number = parseInt((event.target as Element).id);
        duplicateScenario({ scenarioId });
      } catch {
        showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
      }
    },
    [duplicateScenario, showSnackbar, t],
  );

  function handleNavigateToScenario(scenario: ListMicroScenarioDto) {
    if (scenario && scenario.id) {
      navigate(`/product-level-analytics/scenario/${scenario?.id.toString()}`, {
        state: {
          id: scenario.id,
          title: scenario.name,
          description: scenario.description,
        },
      });
    }
  }

  return (
    <Box
      className="scenario-tile"
      key={scenario.id}
      sx={{ display: "flex", justifyContent: "space-between" }}
      mb={2}
    >
      <Grid width="100%">
        <Box display={"flex"} alignItems={"center"}>
          <Typography
            variant={"h2"}
            className="scenario-title"
            onClick={() => {
              handleNavigateToScenario(scenario);
            }}
            onKeyDown={(e) => {
              if (
                (e.ctrlKey && e.altKey && e.code === "Space") ||
                e.code === "Enter"
              ) {
                handleNavigateToScenario(scenario);
              }
            }}
            role="button"
            tabIndex={0}
            aria-label={`${t("common:actions.open")} ${scenario.name}`}
          >
            {scenario.name}
          </Typography>
          <Box
            tabIndex={0}
            marginLeft="auto"
            display="flex"
            alignItems="center"
          >
            {scenario.adjustment_count > 0 ? (
              <>
                <AdjustmentCountChip scenario={scenario} />
                <FiberManualRecordIcon
                  sx={{
                    m: 1,
                    fontSize: "5px",
                    color: "black",
                  }}
                />
              </>
            ) : null}
            <Typography>
              {scenario.product_count === 0 ? "No" : scenario.product_count}{" "}
              {t("common:products")}
            </Typography>
            <FiberManualRecordIcon
              sx={{
                m: 1,
                fontSize: "5px",
                color: "black",
              }}
            />
            <Tooltip title={t("common:labels.updatedDate")}>
              <Box display={"flex"} alignItems={"center"}>
                <Box mr={0.5}>
                  <CustomIcon
                    name={Icons.UPDATE}
                    width={24}
                    height={24}
                    fill={palette.text.primary}
                  />
                </Box>
                <Typography>
                  {new Date(scenario.updated_at || "").toLocaleString(
                    undefined,
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    },
                  )}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
          <ThreeDotMenu
            menuItems={[
              {
                label: t(
                  "micro:microViewAllScenariosPage.labels.updateDetailsBtn",
                ),
                onClick: (
                  event: Event | React.SyntheticEvent<Element, Event>,
                ) => openHandleUpdateDetailsModal(event),
                id: scenario.id,
              },
              {
                label: t("common:actions.duplicate"),
                onClick: (
                  event: Event | React.SyntheticEvent<Element, Event>,
                ) => onHandleDuplicateScenario(event),
                isAsync: true,
                id: scenario.id,
              },
              {
                label: t("common:actions.delete"),
                onClick: () => onHandleDeleteScenario(),
                id: scenario.id,
              },
            ]}
            menuTitle={`${t("common:a11y.menuFor")} ${scenario.name}`}
          ></ThreeDotMenu>
        </Box>
        {scenario.description && showDescription && (
          <Box mt={2} pr={2} overflow="hidden">
            <Typography tabIndex={0}>{scenario.description}</Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default MicroSingleScenario;
