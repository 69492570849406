import { SelectDropdownListItem } from "components/common/SelectDropdown/SelectDropdownRow/SelectDropdownRow";
import {
  AccordionsViewModel,
  CountryViewModel,
  FunctionalAreaItemViewModel,
  PermissionItem,
} from "orval/generated/models";
import { useCallback } from "react";
import { FormikContextType } from "formik";

export type AccessField = {
  title: string;
  formikLabel: string;
  helperText: string;
  listItems: SelectDropdownListItem<string>[] | string[];
  savedSelectedItems: string[];
  onSave: (selectedItems: string[], label?: string) => void;
  onOpen: () => void;
  disabledItems?: string[];
  type: "FUNCTIONAL_ACCESS" | "COUNTRY_ACCESS" | "ADJUSTMENT_ACCESS";
};

export const flattenAccess = (data: any[]): any[] => {
  return data.flatMap((item: any) => {
    if (item.subItem) {
      return flattenAccess(item.subItem);
    } else {
      return { id: item.id, name: item.name };
    }
  });
};

export const getFunctionalAccessItems = (
  data: FunctionalAreaItemViewModel[],
): SelectDropdownListItem<string>[] => {
  return data.map(
    (item: FunctionalAreaItemViewModel): SelectDropdownListItem<string> => {
      if (item.subItem) {
        return {
          title: item.name,
          value: getFunctionalAccessItems(item.subItem),
        };
      } else {
        return {
          value: item.name,
        };
      }
    },
  );
};

export const getAdjustmentAccessItems = (
  data: AccordionsViewModel[],
): SelectDropdownListItem<string>[] => {
  return data.map(
    (item: AccordionsViewModel): SelectDropdownListItem<string> => {
      if (item.subItem) {
        return {
          title: item.name,
          value: getAdjustmentAccessItems(item.subItem),
        };
      } else {
        return {
          value: item.name,
        };
      }
    },
  );
};

export const useAccessHandlers = (
  formik: FormikContextType<any>,
  flattenedFunctionalAccess: PermissionItem[],
  countryData: CountryViewModel[],
  flattenedAdjustmentAccess: PermissionItem[],
) => {
  const handleFunctionalAccessChange = useCallback(
    (selectedItems: string[], label: string) => {
      formik
        .setFieldValue(
          label,
          selectedItems.map(
            (item) =>
              flattenedFunctionalAccess.find(
                (access: PermissionItem) => access.name === item,
              )?.id,
          ),
        )
        .catch((error) => {
          console.error("Error setting field value:", error);
        });
    },
    [flattenedFunctionalAccess, formik],
  );

  const handleCountryAccessChange = useCallback(
    (selectedItems: string[], label: string) => {
      formik
        .setFieldValue(
          label,
          selectedItems.map(
            (item) =>
              countryData.find(
                (country: CountryViewModel) => country.display_name === item,
              )?.country,
          ),
        )
        .catch((error) => {
          console.error("Error setting field value:", error);
        });
    },
    [countryData, formik],
  );

  const handleAdjustmentAccessChange = useCallback(
    (selectedItems: string[], label: string) => {
      let ids: number[] = selectedItems.map(
        (item) =>
          flattenedAdjustmentAccess.find(
            (access: PermissionItem) => access.name === item,
          )?.id as number,
      );

      // Adds serving_size for all users by default, do not remove
      if (!ids.find((id) => id === 1)) ids.push(1);

      formik.setFieldValue(label, ids).catch((error) => {
        console.error("Error setting field value:", error);
      });
    },
    [flattenedAdjustmentAccess, formik],
  );

  return {
    handleFunctionalAccessChange,
    handleCountryAccessChange,
    handleAdjustmentAccessChange,
  };
};

export const getAccessFields = (
  formik: FormikContextType<any>,
  countryData: CountryViewModel[] | undefined,
  flattenedAdjustmentAccess: PermissionItem[],
  flattenedFunctionalAccess: PermissionItem[],
  adjustmentAccessItems: SelectDropdownListItem<any>[],
  functionalAccessItems: SelectDropdownListItem<any>[],
  handleCountryAccessChange: (selectedItems: string[], label: string) => void,
  handleAdjustmentAccessChange: (
    selectedItems: string[],
    label: string,
  ) => void,
  handleFunctionalAccessChange: (
    selectedItems: string[],
    label: string,
  ) => void,
  t: (key: string) => string,
): AccessField[] => {
  return [
    {
      title: "Functional Access",
      formikLabel: "functional_access",
      helperText: formik.touched.functional_access
        ? (formik.errors.functional_access as string)
        : "",
      listItems: functionalAccessItems,
      savedSelectedItems: formik.values.functional_access.map(
        (id: number) =>
          flattenedFunctionalAccess?.find(
            (access: FunctionalAreaItemViewModel) => access.id === id,
          )?.name || "",
      ),
      onSave: (selectedItem: string[], label?: string) => {
        if (label) {
          handleFunctionalAccessChange(selectedItem, label);
        }
      },
      onOpen: () => formik.setFieldTouched("functional_access", true),
      type: "FUNCTIONAL_ACCESS",
    },
    {
      title: "Country Access",
      formikLabel: "countries",
      helperText: formik.touched.countries
        ? (formik.errors.countries as string)
        : "",
      listItems:
        countryData?.map((country: CountryViewModel) => country.display_name) ??
        [],
      savedSelectedItems:
        formik.values.countries.map(
          (code: string) =>
            countryData?.find(
              (country: CountryViewModel) => country.country === code,
            )?.display_name || "",
        ) || [],
      onSave: (selectedItem: string[], label?: string) => {
        if (label) {
          handleCountryAccessChange(selectedItem, label as string);
        }
      },
      onOpen: () => formik.setFieldTouched("countries", true),
      type: "COUNTRY_ACCESS",
    },
    {
      title: t("userManagementPage.upsertUser.adjustmentAccessLabel"),
      formikLabel: "adjustment_access",
      helperText: formik.touched.adjustment_access
        ? (formik.errors.adjustment_access as string)
        : "",
      listItems: adjustmentAccessItems,
      savedSelectedItems: formik.values.adjustment_access.map(
        (id: number) =>
          flattenedAdjustmentAccess?.find(
            (access: AccordionsViewModel) => access.id === id,
          )?.name || "",
      ),
      onSave: (selectedItem: string[], label?: string) => {
        if (label) {
          handleAdjustmentAccessChange(selectedItem, label);
        }
      },
      onOpen: () => formik.setFieldTouched("adjustment_access", true),
      disabledItems: ["Serving Size"],
      type: "ADJUSTMENT_ACCESS",
    },
  ];
};
