import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export type CustomSelectProps = {
  list: any[];
  label: string;
  source: "micro" | "macro";
  sourceKey?: string;
  handlerFunction: (value: string) => void;
  isDisabled?: boolean;
  width?: string;
  defaultSelection?: boolean;
  initialValue?: string;
  height?: number;
};

export function CustomSelect({
  list,
  label,
  source,
  sourceKey,
  isDisabled,
  width,
  defaultSelection,
  initialValue,
  height,
  handlerFunction,
}: CustomSelectProps) {
  const [selectedItem, setSelectedItem] = useState<string>(initialValue || "");
  const { t } = useTranslation(source);

  useEffect(() => {
    if (defaultSelection && list.length > 0) {
      setSelectedItem(list[0]);
    }
  }, [list, defaultSelection]);

  const handleSelect = (e: SelectChangeEvent) => {
    setSelectedItem(e.target.value);
    handlerFunction(e.target.value);
  };

  const _renderList = (list: any[]) => {
    return list?.map((item, itemIndex) => (
      <MenuItem
        key={itemIndex}
        value={item}
        data-testid={`filters-custom-select-item-${itemIndex}`}
      >
        {sourceKey ? t(`${sourceKey}.${item}`) : item}
      </MenuItem>
    ));
  };

  return (
    <Box width={width ?? "100%"}>
      <FormControl size="small" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          value={selectedItem || ""}
          onChange={handleSelect}
          disabled={isDisabled}
          data-testid={`custom-select-${label}`}
          variant="outlined"
          sx={{ ...(height ? { height } : {}) }}
        >
          {_renderList(list)}
        </Select>
      </FormControl>
    </Box>
  );
}
