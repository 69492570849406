import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import { ReactNode } from "react";
import { CustomIcon, Icons } from "components/common";
import { useTheme } from "@mui/material";

export type AccordionComponentProps = {
  disabled?: boolean;
  children: ReactNode;
  title: string;
  testId?: string;
  expanded: boolean;
  onChange: () => void;
};

export function AccordionComponent({
  disabled,
  children,
  title,
  testId,
  expanded,
  onChange,
}: AccordionComponentProps) {
  const { palette } = useTheme();
  return (
    <Accordion disabled={disabled} expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={
          <CustomIcon
            name={Icons.CHEVRON_DOWN}
            width={24}
            height={24}
            fill={palette.text.primary}
          />
        }
        data-testid={testId}
      >
        <Typography variant={"h3"}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box pt={1}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
}
