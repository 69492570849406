import { MicroToolRoutesConfig } from "../../../features/micro-tool/navigation/config";
import { MacroToolRoutesConfig } from "../../../features/macro-tool/navigation/config";
import { ToolConfigurationRoutesConfig } from "../../../features/tool-configuration/navigation/config";
import { IconName, Icons } from "../../common";
import { DataManagementRoutesConfig } from "../../../features/data-management/navigation/config";
import { Permission } from "../../../core/navigation/permission";

export type NavigationMenuOptionType = {
  title: string;
  icon: IconName;
  redirectURL: string;
  permission?: Permission;
};

export const NavigationMenuOptions: NavigationMenuOptionType[] = [
  {
    title: "navigationMenu.options.microTool",
    icon: Icons.TOGGLE,
    redirectURL: MicroToolRoutesConfig.productLevelAnalytics,
    permission: Permission.ProductLevelAnalytics,
  },
  {
    title: "navigationMenu.options.macroTool",
    icon: Icons.GROW_RESPONSE,
    redirectURL: MacroToolRoutesConfig.countryLevelAnalytics,
    permission: Permission.CountryLevelAnalytics,
  },
  {
    title: "navigationMenu.options.dataManagement",
    icon: Icons.DATABASE,
    redirectURL: DataManagementRoutesConfig.dataManagementPage,
    permission: Permission.DataManagement,
  },
  {
    title: "navigationMenu.options.toolConfiguration",
    icon: Icons.SETTINGS,
    redirectURL: ToolConfigurationRoutesConfig.toolConfigurationPage,
    permission: Permission.ToolConfiguration,
  },
];
