import { Box, Typography } from "@mui/material";

export type MicroColumnWithUnitsProps = {
  renderedCellValue: any;
};

export function MicroColumnWithUnits({
  renderedCellValue,
}: MicroColumnWithUnitsProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Typography
        // variant={renderedCellValue === "Total" ? "body2" : "body1"}
        variant={"body2"}
      >
        {renderedCellValue}
      </Typography>
    </Box>
  );
}
