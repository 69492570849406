import { AuthSessionStorageKeys } from "features/auth/navigation/config";
import { AuthenticationProviders } from "constants/AuthenticationProviders";
import {
  clearAuthDataFromSessionStorage,
  isTokenExpired,
} from "features/auth/utils/verifyPwCAccess";
import { AppConstant } from "constants/AppConstant";

export function useIsUserAuthenticated() {
  // HACK: work around to control behaviour as cy.stub() does not work as expected
  if (window.Cypress && Cypress.env("isUserAuthenticated") !== undefined) {
    return !!Cypress.env("isUserAuthenticated");
  }

  const appAuthProvider: any = AppConstant.authentication;

  switch (appAuthProvider?.provider) {
    case AuthenticationProviders.IDAM:
      const accessToken = sessionStorage.getItem(
        AuthSessionStorageKeys.accessToken,
      );

      if (accessToken && isTokenExpired(accessToken)) {
        clearAuthDataFromSessionStorage();
        return false;
      }

      return !!accessToken;

    default:
      return false;
  }
}
