import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { getObjectKeys } from "utils";
import { TransformMekkoData } from "../index";
import { MekkoChartData } from "../index";
import { MekkoTableUnderlyingData } from "../../MekkoTable";
import { Grid2, Typography } from "@mui/material";
import { TableResultsFilterDataType } from "../../ScenarioResults/types";
import { MekkoChartSection } from "../MekkoChartSection";
import {
  MekkoResult,
  MekkoBaseGlideScenario,
  MacroResults,
  Years,
} from "orval/generated/models";
import { CustomSelect } from "components/common/CustomSelect";

type MekkoSectionProps = {
  scenarioResults: MacroResults | null;
  scenarioYears: Years;
};

export function MekkoAccordion({
  scenarioResults,
  scenarioYears,
}: MekkoSectionProps) {
  const { t } = useTranslation("macro");
  const [mekkoData, setMekkoData] = useState<MekkoResult | undefined>();
  const [mekkoYearList, setMekkoYearList] = useState<string[]>([]);
  const [selectedMekkoYear, setSelectedMekkoYear] = useState<string>("");

  const [chartData, setChartData] = useState<MekkoChartData>({
    dimensions: [],
    data: [],
  });
  const [years, setYears] = useState<{ [Key: string]: string }>({});
  const [subtitle, setSubtitle] = useState<string>("");

  useEffect(() => {
    if (scenarioResults) {
      setMekkoData(scenarioResults.mekko);
      const years: { [Key: string]: string } = {
        base: `${scenarioYears?.base_year} base year`,
        glide: `${scenarioYears?.target_year} glidepath`,
        scenario: `${scenarioYears?.target_year} scenario`,
      };
      setMekkoYearList(Object.values(years));
      setSelectedMekkoYear(getObjectKeys(years)[0]);
      setYears(years);
    }
  }, [scenarioResults, scenarioYears?.base_year, scenarioYears?.target_year]);

  useEffect(() => {
    if (mekkoData) {
      const chartData: MekkoChartData = TransformMekkoData(
        mekkoData,
        selectedMekkoYear as keyof MekkoBaseGlideScenario,
        TableResultsFilterDataType.ABSOLUTE,
      );
      setChartData(chartData);
    }

    setSubtitle(
      t(`dataVisualisationTabSection.timeEvolution.units.absoluteDisplayUnits`),
    );
  }, [selectedMekkoYear, mekkoData, t]);

  function handleSetMekkoYear(year: string) {
    if (!years) {
      return;
    }
    const selectedYear = Object.entries(years).find(
      ([, value]) => value === year,
    );
    if (selectedYear) {
      setSelectedMekkoYear(selectedYear[0]);
    }
  }

  return (
    <Box>
      <Grid2 container justifyContent="space-between" width="100%" py={2}>
        <Grid2>
          <Box display="flex" gap={2} flexDirection="column">
            <Box
              sx={{
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
              }}
              mb={2}
              tabIndex={0}
              aria-label={`${t("common:a11y.chartShowing")} ${t(
                "dataVisualisationTabSection.emissionsHeatMap.titles.heatMapChartTitle",
              )}`}
            >
              <Typography variant={"h4"}>
                {t(
                  "dataVisualisationTabSection.emissionsHeatMap.titles.heatMapChartTitle",
                )}
              </Typography>
              <Typography variant={"h5"}>{subtitle}</Typography>
            </Box>
          </Box>
        </Grid2>
        <Grid2>
          <Box width={"200px"} ml={2}>
            <CustomSelect
              list={mekkoYearList}
              label={t("macro:resultsSection.filterBy")}
              handlerFunction={(value) => handleSetMekkoYear(value)}
              source={"macro"}
              defaultSelection
            />
          </Box>
        </Grid2>
      </Grid2>

      <Box my={2} display="flex" flexDirection="column">
        <MekkoChartSection chartData={chartData} />

        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
          my={2}
        >
          <Typography variant={"h4"} tabIndex={0}>
            {t(
              "dataVisualisationTabSection.emissionsHeatMap.titles.emissionTableTitle",
            )}
          </Typography>
        </Box>
        <MekkoTableUnderlyingData
          mekkoData={mekkoData}
          scenarioResults={scenarioResults}
          selectedYear={selectedMekkoYear}
        />
      </Box>
    </Box>
  );
}
