import { Box, Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentStep,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import {
  CdeFleetBreakdownEntityDto,
  IngredientEfEntityDto,
  IngredientWeightEntity,
  LogisticsDistanceEntityWithCountryDto,
  LogisticsVehicleMixEfEntityDto,
  ManufacturingEurEntityWithCountryDto,
  PackagingEfEntityDto,
  PackagingNetLossRateEntityDto,
  PackagingRecoveryRateEntityWithCountryDto,
  PackagingRecycledContentEntityDto,
} from "orval/generated/models";

export type MacroAdjustmentsWizardFooterProps = {
  handleLeftButtonClick: () => void;
  steps: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => MacroAdjustmentStep[];
  formikProps: FormikProps<MacroAdjustmentWizardFormikValues>;
  activeStep: number;
  handleRightButtonClick: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => Promise<void>;
  adjustmentCombination: MacroAdjustmentCombinations | null;
  validationErrors: Record<number, string | undefined>;
};

const MacroAdjustmentsWizardFooter = ({
  handleLeftButtonClick,
  steps,
  formikProps,
  activeStep,
  handleRightButtonClick,
  adjustmentCombination,
  validationErrors,
}: MacroAdjustmentsWizardFooterProps) => {
  const [rightButtonDisabled, setRightButtonDisabled] = useState(true);
  const { values } = formikProps;
  const [isPending, setIsPending] = useState(false);

  const rightButtonStepThreeDisabledValue = useMemo<boolean>(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return (
          (values.payload.entities as PackagingEfEntityDto[]).filter(
            (entity) =>
              entity.supply_share && entity.virgin_ef && entity.recycled_ef,
          ).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
        return (
          (values.payload.entities as IngredientEfEntityDto[]).filter(
            (entity) => entity.supply_share && entity.ef,
          ).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        return (
          (values.payload.entities as LogisticsVehicleMixEfEntityDto[]).filter(
            (entity) => entity.usage_share && entity.ef,
          ).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        return (
          (values.payload.entities as CdeFleetBreakdownEntityDto[]).filter(
            (entity) => entity.avg_energy_consumption && entity.number_of_units,
          ).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return (
          (values.payload.entities as PackagingNetLossRateEntityDto[]).filter(
            (entity) => entity.net_loss_rate,
          ).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return (
          (
            values.payload.entities as PackagingRecycledContentEntityDto[]
          ).filter((entity) => entity.recycled_content).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return (
          (
            values.payload
              .entities as PackagingRecoveryRateEntityWithCountryDto[]
          ).filter((entity) => entity.recovery_rate).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return (
          (
            values.payload.entities as ManufacturingEurEntityWithCountryDto[]
          ).filter((entity) => entity.eur).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return (
          (
            values.payload.entities as LogisticsDistanceEntityWithCountryDto[]
          ).filter((entity) => entity.distance).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );

      default:
        return (
          (values.payload.entities as IngredientWeightEntity[]).filter(
            (entity) => entity.weight,
          ).length < 1 ||
          Object.values(validationErrors).some((error) => error !== undefined)
        );
    }
  }, [adjustmentCombination, validationErrors, values.payload.entities]);

  const updateRightButtonDisabled = useCallback(() => {
    switch (activeStep) {
      // STEP ONE PAGE:
      case 0:
        setRightButtonDisabled(
          adjustmentCombination === null ||
            values.selectedPillar === null ||
            values.selectedInputArea === null ||
            values.selectedAdjustmentType === null ||
            values.payload.name === "" ||
            values.payload.name === null ||
            values.payload.name.length < 3,
        );
        break;
      // STEP TWO PAGE - ADDITIONAL SWITCH FOR STEP TWO:
      case 1:
        switch (adjustmentCombination) {
          case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
          case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
          case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
          case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
          case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
          case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
            setRightButtonDisabled(values.selectedEntities.length === 0);
            break;
          case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
            setRightButtonDisabled(values.selectedCountries.length === 0);
            break;
          case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
            setRightButtonDisabled(
              values.selectedEntities.length === 0 ||
                values.payload.filters.brands?.length === 0,
            );
            break;
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_RecycledContent:
          case MacroAdjustmentCombinations.Packaging_NetLossRate:
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            setRightButtonDisabled(
              values.selectedProducts.length === 0 ||
                values.selectedEntities.length === 0,
            );
            break;
          default:
            setRightButtonDisabled(false);
        }
        break;
      // STEP THREE PAGE:
      case 2:
        setRightButtonDisabled(rightButtonStepThreeDisabledValue);
        break;
      default:
        setRightButtonDisabled(false);
    }
  }, [
    activeStep,
    adjustmentCombination,
    rightButtonStepThreeDisabledValue,
    values.payload.filters.brands?.length,
    values.payload.name,
    values.selectedAdjustmentType,
    values.selectedCountries.length,
    values.selectedEntities.length,
    values.selectedInputArea,
    values.selectedPillar,
    values.selectedProducts.length,
  ]);

  useEffect(() => {
    updateRightButtonDisabled();
  }, [activeStep, formikProps, updateRightButtonDisabled]);

  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={3}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleLeftButtonClick()}
        disabled={isPending}
      >
        {steps(formikProps)[activeStep].leftBtnText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          activeStep === 2 ? setIsPending(true) : setIsPending(false);

          try {
            await handleRightButtonClick(formikProps);
          } catch (e) {
            console.log(e);
          } finally {
            setIsPending(false);
          }
        }}
        disabled={rightButtonDisabled || isPending}
      >
        {steps(formikProps)[activeStep].rightBtnText}
        {isPending ? (
          <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
        ) : null}
      </Button>
    </Box>
  );
};

export default MacroAdjustmentsWizardFooter;
