import Box from "@mui/material/Box";
import { Autocomplete, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectDropdownListItem } from "components/common/SelectDropdown/SelectDropdownRow/SelectDropdownRow";
import { useFormikContext } from "formik";
import {
  MacroAdjustmentPillars,
  MacroAdjustmentInputAreas,
  MacroAdjustmentTypes,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import {
  getPillars,
  filterInputAreas,
  setupAdjustmentTypeDropdown,
} from "../HelperFunctions";
import { ViewMacroScenarioDto } from "orval/generated/models";
import { UserDataTypes } from "orval/generated/models";

export type MacroAdjustmentStepOneProps = {
  updateAdjustmentCombination: Function;
  isEditWizard: boolean;
  setMacroFiltersParams: Function;
  scenarioDetails: ViewMacroScenarioDto | undefined;
};

export const MacroAdjustmentStepOne = ({
  updateAdjustmentCombination,
  isEditWizard,
  setMacroFiltersParams,
  scenarioDetails,
}: MacroAdjustmentStepOneProps) => {
  const { t } = useTranslation("macro");
  const { setFieldValue, values, handleChange, handleBlur, errors } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const [inputAreaDropdownItems, setInputAreaDropdownItems] = useState<
    SelectDropdownListItem<any>[]
  >([]);
  const [adjustmentDropdownItems, setAdjustmentDropdownItems] = useState<
    SelectDropdownListItem<any>[]
  >([]);

  useEffect(() => {
    if (values.selectedPillar) {
      filterInputAreas(
        values.selectedPillar as MacroAdjustmentPillars,
        setInputAreaDropdownItems,
      );
    }
  }, [values.selectedPillar]);

  useEffect(() => {
    if (values.selectedInputArea) {
      setupAdjustmentTypeDropdown(
        values.selectedInputArea as MacroAdjustmentInputAreas,
        setAdjustmentDropdownItems,
      );
    }
  }, [values.selectedInputArea]);

  const pillars = useMemo(() => getPillars(), []);

  const handlePillarSelection = (newValue: MacroAdjustmentPillars | null) => {
    setInputAreaDropdownItems([]);
    setAdjustmentDropdownItems([]);
    if (!newValue) {
      setFieldValue("selectedPillar", null).catch((e) => console.warn(e));
      setFieldValue("selectedInputArea", null).catch((e) => console.warn(e));
      setFieldValue("selectedAdjustmentType", null).catch((e) =>
        console.warn(e),
      );
    }
    if (newValue) {
      filterInputAreas(newValue, setInputAreaDropdownItems);
      setFieldValue("selectedPillar", newValue).catch((e) => console.warn(e));
      setFieldValue("selectedInputArea", null).catch((e) => console.warn(e));
      setFieldValue("selectedAdjustmentType", null).catch((e) =>
        console.warn(e),
      );
      setFieldValue("selectedEntities", []).catch((e) => console.warn(e));
    }
  };

  const handleInputAreaSelection = (
    newValue: MacroAdjustmentInputAreas | null,
  ) => {
    if (!newValue) {
      setFieldValue("selectedInputArea", null).catch((e) => console.warn(e));
      setFieldValue("selectedAdjustmentType", null).catch((e) =>
        console.warn(e),
      );
    }
    if (newValue) {
      setupAdjustmentTypeDropdown(newValue, setAdjustmentDropdownItems);
      setFieldValue("selectedInputArea", newValue).catch((e) =>
        console.warn(e),
      );
      setFieldValue("selectedAdjustmentType", null).catch((e) =>
        console.warn(e),
      );

      if (
        ![
          MacroAdjustmentInputAreas.IngredientWeights,
          MacroAdjustmentInputAreas.PackagingWeights,
        ].includes(newValue)
      ) {
        setFieldValue(
          "selectedAdjustmentType",
          MacroAdjustmentTypes.NotApplicable,
        ).catch((e) => console.warn(e));
        setFieldValue("selectedAction", UserDataTypes.new_value).catch((e) =>
          console.warn(e),
        );
        updateAdjustmentCombination(
          values.selectedPillar as MacroAdjustmentPillars,
          newValue as MacroAdjustmentInputAreas,
          MacroAdjustmentTypes.NotApplicable,
        );
      }
    }
  };

  const handleAdjustmentTypeSelection = (newValue: string | null) => {
    setFieldValue("selectedAdjustmentType", newValue).catch((e) =>
      console.warn(e),
    );

    if (newValue === MacroAdjustmentTypes.SpecificSKUAdjustment) {
      setFieldValue("selectedProducts", []).catch((e) => console.warn(e));
    }

    if (values.selectedPillar === MacroAdjustmentPillars.Ingredients) {
      setFieldValue("payload.filters.brands", []).catch((e) => console.warn(e));
      setFieldValue("payload.filters.countries", []).catch((e) =>
        console.warn(e),
      );
      setFieldValue("selectedCountries", []).catch((e) => console.warn(e));

      setFieldValue("payload.filters.categories", []).catch((e) =>
        console.warn(e),
      );
      setFieldValue("payload.filters.brand_groups", []).catch((e) =>
        console.warn(e),
      );

      setMacroFiltersParams({
        countries:
          scenarioDetails?.inputs?.countries?.map(
            (country) => country.country,
          ) || [],
        target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
      });
    }

    updateAdjustmentCombination(
      values.selectedPillar as MacroAdjustmentPillars,
      values.selectedInputArea as MacroAdjustmentInputAreas,
      newValue as MacroAdjustmentTypes,
    );
  };

  return (
    <Box>
      <Box mb={1}>
        <Typography className="text-ellipsis" variant={"h2"}>
          {isEditWizard
            ? t("adjustmentsPage.wizard.stepOne.updateTitle")
            : t("adjustmentsPage.wizard.stepOne.title")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant={"body1"}>
          {isEditWizard
            ? t("adjustmentsPage.wizard.stepOne.updateDescription")
            : t("adjustmentsPage.wizard.stepOne.description")}
        </Typography>
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid size={4}>
          <TextField
            variant={"outlined"}
            size={"small"}
            name="payload.name"
            label={t(
              "macro:adjustmentsPage.wizard.stepOne.adjustmentNameInputTitle",
            )}
            placeholder={t(
              "macro:adjustmentsPage.wizard.stepOne.adjustmentNameInputPlaceholder",
            )}
            value={values.payload.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.payload?.name)}
            helperText={Boolean(errors.payload?.name) && errors.payload?.name}
            required
            data-testid={"adjustment-name-input"}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box mb={2}>
        <TextField
          fullWidth
          name="payload.description"
          label={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameDescriptionInputTitle",
          )}
          placeholder={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameDescriptionInputPlaceholder",
          )}
          multiline
          rows={3}
          value={values.payload.description}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid={"adjustment-description-input"}
          disabled={values.payload.name === ""}
        />
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepOne.descriptionTwo")}
        </Typography>
      </Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid size={4}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "macro:adjustmentsPage.wizard.stepOne.pillarSelection",
                )}
                name={"selectedPillar"}
              />
            )}
            value={values.selectedPillar}
            options={pillars.map((pillar) => pillar.title)}
            size={"small"}
            onChange={(
              _event: SyntheticEvent,
              newValue: string | null | undefined,
            ) => {
              if (newValue === undefined) {
                return;
              }
              handlePillarSelection(newValue as MacroAdjustmentPillars);
            }}
            clearOnBlur
            handleHomeEndKeys
            data-testid={"pillar-selection"}
            fullWidth
            getOptionDisabled={(option) =>
              option === MacroAdjustmentPillars.SalesMix
            }
            disabled={isEditWizard || values.payload.name === ""}
          />
        </Grid>
        <Grid size={4}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "macro:adjustmentsPage.wizard.stepOne.inputAreaSelection",
                )}
                name={"selectedInputArea"}
              />
            )}
            value={values.selectedInputArea}
            options={inputAreaDropdownItems.map((item) => item.title)}
            size={"small"}
            onChange={(_event, value) => {
              if (value === undefined) {
                return;
              }
              handleInputAreaSelection(value as MacroAdjustmentInputAreas);
            }}
            clearOnBlur
            handleHomeEndKeys
            data-testid={"input-area-selection"}
            disabled={
              !values.selectedPillar ||
              isEditWizard ||
              values.payload.name === ""
            }
            getOptionDisabled={(option) =>
              [
                MacroAdjustmentInputAreas.DLZShare,
                MacroAdjustmentInputAreas.PackMix,
                MacroAdjustmentInputAreas.SalesGrowth,
                MacroAdjustmentInputAreas.CategoryMix,
              ].includes(option as MacroAdjustmentInputAreas)
            }
          />
        </Grid>
        <Grid size={4}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "macro:adjustmentsPage.wizard.stepOne.adjustmentTypeSelection",
                )}
                name={"selectedAdjustmentType"}
              />
            )}
            value={values.selectedAdjustmentType}
            options={adjustmentDropdownItems.map((item) => item.title)}
            size={"small"}
            onChange={(_event, value) => {
              if (value === undefined) {
                return;
              }
              handleAdjustmentTypeSelection(value);
            }}
            clearOnBlur
            handleHomeEndKeys
            data-testid={"adjustment-type-selection"}
            disabled={
              ![
                MacroAdjustmentInputAreas.IngredientWeights,
                MacroAdjustmentInputAreas.PackagingWeights,
              ].includes(
                values.selectedInputArea as MacroAdjustmentInputAreas,
              ) ||
              isEditWizard ||
              values.payload.name === ""
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
