import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import { SearchBar, useGlobalLoader } from "components/common";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import { useGetSkusSkusGet } from "orval/generated/endpoint";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { debounce } from "lodash";
import { CountryViewModel, SKUMinimalViewModel } from "orval/generated/models";
import { FixedSizeList } from "react-window";

export type FiltersListsType = {
  countries: string[];
  categories: string[];
  brandGroups: string[];
  brands: string[];
  packSizes: string[];
  packTypes: string[];
  multipackSizes: string[];
};

export type FilterType =
  | "countries"
  | "categories"
  | "brands"
  | "brandGroups"
  | "packTypes"
  | "packSizes"
  | "multipackSizes";

const initialSelectedFilters = {
  countries: [],
  categories: [],
  brandGroups: [],
  brands: [],
  packSizes: [],
  packTypes: [],
  multipackSizes: [],
};

export type ProductSelectionViewProps = {
  countriesList: CountryViewModel[];
  targetYear: number;
  showBaseProducts?: boolean;
  updateSelectedProducts: (products: number[]) => void;
  initialSelectedProducts: number[];
  selectionType: "multiple" | "single";
  clearAllProductsHandler?: () => void;
};

const ProductSelectionView = ({
  countriesList,
  targetYear,
  showBaseProducts = false,
  updateSelectedProducts,
  initialSelectedProducts,
  selectionType,
  clearAllProductsHandler,
}: ProductSelectionViewProps) => {
  const { t } = useTranslation("macro");
  const { spacing, palette } = useTheme();

  const {
    data: skusData,
    isPending: skusIsPending,
    isError: errorFetchingSkus,
  } = useGetSkusSkusGet();

  const { showGlobalLoader } = useGlobalLoader();
  const showSnackbar = useSnackbar();

  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedProducts, setSelectedProducts] = useState<number[]>(
    initialSelectedProducts,
  );

  const [filtersLists, setFiltersLists] = useState<FiltersListsType>({
    countries: [],
    categories: [],
    brandGroups: [],
    brands: [],
    packSizes: [],
    packTypes: [],
    multipackSizes: [],
  });

  const [selectedFilters, setSelectedFilters] = useState<FiltersListsType>(
    initialSelectedFilters,
  );

  const [filteredSkus, setFilteredSkus] = useState<SKUMinimalViewModel[]>([]);

  const handleSaveFilter = useCallback(
    (selectedItems: string[], filterType: FilterType) => {
      setSelectedFilters((prev) => ({ ...prev, [filterType]: selectedItems }));
    },
    [],
  );

  const onSelectChange = (
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    _e.stopPropagation();
    if (checked) {
      if (selectionType) {
        setSelectedProducts((prev) => [...prev, Number(_e.currentTarget.name)]);
      } else {
        setSelectedProducts([Number(_e.currentTarget.name)]);
      }
    } else {
      setSelectedProducts((prev) =>
        [...prev].filter((id) => id !== Number(_e.currentTarget.name)),
      );
    }
  };

  const handleAddAllClick = useCallback(() => {
    setSelectedProducts((prev) => {
      const selectedProductsHashMap: { [id: string]: number } = {};
      prev.forEach((selectedProductId) => {
        if (!selectedProductsHashMap[`${selectedProductId}`])
          selectedProductsHashMap[`${selectedProductId}`] = selectedProductId;
      });

      const newSelectedProducts: number[] = [...prev];

      filteredSkus
        .map((sku) => sku.id)
        .forEach((skuId) => {
          if (!selectedProductsHashMap[`${skuId}`])
            newSelectedProducts.push(skuId);
        });

      return newSelectedProducts;
    });
  }, [filteredSkus]);

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchValue(value);
      }, 200),
    [],
  );

  const selectedSkus = useMemo(() => {
    const skusHashMap: { [key: string]: SKUMinimalViewModel } = {};

    if (skusData) {
      skusData.forEach((sku) => {
        if (!skusHashMap[sku.id.toString()])
          skusHashMap[sku.id.toString()] = sku;
      });
    }

    if (Object.keys(skusHashMap).length) {
      return selectedProducts.map((id) => skusHashMap[id.toString()]);
    }

    return [];
  }, [selectedProducts, skusData]);

  const scenarioScopedSkus = useMemo<SKUMinimalViewModel[]>(() => {
    if (!skusData) return [];

    const countryCodes = countriesList.map(({ country }) => country);
    const scenarioSkus = skusData.filter(({ country }) =>
      countryCodes.includes(country),
    );

    return showBaseProducts
      ? scenarioSkus
      : scenarioSkus.filter(({ year }) => year === targetYear);
  }, [countriesList, showBaseProducts, skusData, targetYear]);

  useEffect(() => {
    if (scenarioScopedSkus) {
      const uniqueElements: FiltersListsType = {
        countries: countriesList.map((country) => country.display_name),
        categories: [],
        brandGroups: [],
        brands: [],
        packSizes: [],
        packTypes: [],
        multipackSizes: [],
      };

      scenarioScopedSkus.forEach((product) => {
        if (!uniqueElements.categories.includes(product.category)) {
          uniqueElements.categories.push(product.category);
        }
        if (!uniqueElements.brandGroups.includes(product.brand_group)) {
          uniqueElements.brandGroups.push(product.brand_group);
        }
        if (!uniqueElements.brands.includes(product.brand)) {
          uniqueElements.brands.push(product.brand);
        }
        if (!uniqueElements.packSizes.includes(product.pack_size.toString())) {
          uniqueElements.packSizes.push(product.pack_size.toString());
        }
        if (!uniqueElements.packTypes.includes(product.pack_type)) {
          uniqueElements.packTypes.push(product.pack_type);
        }
        if (
          !uniqueElements.multipackSizes.includes(
            product.multipack_size.toString(),
          )
        ) {
          uniqueElements.multipackSizes.push(product.multipack_size.toString());
        }
      });

      setFiltersLists(uniqueElements);
    }
  }, [countriesList, scenarioScopedSkus]);

  useEffect(() => {
    if (scenarioScopedSkus) {
      if (scenarioScopedSkus.length) {
        let filteredItems: SKUMinimalViewModel[] = [];
        // Search
        const words = searchValue
          .replace(",", " ")
          .trim()
          .split(" ")
          .map((word) => word.toLowerCase()); // Convert to lowercase for case-insensitive comparison

        if (words.length) {
          scenarioScopedSkus.forEach((sku) => {
            const productName = sku.product_name.toLocaleLowerCase();
            if (words.every((word) => productName.includes(word))) {
              if (!filteredItems.find((_sku) => _sku.id === sku.id)) {
                filteredItems.push(sku);
              }
            }
          });
        } else {
          filteredItems = scenarioScopedSkus;
        }

        //filters
        if (selectedFilters) {
          Object.keys(selectedFilters).forEach((filterType) => {
            const selectedItems: string[] =
              selectedFilters[filterType as keyof FiltersListsType];

            // Filter based on the filter type
            if (selectedItems && selectedItems.length > 0) {
              filteredItems = filteredItems.filter((sku) => {
                switch (filterType) {
                  case "countries":
                    return selectedItems.includes(sku.country_display_name);
                  case "categories":
                    return selectedItems.includes(sku.category);
                  case "brandGroups":
                    return selectedItems.includes(sku.brand_group);
                  case "brands":
                    return selectedItems.includes(sku.brand);
                  case "packSizes":
                    return selectedItems.includes(sku.pack_size.toString());
                  case "packTypes":
                    return selectedItems.includes(sku.pack_type);
                  case "multipackSizes":
                    return selectedItems.includes(
                      sku.multipack_size.toString(),
                    );
                  default:
                    return true;
                }
              });
            }
          });
        }
        setFilteredSkus(filteredItems);
      } else {
        setFilteredSkus([]);
      }
    }
  }, [countriesList, scenarioScopedSkus, searchValue, selectedFilters]);

  useEffect(() => {
    skusIsPending ? showGlobalLoader(true, true) : showGlobalLoader(false);
  }, [showGlobalLoader, skusIsPending]);

  useEffect(() => {
    errorFetchingSkus &&
      showSnackbar(
        t("adjustmentsPage.wizard.stepTwo.loadSkusErrorMessage"),
        "error",
      );
  }, [showSnackbar, errorFetchingSkus, t]);

  useEffect(() => {
    updateSelectedProducts(selectedProducts);
  }, [selectedProducts, updateSelectedProducts]);

  const boxRemHeight = 16;
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize,
  );
  const verticalSpacing = parseFloat(spacing(7));
  const fixedListPixelHeight = boxRemHeight * fontSize - verticalSpacing;

  return (
    <Grid
      container
      spacing={2}
      sx={{ borderBottom: `1px solid ${palette.textColor.light}` }}
    >
      <Grid size={{ xs: 12, md: 6 }} pb={2}>
        <Box sx={{ borderBottom: `1px solid ${palette.textColor.light}` }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={2}
          >
            <Typography variant={"h3"}>
              {t("adjustmentsPage.wizard.stepTwo.searchProducts")}
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setSelectedFilters(initialSelectedFilters);
              }}
              disabled={false}
              data-testid="clear-all-filters-button"
            >
              {t("adjustmentsPage.wizard.stepTwo.clearAllFilters")}
            </Button>
          </Box>
          <Box mb={1}>
            <SearchBar
              placeHolder={t("adjustmentsPage.wizard.stepTwo.searchProducts")}
              handleSearch={debouncedHandleSearch}
              disabled={!skusData?.length}
            />
          </Box>
          <Box mb={2}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.countries}
                  onSave={(items) => {
                    handleSaveFilter(items, "countries");
                  }}
                  savedSelectedItems={selectedFilters.countries}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.countries",
                  )}
                  compact
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.categories}
                  onSave={(items) => {
                    handleSaveFilter(items, "categories");
                  }}
                  savedSelectedItems={selectedFilters.categories}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.categories",
                  )}
                  compact
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.brandGroups}
                  onSave={(items) => {
                    handleSaveFilter(items, "brandGroups");
                  }}
                  savedSelectedItems={selectedFilters.brandGroups}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.brandGroups",
                  )}
                  compact
                  virtualiseList={true}
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.brands}
                  onSave={(items) => {
                    handleSaveFilter(items, "brands");
                  }}
                  savedSelectedItems={selectedFilters.brands}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.brands",
                  )}
                  compact
                  virtualiseList={true}
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.packTypes}
                  onSave={(items) => {
                    handleSaveFilter(items, "packTypes");
                  }}
                  savedSelectedItems={selectedFilters.packTypes}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.packTypes",
                  )}
                  compact
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.packSizes}
                  onSave={(items) => {
                    handleSaveFilter(items, "packSizes");
                  }}
                  savedSelectedItems={selectedFilters.packSizes}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.packSizes",
                  )}
                  compact
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
              <Grid size={6}>
                <SelectDropdown
                  listItems={filtersLists.multipackSizes}
                  onSave={(items) => {
                    handleSaveFilter(items, "multipackSizes");
                  }}
                  savedSelectedItems={selectedFilters.multipackSizes}
                  title={t(
                    "adjustmentsPage.wizard.stepTwo.dropdownTitles.multipacks",
                  )}
                  compact
                  requireSave={true}
                ></SelectDropdown>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          mt={2}
          sx={{
            flexWrap: "nowrap",
            overflowY: "hidden",
            overflowX: "hidden",
            maxHeight: `${boxRemHeight}rem`,
            height: "20em",
          }}
          data-testid="product-list-picker"
        >
          {filteredSkus.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              my={2}
            >
              <Typography variant="body2">
                {t("common:labels.noResults")}
              </Typography>
            </Box>
          ) : (
            <>
              <FormGroup>
                <FixedSizeList
                  height={fixedListPixelHeight}
                  itemCount={filteredSkus.length}
                  itemSize={50}
                  width="100%"
                >
                  {({ index, style }) => (
                    <FormControlLabel
                      labelPlacement="start"
                      key={`${filteredSkus[index].product_name}-${index}`}
                      control={
                        <Checkbox
                          name={filteredSkus[index].id.toString()}
                          checked={selectedProducts.includes(
                            filteredSkus[index].id,
                          )}
                          checkedIcon={<DoneIcon />}
                          onChange={onSelectChange}
                          icon={<AddIcon />}
                          data-testid={`checkbox-index-${index}`}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          flexDirection="column"
                          mb={0.5}
                          mt={0.5}
                        >
                          <Typography variant={"body1"}>
                            {filteredSkus[index].product_name}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        justifyContent: "space-between",
                        marginLeft: 0,
                        pl: 1,
                        backgroundColor: selectedProducts.includes(
                          filteredSkus[index].id,
                        )
                          ? palette.activeColor.main
                          : "",
                      }}
                      style={style}
                    />
                  )}
                </FixedSizeList>
                {selectionType !== "single" && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setSelectedProducts([]);
                        if (clearAllProductsHandler) clearAllProductsHandler();
                      }}
                      disabled={false}
                      data-testid="clear-all-products-button"
                    >
                      {t("adjustmentsPage.wizard.stepTwo.clearAllProducts")}
                    </Button>

                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleAddAllClick}
                      disabled={false}
                      data-testid="add-all-products-button"
                    >
                      {t("adjustmentsPage.wizard.stepTwo.addAll")}
                    </Button>
                  </Box>
                )}
              </FormGroup>
            </>
          )}
        </Box>
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{ borderLeft: `1px solid ${palette.textColor.light}`, px: 2 }}
      >
        <Box>
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant={"h3"}>
              {t("adjustmentsPage.wizard.stepTwo.selectedProducts")}
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => setSelectedProducts([])}
              sx={{ marginLeft: 1 }}
              disabled={!selectedProducts.length}
              data-testid="clear-selected-products"
            >
              {t("adjustmentsPage.wizard.stepTwo.clearSelectedProducts")}
            </Button>
          </Box>
          <Box
            sx={{
              height: "32em",
              overflow: "scroll",
              p: 2,
              border: `1px dashed ${palette.textColor.light}`,
            }}
          >
            {selectedSkus.length > 0 ? (
              selectedSkus.map((product) => (
                <Box
                  key={product.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    border: `solid 0.5px #6A6A6A`,
                    borderRadius: 1,
                    marginBottom: 1,
                    padding: 0.5,
                    pl: 2,
                    backgroundColor: "white",
                  }}
                  data-testid="selected-product-row"
                >
                  <Typography variant={"body1"}>
                    {product.product_name}
                  </Typography>
                  <IconButton
                    data-testid={`delete-btn`}
                    aria-label="delete"
                    onClick={() => {
                      setSelectedProducts((prev) =>
                        [...prev].filter((id) => id !== product.id),
                      );
                    }}
                  >
                    <CloseSharpIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: `${boxRemHeight}rem`,
                }}
              >
                <Typography variant={"body1"}>
                  {t("adjustmentsPage.wizard.stepTwo.noProductSelected")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductSelectionView;
