import {
  CountryViewModel,
  MacroFilter,
  CountryAverageDistanceTravelled,
  CountryAverageManufacturingEur,
  CountryPackagingAverageRecoveryRate,
  EnergyTypeEmissionFactor,
  IngredientEmissionFactor,
  PartMaterialEmissionFactor,
  VehicleMixEmissionFactor,
  EntityWeightAggregate,
  WeightedAverageRecycledContentAggregate,
  WeightedAverageNetLossRateAggregate,
  IngredientMultiWeightMacroAdjustmentDto,
  IngredientSpecificWeightMacroAdjustmentDto,
  PackagingEmissionFactorMacroAdjustmentDto,
  IngredientEmissionFactorMacroAdjustmentDto,
  PackagingRecoveryRateMacroAdjustmentDto,
  PackagingMultiWeightMacroAdjustmentDto,
  PackagingSpecificWeightMacroAdjustmentDto,
  ManufacturingEurMacroAdjustmentDto,
  ManufacturingSourceMixEfMacroAdjustmentDto,
  LogisticsDistanceMacroAdjustmentDto,
  LogisticsRoadVehicleMixEfMacroAdjustmentDto,
  CdeEnergyMixEfMacroAdjustmentDto,
  CdeFleetBreakdownMacroAdjustmentDto,
  IngredientWeightEntity,
  IngredientEfEntityDto,
  PackagingEfEntityDto,
  PackagingNetLossRateEntityDto,
  PackagingRecycledContentEntityDto,
  PackagingRecoveryRateEntityWithCountryDto,
  ManufacturingSourceMixEfEntityDto,
  ManufacturingEurEntityWithCountryDto,
  CdeFleetBreakdownEntityDto,
  CdeEnergyMixEfEntityDto,
  LogisticsDistanceEntityWithCountryDto,
  LogisticsVehicleMixEfEntityDto,
  EmissionFactorActions,
  CdeFleetBreakdownActions,
  ManufacturingEurActions,
  PackagingRecycledContentActions,
  PackagingEfActions,
  PackagingWeightEntityDto,
  PackagingRecycledContentMacroAdjustmentDto,
  PackagingNetLossRateMacroAdjustmentDto,
  CountryCdeEnergyEmissionFactor,
  CountryCdeAverageEnergyConsumption,
} from "orval/generated/models";
import { ReactNode } from "react";

export enum MacroAdjustmentPillars {
  Ingredients = "Ingredients",
  Packaging = "Packaging",
  Manufacturing = "Manufacturing",
  Logistics = "Logistics",
  CDE = "CDE",
  SalesMix = "Sales mix",
}

export enum MacroAdjustmentInputAreas {
  IngredientWeights = "Ingredient weights",
  IngredientsEmissionFactors = "Ingredients emission factors",
  PackagingWeights = "Packaging weights",
  RecycledContent = "Recycled content",
  RecoveryRate = "Recovery rate",
  NetLossRate = "Net loss rate",
  PackagingEmissionFactors = "Packaging emission factors",
  EnergyUsageRatio = "Manufacturing EUR",
  SourceMixAndEmissionFactors = "Manufacturing source mix and emission factors",
  DistanceTravelled = "Distance travelled",
  LogisticsRoadVehicleMixAndEmissionFactors = "Logistics road vehicle mix and emission factors",
  OtherVehicleMixAndEmissionFactors = "Logistics other vehicle mix and emission factors",
  CDEFleetBreakdown = "CDE fleet breakdown",
  CDEEnergyMixAndEmissionFactors = "CDE energy mix and emission factors",
  SalesGrowth = "Sales growth (CAGR)",
  CategoryMix = "Category mix",
  PackMix = "Pack mix",
  DLZShare = "DLZ share",
}

export enum MacroAdjustmentTypes {
  MultiBrandAdjustment = "Multi brand adjustment",
  SpecificBrandAdjustment = "Specific brand adjustment",
  MultiSKUAdjustment = "Multi SKU adjustment",
  SpecificSKUAdjustment = "Specific SKU adjustment",
  NotApplicable = "Not applicable",
}

export enum MacroAdjustmentCombinations {
  Ingredients_IngredientWeights_MultiBrandAdjustment = "Ingredients_IngredientWeights_MultiBrandAdjustment",
  Ingredients_IngredientWeights_SpecificBrandAdjustment = "Ingredients_IngredientWeights_SpecificBrandAdjustment",
  Ingredients_IngredientsEmissionFactors = "Ingredients_IngredientsEmissionFactors",
  Packaging_PackagingWeights_MultiSKUAdjustment = "Packaging_PackagingWeights_MultiSKUAdjustment",
  Packaging_PackagingWeights_SpecificSKUAdjustment = "Packaging_PackagingWeights_SpecificSKUAdjustment",
  Packaging_RecycledContent = "Packaging_RecycledContent",
  Packaging_RecoveryRate = "Packaging_RecoveryRate",
  Packaging_NetLossRate = "Packaging_NetLossRate",
  Packaging_PackagingEmissionFactors = "Packaging_PackagingEmissionFactors",
  Manufacturing_ManufacturingEur = "Manufacturing_ManufacturingEur",
  Manufacturing_ManufacturingSourceMixAndEmissionFactors = "Manufacturing_ManufacturingSourceMixAndEmissionFactors",
  Logistics_DistanceTravelled = "Logistics_DistanceTravelled",
  Logistics_LogisticsRoadVehicleMixAndEmissionFactors = "Logistics_LogisticsRoadVehicleMixAndEmissionFactors",
  Logistics_LogisticsOtherVehicleMixAndEmissionFactors = "Logistics_LogisticsOtherVehicleMixAndEmissionFactors",
  CDE_CDEFleetBreakdown = "CDE_CdeFleetBreakdown",
  CDE_CDEEnergyMixAndEmissionFactors = "CDE_CdeEnergyMixAndEmissionFactors",
  SalesMix_SalesGrowth = "SalesMix_SalesGrowth",
  SalesMix_CategoryMix = "SalesMix_CategoryMix",
  SalesMix_PackMix = "SalesMix_PackMix",
  SalesMix_DLZShare = "SalesMix_DLZShare",
}

export type MacroAdjustmentCombination = {
  combo: MacroAdjustmentCombinations;
  name: string;
};

export interface MacroAdjustmentStep {
  index: number;
  label: string | null;
  component: ReactNode;
  leftBtnText: string;
  rightBtnText: string;
  stepTitle: string | null;
  stepSubtitle?: string;
}

export enum MacroFilterDropdownTypes {
  Countries = "countries",
  Categories = "categories",
  BrandGroups = "brand_groups",
  Brands = "brands",
}

export type AdjustmentEntities =
  | IngredientWeightEntity[]
  | IngredientEfEntityDto[]
  | PackagingWeightEntityDto[]
  | PackagingEfEntityDto[]
  | PackagingNetLossRateEntityDto[]
  | PackagingRecycledContentEntityDto[]
  | PackagingRecoveryRateEntityWithCountryDto[]
  | ManufacturingEurEntityWithCountryDto[]
  | ManufacturingSourceMixEfEntityDto[]
  | LogisticsDistanceEntityWithCountryDto[]
  | LogisticsVehicleMixEfEntityDto[]
  | CdeFleetBreakdownEntityDto[]
  | CdeEnergyMixEfEntityDto[];

export type AdjustmentActions =
  | EmissionFactorActions
  | ManufacturingEurActions
  | PackagingRecycledContentActions
  | PackagingEfActions
  | CdeFleetBreakdownActions;

export type MacroAdjustmentWizardFormikValues = {
  payload: {
    description?: string | null;
    entities: AdjustmentEntities;
    actions?: AdjustmentActions;
    filters: { [key: string]: number[] | string[] };
    name: string;
  };
  selectedPillar: MacroAdjustmentPillars | null;
  selectedInputArea: MacroAdjustmentInputAreas | null;
  selectedAdjustmentType: MacroAdjustmentTypes | null;
  selectedCountries: CountryViewModel[];
  selectedEntities: MacroFilter[];
  selectedAction: "percentage" | "new_value";
  selectedProducts: number[];
};

export type AccordionArrayAggredatedDataType =
  | CountryPackagingAverageRecoveryRate[]
  | CountryAverageManufacturingEur[]
  | CountryAverageDistanceTravelled[]
  | PartMaterialEmissionFactor[]
  | EnergyTypeEmissionFactor[]
  | VehicleMixEmissionFactor[]
  | IngredientEmissionFactor[]
  | null;

export type MacroAdjustmentTableAccordion =
  | CountryPackagingAverageRecoveryRate
  | CountryAverageManufacturingEur
  | CountryAverageDistanceTravelled
  | PartMaterialEmissionFactor
  | EnergyTypeEmissionFactor
  | VehicleMixEmissionFactor
  | IngredientEmissionFactor
  | CountryCdeEnergyEmissionFactor
  | CountryCdeAverageEnergyConsumption;

export type CountryAccordionAggredatedDataType =
  | CountryPackagingAverageRecoveryRate
  | CountryAverageManufacturingEur
  | CountryAverageDistanceTravelled;

export type BasicTableAgreggatedWeightsType =
  | EntityWeightAggregate
  | WeightedAverageRecycledContentAggregate
  | WeightedAverageNetLossRateAggregate
  | CountryPackagingAverageRecoveryRate
  | CountryAverageManufacturingEur
  | CountryAverageDistanceTravelled;

export type MacroAdjustmentPayload =
  | IngredientMultiWeightMacroAdjustmentDto
  | IngredientSpecificWeightMacroAdjustmentDto
  | IngredientEmissionFactorMacroAdjustmentDto
  | PackagingMultiWeightMacroAdjustmentDto
  | PackagingSpecificWeightMacroAdjustmentDto
  | PackagingRecycledContentMacroAdjustmentDto
  | PackagingNetLossRateMacroAdjustmentDto
  | PackagingRecoveryRateMacroAdjustmentDto
  | PackagingEmissionFactorMacroAdjustmentDto
  | ManufacturingEurMacroAdjustmentDto
  | ManufacturingSourceMixEfMacroAdjustmentDto
  | LogisticsDistanceMacroAdjustmentDto
  | LogisticsRoadVehicleMixEfMacroAdjustmentDto
  | CdeEnergyMixEfMacroAdjustmentDto
  | CdeFleetBreakdownMacroAdjustmentDto
  | undefined;
