import { Bar } from "react-chartjs-2";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { chartData } from "../../types";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  BarElement,
  BarOptions,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
} from "chart.js";
import Chart from "chart.js/auto";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { ProductConfigurationsMacro } from "../../../micro-tool/components/MicroAdjustmentsTable/types";

export type WaterfallChartProps = {
  labels: string[];
  datasets: chartData;
  title: string;
  subtitle: string;
};

Chart.register(CategoryScale, LinearScale, BarElement, Title, Legend);

function WaterfallChart({
  labels,
  datasets,
  title,
  subtitle,
}: WaterfallChartProps) {
  const { t } = useTranslation("macro");
  const { palette } = useTheme();

  const data = {
    labels,
    datasets,
  } as const;

  const handleReformatting = (value: string | number[]) => {
    if (!value || !Array.isArray(value) || value.length < 2) {
      return ""; // Return an empty string or a default value if the value is invalid
    }
    const differenceValue = Number(value[1]) - Number(value[0]);
    if (differenceValue < 0) {
      return `(${formatNumber(differenceValue.toString().replace("-", ""), ProductConfigurationsMacro.WATERFALL)})`;
    } else {
      return formatNumber(
        differenceValue,
        ProductConfigurationsMacro.WATERFALL,
      );
    }
  };

  const xAxis = {
    ticks: {
      autoSkip: false,
      maxRotation: 0,
      minRotation: 0,
      color: `${palette.textColor.main}`,
      font: (context: any) => {
        if (
          context.tick.label.includes("Base") ||
          context.tick.label.includes("Glide") ||
          context.tick.label.includes("Scenario")
        )
          return {
            size: 14,
            weight: "bold",
          };

        return {
          size: 12,
          weight: "normal",
        };
      },
    },
    border: {
      color: `${palette.textColor.main}`,
    },
  };
  const yAxis = {
    ticks: {
      color: `${palette.textColor.main}`,
    },
    border: {
      color: `${palette.textColor.main}`,
    },
  };

  const options = {
    layout: {
      padding: {
        top: 20, // Used to keep all the datalabels within the canvas
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Set to false to allow setting exact height
    datasets: {
      bar: {
        skipNull: true,
        barThickness: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t("chartTitle"),
        position: "left",
      },
      datalabels: {
        anchor: "end",
        align: "top",
        color: `${palette.textColor.main}`,
        font: {
          weight: "bold",
        },
        formatter: (value: string | number[]) => handleReformatting(value),
        offset: 4, // Adjust to position labels slightly above the bars
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            // Customize the tooltip label
            const label = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw;
            return `${label}: ${t("resultsSection.waterfall.chart.labels.startValue")}${formatNumber(value[0], ProductConfigurationsMacro.WATERFALL)}, ${t("resultsSection.waterfall.chart.labels.endValue")}${formatNumber(value[1], ProductConfigurationsMacro.WATERFALL)}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove vertical grid lines
        },
        ...xAxis,
      },
      y: {
        ...yAxis,
      },
    },
  };

  return (
    <Box>
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
        }}
        tabIndex={0}
      >
        <Typography variant={"h4"}>{title}</Typography>
        <Typography variant={"h5"}>{subtitle}</Typography>
      </Box>
      <Box sx={{ height: "700px", overflowX: "auto" }} mt={2} mb={2}>
        <div style={{ width: "1700px", height: "100%" }}>
          <Bar
            test-id="waterfall-chart"
            options={options as unknown as BarOptions}
            data={data}
            plugins={[ChartDataLabels]}
          />
        </div>
      </Box>
    </Box>
  );
}

export default WaterfallChart;
