import {
  MRT_TableContainer as MaterialTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { OnChangeFn, VisibilityState } from "@tanstack/table-core";
import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ClipboardEvent,
} from "react";
import {
  AverageDistanceTravelled,
  AverageManufacturingEur,
  CountryAverageDistanceTravelled,
  CountryAverageManufacturingEur,
  CountryPackagingAverageRecoveryRate,
  EntityWeight,
  EntityWeightAggregate,
  IngredientWeightEntity,
  LogisticsDistanceEntityWithCountryDto,
  ManufacturingEurEntityWithCountryDto,
  PackagingAverageNetLossRate,
  PackagingAverageRecoveryRate,
  PackagingAverageRecycledContent,
  PackagingNetLossRateEntityDto,
  PackagingRecoveryRateEntityWithCountryDto,
  PackagingRecycledContentEntityDto,
  PackagingWeightEntityDto,
  WeightedAverageNetLossRateAggregate,
  WeightedAverageRecycledContentAggregate,
} from "orval/generated/models";
import {
  formatNumberOrEmpty,
  getDecimalPlacesForDataStructureKey,
} from "utils/formatNumber/formatNumber";
import { AppConstant } from "constants/AppConstant";
import {
  BasicTableAgreggatedWeightsType,
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import { Box, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export type TableEntityType = {
  id: number;
  baseYear?: number;
  targetYear?: number;
  adjusted_value: number | null;
  action?: "percentage" | "new_value" | null;
  country?: string;
};

export type MacroAdjustmentStepThreeBasicTableProps = {
  validationErrors: { [key: string]: string | undefined };
  setValidationErrors: (
    errors: (prev: { [key: string]: string | undefined }) => {
      [key: string]: string | undefined;
    },
  ) => void;
  base_year: number;
  target_year: number;
  adjustmentCombination: MacroAdjustmentCombinations | undefined;
  aggregatedWeights:
    | BasicTableAgreggatedWeightsType
    | CountryPackagingAverageRecoveryRate
    | CountryAverageManufacturingEur
    | CountryAverageDistanceTravelled;
};

function MacroAdjustmentStepThreeBasicTable({
  validationErrors,
  setValidationErrors,
  base_year,
  target_year,
  adjustmentCombination,
  aggregatedWeights,
}: MacroAdjustmentStepThreeBasicTableProps) {
  const { palette } = useTheme();

  const { t } = useTranslation(["macro", "common"]);

  const { setFieldValue, values } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const [columnVisibility, setColumnVisibility] = useState({
    baseYear: false,
    targetYear: false,
  });

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, row: any) => {
      const updatedEntity = { ...row.original };

      const newValue = e.target.value === "" ? null : Number(e.target.value);
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment: {
          const payloadIngredientEntities: IngredientWeightEntity[] = values
            .payload.entities as IngredientWeightEntity[];
          setFieldValue(
            "payload.entities",
            payloadIngredientEntities.map((entity: IngredientWeightEntity) => {
              if (entity.ingredient_id === updatedEntity.id) {
                return {
                  ...entity,
                  ingredient_id: updatedEntity.id,
                  weight: newValue,
                } as IngredientWeightEntity;
              }
              return entity;
            }),
          ).catch((e) => console.warn(e));
          break;
        }
        case MacroAdjustmentCombinations.Packaging_RecoveryRate: {
          const payloadPackagingEntities: PackagingRecoveryRateEntityWithCountryDto[] =
            values.payload
              .entities as PackagingRecoveryRateEntityWithCountryDto[];
          const updatedEntities = payloadPackagingEntities.map(
            (entity: PackagingRecoveryRateEntityWithCountryDto) => {
              if (
                entity.part_material_id === updatedEntity.id &&
                entity.country === updatedEntity.country
              ) {
                return {
                  ...entity,
                  part_material_id: updatedEntity.id,
                  recovery_rate: newValue,
                } as PackagingRecoveryRateEntityWithCountryDto;
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.part_material_id === updatedEntity.id &&
                e.country === updatedEntity.country,
            )
          )
            updatedEntities.push({
              part_material_id: updatedEntity.id,
              recovery_rate: newValue as number,
              country: updatedEntity.country,
            });

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur: {
          const payloadEntities: ManufacturingEurEntityWithCountryDto[] = values
            .payload.entities as ManufacturingEurEntityWithCountryDto[];
          const updatedEntities = payloadEntities.map(
            (entity: ManufacturingEurEntityWithCountryDto) => {
              if (
                entity.energy_type_id === updatedEntity.id &&
                entity.country === updatedEntity.country
              ) {
                return {
                  ...entity,
                  energy_type_id: updatedEntity.id,
                  eur: newValue,
                } as ManufacturingEurEntityWithCountryDto;
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.energy_type_id === updatedEntity.id &&
                e.country === updatedEntity.country,
            )
          )
            updatedEntities.push({
              energy_type_id: updatedEntity.id,
              eur: newValue as number,
              country: updatedEntity.country,
            });

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Logistics_DistanceTravelled: {
          const payloadEntities: LogisticsDistanceEntityWithCountryDto[] =
            values.payload.entities as LogisticsDistanceEntityWithCountryDto[];
          const updatedEntities = payloadEntities.map(
            (entity: LogisticsDistanceEntityWithCountryDto) => {
              if (
                entity.transport_mode_id === updatedEntity.id &&
                entity.country === updatedEntity.country
              ) {
                return {
                  ...entity,
                  transport_mode_id: updatedEntity.id,
                  distance: newValue,
                } as LogisticsDistanceEntityWithCountryDto;
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.transport_mode_id === updatedEntity.id &&
                e.country === updatedEntity.country,
            )
          )
            updatedEntities.push({
              transport_mode_id: updatedEntity.id,
              distance: newValue as number,
              country: updatedEntity.country,
            });

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Packaging_RecycledContent: {
          const payloadEntities: PackagingRecycledContentEntityDto[] = values
            .payload.entities as PackagingRecycledContentEntityDto[];
          setFieldValue(
            "payload.entities",
            payloadEntities.map((entity: PackagingRecycledContentEntityDto) => {
              if (entity.part_material_id === updatedEntity.id) {
                return {
                  ...entity,
                  part_material_id: updatedEntity.id,
                  recycled_content: newValue,
                } as PackagingRecycledContentEntityDto;
              }
              return entity;
            }),
          ).catch((e) => console.warn(e));
          break;
        }
        case MacroAdjustmentCombinations.Packaging_NetLossRate: {
          const payloadEntities: PackagingNetLossRateEntityDto[] = values
            .payload.entities as PackagingNetLossRateEntityDto[];
          setFieldValue(
            "payload.entities",
            payloadEntities.map((entity: PackagingNetLossRateEntityDto) => {
              if (entity.part_material_id === updatedEntity.id) {
                return {
                  ...entity,
                  part_material_id: updatedEntity.id,
                  net_loss_rate: newValue,
                } as PackagingNetLossRateEntityDto;
              }
              return entity;
            }),
          ).catch((e) => console.warn(e));
          break;
        }

        default: {
          const payloadPackagingEntities: PackagingWeightEntityDto[] = values
            .payload.entities as PackagingWeightEntityDto[];
          const updatedEntities = payloadPackagingEntities.map(
            (entity: PackagingWeightEntityDto) => {
              if (entity.part_material_id === updatedEntity.id) {
                return {
                  ...entity,
                  part_material_id: updatedEntity.id,
                  weight: newValue,
                } as PackagingWeightEntityDto;
              }
              return entity;
            },
          );
          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
      }
    },
    [adjustmentCombination, setFieldValue, values.payload.entities],
  );

  const entityColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return t("adjustmentsPage.wizard.stepThree.ingredient");
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return t("adjustmentsPage.wizard.stepThree.packagingMaterial");
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return t("adjustmentsPage.wizard.stepThree.energyType");
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return t("adjustmentsPage.wizard.stepThree.transportMode");
      default:
        return "";
    }
  }, [adjustmentCombination, t]);

  const baseYearColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.ingredientsBaseYear")}`;
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.packagingBaseYear")}`;
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.recycledContent")} (%)`;
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.recoveryRate")} (%)`;
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.netLossRate")} (%)`;
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.energyUsageRatio")}`;
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return `${base_year} ${t("adjustmentsPage.wizard.stepThree.distanceTravelled")}`;
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return `${base_year}`;
      default:
        return "";
    }
  }, [adjustmentCombination, base_year, t]);

  const targetYearColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.ingredientsTargetYear")}`;
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.packagingTargetYear")}`;
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.recycledContent")} (%)`;
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.recoveryRate")} (%)`;
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.netLossRate")} (%)`;
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.energyUsageRatio")}`;
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return `${target_year} ${t("adjustmentsPage.wizard.stepThree.distanceTravelled")}`;
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return `${target_year}`;
      default:
        return "";
    }
  }, [adjustmentCombination, target_year, t]);

  const adjustedValueColumnHeader = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return values.selectedAction === "percentage"
          ? t(
              "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentagePackaging",
            )
          : t(
              "adjustmentsPage.wizard.stepThree.adjustedValueHeaderNewValuePackaging",
            );
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        return t(
          "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentagePackaging",
        );
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return `${t("adjustmentsPage.wizard.stepThree.override")} ${target_year} ${t("adjustmentsPage.wizard.stepThree.recycledContent")} (%)`;
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return `${t("adjustmentsPage.wizard.stepThree.override")} ${target_year} ${t("adjustmentsPage.wizard.stepThree.recoveryRate")} (%)`;
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return `${t("adjustmentsPage.wizard.stepThree.override")} ${target_year} ${t("adjustmentsPage.wizard.stepThree.netLossRate")} (%)`;
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return `${t("adjustmentsPage.wizard.stepThree.adjust")} ${target_year} ${t("adjustmentsPage.wizard.stepThree.energyUsageRatio")}`;
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return `${t("adjustmentsPage.wizard.stepThree.adjust")} ${target_year} ${t("adjustmentsPage.wizard.stepThree.ingredientWeight")}`;
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return `${t("adjustmentsPage.wizard.stepThree.adjust")} ${target_year} ${t("adjustmentsPage.wizard.stepThree.distanceTravelled")}`;
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant={"body2"}>
              {t(
                "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentageIngredients",
              )}
            </Typography>
            <Typography sx={{ color: "#6A6A6A" }} variant={"body2"}>
              ({t("adjustmentsPage.wizard.stepThree.vs")} {target_year}{" "}
              {t("adjustmentsPage.wizard.stepThree.glidepath")})
            </Typography>
          </Box>
        );
      default:
        return t("adjustmentsPage.wizard.stepThree.adjustedValue");
    }
  }, [adjustmentCombination, values.selectedAction, target_year, t]);

  const tableData = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return values.selectedEntities.map((entity) => {
          const existingEntity = (
            values.payload
              .entities as PackagingRecoveryRateEntityWithCountryDto[]
          ).find(
            (adjustedEntity) =>
              adjustedEntity.part_material_id === entity.id &&
              adjustedEntity.country ===
                (aggregatedWeights as CountryPackagingAverageRecoveryRate)
                  .country,
          ) as PackagingRecoveryRateEntityWithCountryDto | undefined;

          return {
            id: entity.id,
            adjusted_value: existingEntity?.recovery_rate ?? null,
            action: "new_value",
            baseYear: (
              aggregatedWeights as CountryPackagingAverageRecoveryRate
            ).base_year.find(
              (item: PackagingAverageRecoveryRate) => item.id === entity.id,
            )?.avg_recovery_rate,
            targetYear: (
              aggregatedWeights as CountryPackagingAverageRecoveryRate
            ).target_year.find(
              (item: PackagingAverageRecoveryRate) => item.id === entity.id,
            )?.avg_recovery_rate,
            country: (aggregatedWeights as CountryPackagingAverageRecoveryRate)
              .country,
          };
        });
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return values.selectedEntities.map((entity) => {
          const existingEntity = (
            values.payload.entities as ManufacturingEurEntityWithCountryDto[]
          ).find(
            (adjustedEntity) =>
              adjustedEntity.energy_type_id === entity.id &&
              adjustedEntity.country ===
                (aggregatedWeights as CountryAverageManufacturingEur).country,
          );

          return {
            id: entity.id,
            adjusted_value: existingEntity?.eur ?? null,
            baseYear: (
              aggregatedWeights as CountryAverageManufacturingEur
            ).base_year.find(
              (item: AverageManufacturingEur) => item.id === entity.id,
            )?.avg_eur,
            targetYear: (
              aggregatedWeights as CountryAverageManufacturingEur
            ).target_year.find(
              (item: AverageManufacturingEur) => item.id === entity.id,
            )?.avg_eur,
            country: (aggregatedWeights as CountryAverageManufacturingEur)
              .country,
          };
        });
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return values.selectedEntities.map((entity) => {
          const existingEntity = (
            values.payload.entities as LogisticsDistanceEntityWithCountryDto[]
          ).find(
            (adjustedEntity) =>
              adjustedEntity.transport_mode_id === entity.id &&
              adjustedEntity.country ===
                (aggregatedWeights as CountryAverageDistanceTravelled).country,
          );

          return {
            id: entity.id,
            adjusted_value: existingEntity?.distance ?? null,
            baseYear: (
              aggregatedWeights as CountryAverageDistanceTravelled
            ).base_year.find(
              (item: AverageDistanceTravelled) => item.id === entity.id,
            )?.avg_distance,
            targetYear: (
              aggregatedWeights as CountryAverageDistanceTravelled
            ).target_year.find(
              (item: AverageDistanceTravelled) => item.id === entity.id,
            )?.avg_distance,
            country: (aggregatedWeights as CountryAverageDistanceTravelled)
              .country,
          };
        });
      default:
        return values.selectedEntities.map((entity) => {
          let adjustedValue: number | undefined = undefined;
          switch (adjustmentCombination) {
            case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
            case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
              adjustedValue = (
                values.payload.entities as IngredientWeightEntity[]
              ).find(
                (adjustedEntity) => adjustedEntity.ingredient_id === entity.id,
              )?.weight;
              break;
            case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
            case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
              adjustedValue = (
                values.payload.entities as PackagingWeightEntityDto[]
              ).find(
                (adjustedEntity) =>
                  adjustedEntity.part_material_id === entity.id,
              )?.weight;
              break;
            case MacroAdjustmentCombinations.Packaging_NetLossRate:
              adjustedValue = (
                values.payload.entities as PackagingNetLossRateEntityDto[]
              ).find(
                (adjustedEntity) =>
                  adjustedEntity.part_material_id === entity.id,
              )?.net_loss_rate;
              break;
            case MacroAdjustmentCombinations.Packaging_RecycledContent:
              adjustedValue = (
                values.payload.entities as PackagingRecycledContentEntityDto[]
              ).find(
                (adjustedEntity) =>
                  adjustedEntity.part_material_id === entity.id,
              )?.recycled_content;
              break;

            default:
              break;
          }

          let tableRow: TableEntityType;
          tableRow = {
            id: entity.id,
            adjusted_value: adjustedValue ?? null,
            baseYear: (() => {
              switch (adjustmentCombination) {
                case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
                  return (
                    aggregatedWeights as EntityWeightAggregate
                  )?.base_year.find(
                    (item: EntityWeight) => item.id === entity.id,
                  )?.weight;
                case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
                  return (
                    aggregatedWeights as EntityWeightAggregate
                  )?.base_year.find(
                    (item: EntityWeight) => item.id === entity.id,
                  )?.weight;
                case MacroAdjustmentCombinations.Packaging_RecycledContent:
                  return (
                    aggregatedWeights as WeightedAverageRecycledContentAggregate
                  )?.base_year.find(
                    (item: PackagingAverageRecycledContent) =>
                      item.id === entity.id,
                  )?.avg_recycled_content;
                case MacroAdjustmentCombinations.Packaging_NetLossRate:
                  return (
                    aggregatedWeights as WeightedAverageNetLossRateAggregate
                  )?.base_year.find(
                    (item: PackagingAverageNetLossRate) =>
                      item.id === entity.id,
                  )?.avg_net_loss_rate;

                default:
                  return undefined;
              }
            })(),
            targetYear: (() => {
              switch (adjustmentCombination) {
                case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
                  return (
                    aggregatedWeights as EntityWeightAggregate
                  )?.target_year.find(
                    (item: EntityWeight) => item.id === entity.id,
                  )?.weight;
                case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
                  return (
                    aggregatedWeights as EntityWeightAggregate
                  )?.target_year.find(
                    (item: EntityWeight) => item.id === entity.id,
                  )?.weight;
                case MacroAdjustmentCombinations.Packaging_RecycledContent:
                  return (
                    aggregatedWeights as WeightedAverageRecycledContentAggregate
                  )?.target_year.find(
                    (item: PackagingAverageRecycledContent) =>
                      item.id === entity.id,
                  )?.avg_recycled_content;
                case MacroAdjustmentCombinations.Packaging_NetLossRate:
                  return (
                    aggregatedWeights as WeightedAverageNetLossRateAggregate
                  )?.target_year.find(
                    (item: PackagingAverageNetLossRate) =>
                      item.id === entity.id,
                  )?.avg_net_loss_rate;
                default:
                  return undefined;
              }
            })(),
          };
          return tableRow;
        });
    }
  }, [
    adjustmentCombination,
    aggregatedWeights,
    values.payload.entities,
    values.selectedEntities,
  ]);

  const getRegex = (decimalPlaces: number) => {
    return new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`);
  };

  const columns = useMemo<MRT_ColumnDef<TableEntityType>[]>(
    () => [
      {
        accessorKey: "id",
        header: entityColumnHeader,
        enableEditing: false,
        Cell: ({ row }: { row: any }) => {
          return values.selectedEntities.find(
            (entity: any) => entity.id === row.original.id,
          )?.value;
        },
      },
      {
        accessorKey: "baseYear",
        header: baseYearColumnHeader,
        enableEditing: false,
        Cell: ({ row }) => {
          const baseYear = row.original.baseYear;
          if (baseYear == null) {
            return AppConstant.emptyCell;
          }
          return formatNumberOrEmpty(baseYear, adjustmentCombination as string);
        },
      },
      {
        accessorKey: "targetYear",
        header: targetYearColumnHeader,
        enableEditing: false,
        Cell: ({ row }) => {
          const targetYear = row.original.targetYear;
          if (targetYear == null) {
            return AppConstant.emptyCell;
          }
          return formatNumberOrEmpty(
            targetYear,
            adjustmentCombination as string,
          );
        },
      },
      {
        accessorKey: "adjusted_value",
        Header: adjustedValueColumnHeader,
        header: t(
          "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentagePackaging",
        ),
        enableColumnActions: false,
        muiEditTextFieldProps: ({ row }: { row: any }) => ({
          type: "number",
          required: true,
          placeholder: AppConstant.emptyCell,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            handleOnChange(e, row);
          },
          error:
            !!validationErrors?.[
              `${row.original.id}${adjustmentCombination === MacroAdjustmentCombinations.Packaging_RecoveryRate ? row.original.country : ""}` as string
            ],
          helperText:
            validationErrors?.[
              `${row.original.id}${adjustmentCombination === MacroAdjustmentCombinations.Packaging_RecoveryRate ? row.original.country : ""}` as string
            ],
          onBlur: (event: FocusEvent<HTMLInputElement>) => {
            let validationError: string | undefined = undefined;
            const value = Number(event.currentTarget.value) || 0;
            if (values.selectedAction === "percentage") {
              if (value < -100) {
                validationError = t(
                  "common:validation.greaterThanOrEqualToMinusOneHundred",
                );
              }
            } else if (
              adjustmentCombination ===
                MacroAdjustmentCombinations.Packaging_RecoveryRate &&
              (value < 0 || value > 100)
            ) {
              validationError = t(
                "common:validation.greaterThanOrEqualZeroAndLessThanOrEqualHundred",
              );
              // } else if (value < 0) {
              //   validationError = t("common:validation.greaterThanZero");
            } else {
              validationError = undefined;
            }

            setValidationErrors((prev: Record<number, string | undefined>) => ({
              ...prev,
              [`${row.original.id}${adjustmentCombination === MacroAdjustmentCombinations.Packaging_RecoveryRate ? row.original.country : ""}`]:
                validationError,
            }));
          },
          onInput: (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const decimalPlaces = getDecimalPlacesForDataStructureKey(
              adjustmentCombination as string,
            );
            const regex = getRegex(decimalPlaces);
            if (decimalPlaces === 0 && value.includes(".")) {
              event.target.value = value.replace(".", "");
            } else if (!regex.test(value)) {
              event.target.value = value.slice(0, -1);
            }
          },
          onPaste: (event: ClipboardEvent<HTMLInputElement>) => {
            const paste = event.clipboardData.getData("text");
            const decimalPlaces = getDecimalPlacesForDataStructureKey(
              adjustmentCombination as string,
            );
            const regex = getRegex(decimalPlaces);
            if (!regex.test(paste)) {
              event.preventDefault();
            }
          },
        }),
        muiTableHeadCellProps: () => ({
          sx: {
            color: palette.primary.main,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          },
        }),
      },
    ],
    [
      entityColumnHeader,
      baseYearColumnHeader,
      targetYearColumnHeader,
      adjustedValueColumnHeader,
      t,
      values.selectedEntities,
      values.selectedAction,
      validationErrors,
      handleOnChange,
      adjustmentCombination,
      setValidationErrors,
      palette.primary.main,
    ],
  );

  useEffect(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        setColumnVisibility({ baseYear: true, targetYear: true });
        break;
      default:
        break;
    }
  }, [adjustmentCombination]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    editDisplayMode: "table",
    enableEditing: true,
    enableColumnActions: false,
    enableSorting: false,
    enablePagination: true,
    enableKeyboardShortcuts: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnOrder: ["id", "baseYear", "targetYear", "adjusted_value"],
    },
    state: { columnVisibility },
    onColumnVisibilityChange:
      setColumnVisibility as OnChangeFn<VisibilityState>,
    getRowId: (row: any) => row.id,
    muiTableContainerProps: {
      sx: {
        border: "1px solid #E0E0E0",
        overflowY: "auto",
      },
    },
  });
  return <MaterialTable className="macro-accordion" table={table} />;
}

export default MacroAdjustmentStepThreeBasicTable;
