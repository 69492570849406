import Box from "@mui/material/Box";
import { AccordionComponent } from "../../AccordionComponent";
import { useTranslation } from "react-i18next";
import { MacroResults, Years } from "orval/generated/models";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import MacroPillarDeepDiveTable from "../../MacroPillarDeepDiveTable/MacroPillarDeepDiveTable";
import {
  MacroResultsAccordionEnum,
  TableResultsFilterDataType,
  TableResultsType,
} from "../types";
import { CarbonEmissionsBySection } from "../../CarbonEmissionsBySection/CarbonEmissionsBySection";
import { OptionalMetricsTableResultsType } from "../../MacroOptionalMetricsTable/Types";
import { Button } from "@mui/material";
import { accordionMap } from "../ScenarioResults";

export type ResultsTabSectionProps = {
  disabled: boolean;
  scenarioResults: MacroResults | null;
  scenarioYears: Years;
  expandedIds: string[];
  handleExpandCollapseAll: (
    parentAccordionKey: MacroResultsAccordionEnum,
  ) => void;
  isExpanded: (id: string) => boolean;
  handleToggle: (id: string) => void;
};

export function ResultsTabSection({
  disabled,
  scenarioResults,
  scenarioYears,
  expandedIds,
  handleExpandCollapseAll,
  isExpanded,
  handleToggle,
}: ResultsTabSectionProps) {
  const { t } = useTranslation("macro");

  const subAccordionLabelsOverview = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      id: MacroResultsAccordionEnum.OverviewByCategories,
      component: (
        <CarbonEmissionsBySection
          scenarioResults={scenarioResults}
          scenarioYears={scenarioYears}
          type={OptionalMetricsTableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      id: MacroResultsAccordionEnum.OverviewByPackType,
      component: (
        <CarbonEmissionsBySection
          scenarioResults={scenarioResults}
          scenarioYears={scenarioYears}
          type={OptionalMetricsTableResultsType.BY_PACK_TYPE}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPillar",
      id: MacroResultsAccordionEnum.OverviewByPillar,
      component: (
        <CarbonEmissionsBySection
          scenarioResults={scenarioResults}
          scenarioYears={scenarioYears}
          type={OptionalMetricsTableResultsType.BY_PILLAR}
        />
      ),
    },
  ];

  const subAccordionLabelsPillarDeepDive = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      id: MacroResultsAccordionEnum.PillarDeepDiveByCategories,
      component: (
        <MacroPillarDeepDiveTable
          title={t("resultsSection.pillarDeepDiveTable.titles.byCategory")}
          initialFilter={TableResultsFilterDataType.ABSOLUTE}
          scenarioResults={scenarioResults}
          scenarioYears={scenarioYears}
          tableType={TableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      id: MacroResultsAccordionEnum.PillarDeepDiveByPackType,
      component: (
        <MacroPillarDeepDiveTable
          title={t("resultsSection.pillarDeepDiveTable.titles.byPack")}
          initialFilter={TableResultsFilterDataType.ABSOLUTE}
          scenarioResults={scenarioResults}
          scenarioYears={scenarioYears}
          tableType={TableResultsType.BY_PACK_TYPE}
        />
      ),
    },
  ];

  return (
    <Box mb={10}>
      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleExpandCollapseAll(MacroResultsAccordionEnum.Results);
          }}
          variant={"text"}
          size={"small"}
        >
          {accordionMap[MacroResultsAccordionEnum.Results].every(
            (accordion) => {
              return expandedIds.includes(accordion);
            },
          )
            ? t("common:actions.collapseAll")
            : t("common:actions.expandAll")}
        </Button>
      </Box>
      <AccordionComponent
        title={t("resultsTabSection.accordionLabels.overview")}
        disabled={disabled}
        expanded={isExpanded(MacroResultsAccordionEnum.Overview)}
        onChange={() => handleToggle(MacroResultsAccordionEnum.Overview)}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleExpandCollapseAll(MacroResultsAccordionEnum.Overview);
            }}
            variant={"text"}
            size={"small"}
          >
            {expandedIds.includes(
              MacroResultsAccordionEnum.OverviewByCategories,
            ) &&
            expandedIds.includes(
              MacroResultsAccordionEnum.OverviewByPackType,
            ) &&
            expandedIds.includes(MacroResultsAccordionEnum.OverviewByPillar)
              ? t("common:actions.collapseAll")
              : t("common:actions.expandAll")}
          </Button>
        </Box>
        {subAccordionLabelsOverview.map((item) => (
          <Box key={item.id} ml={SUB_ACCORDION_INDENT}>
            <AccordionComponent
              title={t(item.label)}
              disabled={disabled}
              expanded={isExpanded(item.id)}
              onChange={() => handleToggle(item.id)}
            >
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
      <AccordionComponent
        title={t("resultsTabSection.accordionLabels.pillarDeepDive")}
        disabled={disabled}
        expanded={isExpanded(MacroResultsAccordionEnum.PillarDeepDive)}
        onChange={() => handleToggle(MacroResultsAccordionEnum.PillarDeepDive)}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleExpandCollapseAll(MacroResultsAccordionEnum.PillarDeepDive);
            }}
            variant={"text"}
            size={"small"}
          >
            {expandedIds.includes(
              MacroResultsAccordionEnum.PillarDeepDiveByCategories,
            ) &&
            expandedIds.includes(
              MacroResultsAccordionEnum.PillarDeepDiveByPackType,
            )
              ? t("common:actions.collapseAll")
              : t("common:actions.expandAll")}
          </Button>
        </Box>
        {subAccordionLabelsPillarDeepDive.map((item) => (
          <Box key={item.id} ml={SUB_ACCORDION_INDENT}>
            <AccordionComponent
              title={t(item.label)}
              disabled={disabled}
              expanded={isExpanded(item.id)}
              onChange={() => handleToggle(item.id)}
            >
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
    </Box>
  );
}
