import { FC, ReactNode, useEffect, useState } from "react";
import { TermsContext } from "./TermsContext";
import { AuthSessionStorageKeys } from "../../auth/navigation/config";
import { useGetUserAcceptanceStatusLegalTermsOfUseStatusGet } from "../../../orval/generated/endpoint";
import { useSnackbar } from "../../../components/common/Notification/showSnackbar";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { SupportingPagesRoutesConfig } from "../../supporting-pages";
import { HomeRoutesConfig } from "../../home";

export const TermsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>();
  const [accessToken, setAccessToken] = useState<string | null>(
    sessionStorage.getItem(AuthSessionStorageKeys.accessToken),
  );
  const [statusRetryDelay, setStatusRetryDelay] = useState<number | null>(null);
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  // We must wait for an access token to be available before we can check the user's acceptance status
  const { data: statusData, error: statusError } =
    useGetUserAcceptanceStatusLegalTermsOfUseStatusGet({
      query: {
        enabled: !!accessToken,
        // if statusRetryDelay is defined, use that value else use react query's default value
        ...(!!statusRetryDelay ? { retryDelay: statusRetryDelay } : {}),
      },
    });

  useEffect(() => {
    const token = sessionStorage.getItem(AuthSessionStorageKeys.accessToken);
    if (location.pathname === "/home" && token !== accessToken) {
      setAccessToken(token);
    }
  }, [accessToken, location.pathname]);

  useEffect(() => {
    if (statusData) {
      setIsTermsAccepted(statusData.has_accepted_terms);
      if (
        window.location.pathname === SupportingPagesRoutesConfig.accessDenied
      ) {
        navigate(HomeRoutesConfig.homePage);
      }
    } else if (statusError) {
      const error = statusError as AxiosError;
      showSnackbar(error, "error");
      switch (error.response?.status) {
        case 401:
        case 403:
          // stop retrying for 401 and 403 errors
          setStatusRetryDelay(0);
          navigate(SupportingPagesRoutesConfig.accessDenied);
          break;
      }
    }
  }, [navigate, showSnackbar, statusData, statusError]);

  return (
    <TermsContext.Provider value={{ isTermsAccepted, setIsTermsAccepted }}>
      {children}
    </TermsContext.Provider>
  );
};
