export const scenarioResultsTabs = {
  tabs: [
    {
      label: "macro:tabLabels.dataVisualisations",
    },
    {
      label: "macro:tabLabels.results",
    },
  ],
} as const;
