import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ViewMicroScenarioDto } from "orval/generated/models";
import * as yup from "yup";
import { useFormik } from "formik";
import { CustomIcon, Icons } from "components/common";

export type EditProductModalProps = {
  productGuid: string;
  closeModal: () => void;
  scenarioDetails: ViewMicroScenarioDto;
  handleUpdateProductDisplayName: Function;
};

function MicroEditProductModal({
  productGuid,
  closeModal,
  scenarioDetails,
  handleUpdateProductDisplayName,
}: EditProductModalProps) {
  const { t } = useTranslation(["micro", "common"]);
  const { palette } = useTheme();
  const renameProductValidationSchema = yup.object({
    name: yup
      .string()
      .required(t("editProductModal.emptyNameErrorMessage"))
      .min(3, t("editProductModal.minNameLengthErrorMessage")),
  });

  const formik = useFormik<any>({
    initialValues: {
      name:
        scenarioDetails.products.find((product) => product.guid === productGuid)
          ?.custom_display_name ||
        scenarioDetails.products.find((product) => product.guid === productGuid)
          ?.product_name ||
        "",
    },
    validationSchema: renameProductValidationSchema,
    onSubmit: (values) => {
      handleUpdateProductDisplayName({
        scenarioId: scenarioDetails.id,
        productId: productGuid,
        data: { custom_display_name: values.name },
      });
      closeModal();
    },
  });

  return (
    <Box p={1}>
      <Box display="flex" justifyContent="space-between" m={2}>
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={closeModal}
        >
          <CustomIcon
            name={Icons.ARROR_LEFT}
            width={24}
            height={24}
            fill={palette.primary.main}
          />
        </IconButton>
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={closeModal}
        >
          <CustomIcon
            name={Icons.CLOSE}
            width={24}
            height={24}
            fill={palette.text.primary}
          />
        </IconButton>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box mx={2}>
          <Box mx={3}>
            <Typography textAlign="center" variant="h2" fontWeight={600} pb={3}>
              {t("editProductModal.title")}
            </Typography>
            <TextField
              fullWidth
              label="Name"
              id="edit-product-name"
              name="name"
              sx={{ mb: 4, width: "400px", height: "5em" }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && (formik.errors.name as string)}
              required
            />
          </Box>
          <Box display="flex" justifyContent="center" p={4}>
            <Stack direction="row" spacing={2} margin="auto">
              <Button variant="outlined" color="primary" onClick={closeModal}>
                {t("common:actions.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!formik.dirty || !formik.isValid}
              >
                {t("common:actions.save")}
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default MicroEditProductModal;
