import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Tooltip, Typography } from "@mui/material";
import "./SalesVolumeChartData.scss";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { ProductConfigurationsMacro } from "features/micro-tool/components/MicroAdjustmentsTable/types";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import { AppConstant } from "constants/AppConstant";

export type SalesVolumeChartAreaProps = {
  totalRTDL: number | undefined;
  filteredRTDL: number | undefined;
  targetYear: number | undefined;
  filtersDataIsPending: boolean;
};

const SalesVolumeChartArea = ({
  totalRTDL,
  filteredRTDL,
  targetYear,
  filtersDataIsPending,
}: SalesVolumeChartAreaProps) => {
  const { t } = useTranslation("macro");
  const tooltipPercentageDisplayThreshold = 10;
  const lowerDisplayThreshold = 0.05;
  const [orangeBarPercentage, setOrangeBarPercentage] = useState(100);
  const [open, setOpen] = useState(
    orangeBarPercentage < tooltipPercentageDisplayThreshold,
  );
  const typographyRef = useRef<HTMLSpanElement>(null);
  const [textOffset, setTextOffset] = useState(0);

  useEffect(() => {
    if (!filtersDataIsPending) {
      const newPercentage = ((filteredRTDL ?? 0) / (totalRTDL ?? 1)) * 100;
      if (newPercentage >= 0 && newPercentage !== orangeBarPercentage) {
        setOrangeBarPercentage(newPercentage);
      }
    }
  }, [totalRTDL, filteredRTDL, orangeBarPercentage, filtersDataIsPending]);

  useEffect(() => {
    setOpen(false);
    setTimeout(
      () => setOpen(orangeBarPercentage < tooltipPercentageDisplayThreshold),
      350,
    );
  }, [orangeBarPercentage]);

  useEffect(() => {
    if (typographyRef.current) {
      const textWidth = typographyRef.current.offsetWidth;
      setTextOffset(textWidth / 2);
    }
  }, [orangeBarPercentage]);

  return (
    <Grid
      p={2}
      mt={2}
      sx={{ border: "1px solid #CBCBCB", borderRadius: "4px" }}
      spacing={3}
      display={"flex"}
      alignItems={"center"}
      container
      tabIndex={0}
    >
      <Grid size={{ md: 2, sm: 3 }}>
        <Typography fontWeight={"bold"}>
          <span>
            {t("adjustmentsPage.wizard.stepTwo.salesChart.title1")}
            <span className={"orange-text"}>
              {t("adjustmentsPage.wizard.stepTwo.salesChart.title2")}
            </span>
            {t("adjustmentsPage.wizard.stepTwo.salesChart.title3")}
          </span>
        </Typography>
      </Grid>
      <Grid size={{ md: 8, sm: 6 }}>
        <Box className="percentage-bar-container" sx={{ position: "relative" }}>
          {orangeBarPercentage >= 0 ? (
            <Tooltip
              open={open}
              title={
                orangeBarPercentage < lowerDisplayThreshold
                  ? `${t("adjustmentsPage.wizard.stepTwo.salesChart.filteredOnChart")}${t("adjustmentsPage.wizard.stepTwo.salesChart.lessThanZeroOne")}%`
                  : `${t("adjustmentsPage.wizard.stepTwo.salesChart.filteredOnChart")} ${formatNumber(orangeBarPercentage, ProductConfigurationsMacro.SALES_VOLUME_BAR_ONE_DP)}%`
              }
              placement={"bottom"}
              arrow
              PopperProps={{
                sx: { zIndex: "10 !important" },
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
            >
              <Box
                className="percentage-bar"
                sx={{ width: `${orangeBarPercentage}%` }}
              >
                <Typography
                  ref={typographyRef}
                  sx={{
                    position: "absolute",
                    left: `${orangeBarPercentage / 2}%`,
                    transform: `translateX(-${textOffset}px)`,
                    display:
                      orangeBarPercentage < tooltipPercentageDisplayThreshold
                        ? "none"
                        : "block",
                    color: "white",
                    fontWeight: "bold",
                    paddingTop: 1,
                  }}
                >
                  {orangeBarPercentage === 0
                    ? AppConstant.emptyCell
                    : `${formatNumber(orangeBarPercentage, ProductConfigurationsMacro.SALES_VOLUME_BAR_ONE_DP)}%`}
                </Typography>
              </Box>
            </Tooltip>
          ) : null}
        </Box>
      </Grid>
      <Grid size={{ md: 2, sm: 3 }}>
        <Box mb={1}>
          <Typography
            sx={{ color: "#6A6A6A", fontSize: "16px", fontWeight: "400" }}
          >
            {`${t("adjustmentsPage.wizard.stepTwo.salesChart.RTDLMillions")}${targetYear}`}
          </Typography>
        </Box>
        <Box mb={1}>
          {filteredRTDL && filteredRTDL !== 0 && !filtersDataIsPending ? (
            <Typography
              sx={{ fontWeight: "400", fontSize: "16px", lineHeight: "22px" }}
            >
              {filteredRTDL > lowerDisplayThreshold ? (
                <span className={"bold-numbers"}>
                  {formatNumber(
                    filteredRTDL,
                    ProductConfigurationsMacro.SALES_VOLUME_BAR_ZERO_DP,
                  )}
                </span>
              ) : (
                <span className={"bold-numbers"}>
                  {t(
                    "adjustmentsPage.wizard.stepTwo.salesChart.lessThanZeroOne",
                  )}
                </span>
              )}
              {t("adjustmentsPage.wizard.stepTwo.salesChart.outOf")}
              <span className={"bold-numbers"}>
                {formatNumber(
                  totalRTDL,
                  ProductConfigurationsMacro.SALES_VOLUME_BAR_ZERO_DP,
                )}
              </span>
            </Typography>
          ) : (
            <Typography
              sx={{ fontWeight: "400", fontSize: "16px", lineHeight: "22px" }}
            >
              <span className={"bold-numbers"}>{AppConstant.emptyCell}</span>
              {t("adjustmentsPage.wizard.stepTwo.salesChart.outOf")}{" "}
              <span className={"bold-numbers"}>
                {formatNumber(
                  totalRTDL,
                  ProductConfigurationsMacro.SALES_VOLUME_BAR_ZERO_DP,
                )}
              </span>
            </Typography>
          )}
        </Box>

        <Box>
          <Typography
            sx={{ color: "#6A6A6A", fontSize: "16px", fontWeight: "400" }}
          >
            {t("adjustmentsPage.wizard.stepTwo.salesChart.currentlySelected")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SalesVolumeChartArea;
