import "./MacroOptionalMetricsTable.scss";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { Box, Typography, useTheme } from "@mui/material";
import { CustomSelect } from "components/common/CustomSelect";
import { useState } from "react";
import { MacroResults } from "orval/generated/models";
import { useTranslation } from "react-i18next";
import {
  OptionalMetricsTableDataItem,
  OptionalMetricsTableResultsFilterDataType,
  OptionalMetricsTableResultsType,
} from "./Types";

export type OptionalMetricsTableProps = {
  columns: MRT_ColumnDef<any>[];
  data: any;
  dataExtractionFunction?: (
    type: OptionalMetricsTableResultsType,
    scenarioResults: MacroResults,
    dataType: OptionalMetricsTableResultsFilterDataType,
  ) => OptionalMetricsTableDataItem[];
  type?: OptionalMetricsTableResultsType;
  title: string;
  subtitle?: string;
  displaySubtitle?: boolean;
  initialFilter?: OptionalMetricsTableResultsFilterDataType;
  showFilterDropdown?: boolean;
  highlightLastColumn?: boolean;
  highlightScenarioColumn?: boolean;
};

export const MacroOptionalMetricsTable = ({
  columns,
  data,
  type,
  title,
  subtitle,
  displaySubtitle,
  initialFilter,
  showFilterDropdown = false,
  highlightLastColumn = true,
  highlightScenarioColumn = false,
  dataExtractionFunction,
}: OptionalMetricsTableProps) => {
  const { t } = useTranslation("macro");

  const { palette } = useTheme();
  const [filterType, setFilterType] =
    useState<OptionalMetricsTableResultsFilterDataType>(
      initialFilter ||
        ("absolute" as OptionalMetricsTableResultsFilterDataType),
    );

  const computedData =
    dataExtractionFunction && type
      ? dataExtractionFunction(type, data, filterType)
      : data;
  const table = useMaterialReactTable({
    columns,
    data: computedData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want\
    enableColumnPinning: true,
    initialState: {
      columnPinning: {
        left: [
          "by_category",
          "by_pillar",
          "by_pack_type",
          "category",
          "metric",
        ],
      },
    },
    muiTablePaperProps: {
      sx: {
        elevation: 0,
        boxShadow: "none",
      },
    },
    defaultColumn: {
      muiTableBodyCellProps: ({ column, row, cell }) => {
        return {
          className:
            column.getIndex() === 0
              ? "first-column-cell"
              : column.getIndex() === columns.length - 1 && highlightLastColumn
                ? "last-column"
                : "",
          sx: {
            borderBottom:
              cell.id.charAt(0) === `${computedData.length - 2}`
                ? `thin solid ${palette.primary.main}`
                : `thin solid ${palette.textColor.light}`,
            borderRight: `thin solid ${palette.textColor.light}`,
            borderLeft:
              column.getIndex() === 0
                ? `thin solid ${palette.textColor.light}`
                : "none",
            fontWeight:
              row.id === `${computedData.length - 1}` ? "bold" : "initial",
            textAlign: "center",
            boxShadow: "none",
            color:
              // Used for waterfall chart only
              (highlightScenarioColumn && column.getIndex() === 2) ||
              (highlightScenarioColumn && column.getIndex() === 4)
                ? palette.primary.main
                : "initial",
          },
        };
      },
      muiTableHeadCellProps: ({ column }) => ({
        className: `table-header-cell ${column.id !== columns[0].id ? "middle-header-cells" : ""} ${
          column.id === columns[0].id ? "first-column-cell" : ""
        } ${column.id === columns[columns.length - 1].id && highlightLastColumn ? "last-column" : ""}`,
        sx: {
          borderBottom: `thin solid ${palette.primary.main}`,
          borderTop: `thin solid ${palette.textColor.light}`,
          borderRight: `thin solid ${palette.textColor.light}`,
          borderLeft:
            column.getIndex() === 0
              ? `thin solid ${palette.textColor.light}`
              : "none",
          textAlign: "center",
          boxShadow: "none",
          color:
            // Used for waterfall chart only
            (highlightScenarioColumn && column.getIndex() === 2) ||
            (highlightScenarioColumn && column.getIndex() === 4)
              ? palette.primary.main
              : "initial",
        },
      }),
    },
  });
  const units =
    filterType === "absolute"
      ? t("resultsSection.pillarDeepDiveTable.subtitles.absoluteEmissions")
      : t("resultsSection.pillarDeepDiveTable.subtitles.emissionsPerLitre");

  const subtitleText = subtitle ? subtitle : units;

  return (
    <Box className="displayHeader">
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          margin: "1rem 0",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
          tabIndex={0}
        >
          <Typography variant={"h4"}>{title}</Typography>
          {displaySubtitle && subtitleText ? (
            <Typography variant={"h5"}>{subtitleText}</Typography>
          ) : null}
        </Box>
        {showFilterDropdown ? (
          <Box
            width={"200px"}
            data-testid="optional-metrics-table-filter-dropdown-box"
          >
            <CustomSelect
              list={["absolute", "per_litre"]}
              label={"Filter by"}
              handlerFunction={(value) =>
                setFilterType(
                  value as OptionalMetricsTableResultsFilterDataType,
                )
              }
              source={"macro"}
              sourceKey={
                "macro:resultsSection.carbonEmissionOverview.filters.units"
              }
              initialValue="absolute"
            />
          </Box>
        ) : null}
      </Box>
      <Box data-testid="optional-metrics-table-box">
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};
