import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./TableDataTypesAccordion.scss";
import {
  ChangeEvent,
  SyntheticEvent,
  FocusEvent,
  useEffect,
  useState,
} from "react";
import Checkbox from "@mui/material/Checkbox";
import { Box, useTheme } from "@mui/material";
import { CustomIcon, Icons } from "components/common";

interface TableDataTypesAccordionProps {
  id: string;
  accordionHeader: string;
  accordionContent: string[];
  value: string[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: FocusEvent<HTMLButtonElement>) => void;
  error: boolean;
  reset: boolean;
  expandAll: boolean;
}

export default function TableDataTypesAccordion(
  props: TableDataTypesAccordionProps,
) {
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);
  const { palette } = useTheme();

  useEffect(() => {
    if (props.reset) {
      setChecked(false);
    }
  }, [props.reset]);

  useEffect(() => {
    setExpanded(props.expandAll);
  }, [props.expandAll]);

  const handleAccordionExpansionChange = (
    event: SyntheticEvent,
    isExpanded: boolean,
  ) => {
    if (!(event.target as HTMLElement).closest(".MuiCheckbox-root")) {
      setExpanded(isExpanded);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    props.onChange(event);
    setChecked(event.target.checked);
  };

  return (
    <>
      {props.accordionContent && (
        <Accordion
          className={`dataExtractionAccordion ${checked ? "checkedStyle" : "defaultStyle"}`}
          expanded={expanded}
          onChange={handleAccordionExpansionChange}
        >
          <AccordionSummary
            expandIcon={
              <CustomIcon
                name={Icons.CHEVRON_DOWN}
                width={24}
                height={24}
                fill={palette.primary.main}
              />
            }
            className={`dataExtractionAccordionSummary ${expanded ? "expanded" : ""}`}
            data-testid={`accordion-summary-${props.id}`}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Checkbox
                checked={checked}
                onChange={handleChange}
                onBlur={props.onBlur}
                id={props.id}
                name="tables"
                data-testid="accordion-checkbox"
              />
              <Typography sx={{ fontWeight: "500" }}>
                {props.accordionHeader}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={"dataExtractionAccordionDetails"}>
            {props.accordionContent?.map((content, index) => (
              <Typography key={index}>{content}</Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
