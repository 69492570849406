import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  AccordionArrayAggredatedDataType,
  BasicTableAgreggatedWeightsType,
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import {
  CdeFleetBreakdownActions,
  CountryAverageDistanceTravelled,
  CountryAverageManufacturingEur,
  CountryCdeAverageEnergyConsumption,
  CountryCdeEnergyEmissionFactor,
  CountryPackagingAverageRecoveryRate,
  EmissionFactorActions,
  EnergyTypeEmissionFactor,
  EntityWeightAggregate,
  IngredientEmissionFactor,
  PackagingEfActions,
  PartMaterialEmissionFactor,
  VehicleMixEmissionFactor,
  ViewMacroScenarioDto,
  WeightedAverageNetLossRateAggregate,
  WeightedAverageRecycledContentAggregate,
} from "orval/generated/models";
import { Loader } from "components/common";
import { CustomSelect } from "components/common/CustomSelect";
import {
  useGetAggregatedCdeEnergyEfScenariosMacroAdjustmentsAggregatedCdeEnergyMixEfPost,
  useGetAggregatedCdeFleetBreakdownScenariosMacroAdjustmentsAggregatedCdeFleetBreakdownPost,
  useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost,
  useGetAggregatedIngredientSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedIngredientsEmissionFactorPost,
  useGetAggregatedIngredientsWeightScenariosMacroAdjustmentsAggregatedIngredientsWeightPost,
  useGetAggregatedLogisticsOtherVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsOtherVehicleMixEfPost,
  useGetAggregatedLogisticsRoadVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsRoadVehicleMixEfPost,
  useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost,
  useGetAggregatedManufacturingSourceMixEfScenariosMacroAdjustmentsAggregatedManufacturingSourceMixEfPost,
  useGetAggregatedPackagingSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedPackagingEmissionFactorPost,
  useGetAggregatedPackagingWeightedAvgBreakageRateScenariosMacroAdjustmentsAggregatedPackagingNetLossRatePost,
  useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost,
  useGetAggregatedPackagingWeightedAvgRecycledContentScenariosMacroAdjustmentsAggregatedPackagingRecycledContentPost,
  useGetAggregatedPackagingWeightScenariosMacroAdjustmentsAggregatedPackagingWeightPost,
} from "orval/generated/endpoint";
import MacroAdjustmentStepThreeAccordionArray from "./MacroAdjustmentStepThreeAccordionArray";
import MacroAdjustmentStepThreeBasicTable from "./MacroAdjustmentStepThreeBasicTable";

export type MacroAdjustmentStepThreeProps = {
  validationErrors: { [key: string]: string | undefined };
  setValidationErrors: (
    errors: (prev: { [key: string]: string | undefined }) => {
      [key: string]: string | undefined;
    },
  ) => void;
  scenario: ViewMacroScenarioDto | undefined;
  adjustmentCombination: MacroAdjustmentCombinations | undefined;
};

export const MacroAdjustmentStepThree = ({
  validationErrors,
  setValidationErrors,
  scenario,
  adjustmentCombination,
}: MacroAdjustmentStepThreeProps) => {
  const { t } = useTranslation(["macro", "common"]);

  const { setFieldValue, values } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const [aggregatedWeights, setAggregatedWeights] = useState<
    | EntityWeightAggregate
    | WeightedAverageRecycledContentAggregate
    | WeightedAverageNetLossRateAggregate
    | EnergyTypeEmissionFactor[]
    | VehicleMixEmissionFactor[]
    | PartMaterialEmissionFactor[]
    | IngredientEmissionFactor[]
    | CountryCdeEnergyEmissionFactor[]
    | CountryCdeAverageEnergyConsumption[]
    | null
  >(null);

  const [aggregatedWeightsForCountries, setAggregatedWeightsForCountries] =
    useState<
      | CountryPackagingAverageRecoveryRate[]
      | CountryAverageManufacturingEur[]
      | CountryAverageDistanceTravelled[]
      | null
    >(null);

  const { mutateAsync: getAggregatedIngredientsWeights } =
    useGetAggregatedIngredientsWeightScenariosMacroAdjustmentsAggregatedIngredientsWeightPost();

  const { mutateAsync: getAggregatedPackagingWeights } =
    useGetAggregatedPackagingWeightScenariosMacroAdjustmentsAggregatedPackagingWeightPost();

  const { mutateAsync: getAggregatedRecycledContentWeights } =
    useGetAggregatedPackagingWeightedAvgRecycledContentScenariosMacroAdjustmentsAggregatedPackagingRecycledContentPost();

  const { mutateAsync: getAggregatedNetLossWeights } =
    useGetAggregatedPackagingWeightedAvgBreakageRateScenariosMacroAdjustmentsAggregatedPackagingNetLossRatePost();

  const { mutateAsync: getAggregatedRecoveryRateWeights } =
    useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost();

  const { mutateAsync: getAggregatedManufacturingEur } =
    useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost();

  const { mutateAsync: getAggregatedLogisticsDistanceTravelledData } =
    useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost();

  const { mutateAsync: getAggregatedPackagingEFData } =
    useGetAggregatedPackagingSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedPackagingEmissionFactorPost();

  const { mutateAsync: getAggregatedIngredientEFData } =
    useGetAggregatedIngredientSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedIngredientsEmissionFactorPost();

  const { mutateAsync: getAggregatedManufacturingEFData } =
    useGetAggregatedManufacturingSourceMixEfScenariosMacroAdjustmentsAggregatedManufacturingSourceMixEfPost();

  const { mutateAsync: getAggregatedRoadVehicleEFData } =
    useGetAggregatedLogisticsRoadVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsRoadVehicleMixEfPost();

  const { mutateAsync: getAggregatedOtherVehicleEFData } =
    useGetAggregatedLogisticsOtherVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsOtherVehicleMixEfPost();

  const { mutateAsync: getAggregatedCdeEnergyMixEfData } =
    useGetAggregatedCdeEnergyEfScenariosMacroAdjustmentsAggregatedCdeEnergyMixEfPost();

  const { mutateAsync: getAggregatedCdeFleetBreakdownData } =
    useGetAggregatedCdeFleetBreakdownScenariosMacroAdjustmentsAggregatedCdeFleetBreakdownPost();

  const descriptionKey = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
        return "multiSKUDescription";
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return "specificSKUDescription";
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return "specificBrandDescription";
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return "multiBrandDescription";
      case MacroAdjustmentCombinations.Packaging_RecycledContent:
        return "recycledContentDescription";
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
        return "recoveryRateDescription";
      case MacroAdjustmentCombinations.Packaging_NetLossRate:
        return "netLossRateDescription";
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
        return "manufacturingEURDescription";
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return "logisticsDistanceTravelledDescription";
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return "packagingEFDescription";
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
        return "manufacturingEFDescription";
      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
        return "ingredientEFDescription";
      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
        return "roadVehicleEFDescription";
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        return "otherVehicleEFDescription";
      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
        return "cdeEFDescription";
      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        return "cdeFleetBreakdownDescription";

      default:
        return "";
    }
  }, [adjustmentCombination]);

  useEffect(() => {
    const fetchIngredientsWeights = async () => {
      try {
        if (
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment
        ) {
          const data = await getAggregatedIngredientsWeights({
            data: {
              base_year: scenario?.inputs?.years?.base_year ?? 0,
              target_year: scenario?.inputs?.years?.target_year ?? 0,
              filters: {
                country: values.payload.filters.countries[0] as string,
                brand: values.payload.filters.brands[0] as number,
              },
              entities: values.selectedEntities.map((entity) => entity.id),
            },
          });
          setAggregatedWeights(data);
        }
      } catch (e) {
        console.warn(e);
      }
    };

    fetchIngredientsWeights();
  }, [
    adjustmentCombination,
    getAggregatedIngredientsWeights,
    scenario,
    values.payload.filters,
    values.selectedEntities,
  ]);

  useEffect(() => {
    const fetchProductWeights = async () => {
      try {
        if (values.selectedProducts?.length) {
          const payload = {
            data: {
              products: values.selectedProducts,
              entities: values.selectedEntities.map((entity) => entity.id),
              base_year: scenario?.inputs?.years?.base_year ?? 0,
              target_year: scenario?.inputs?.years?.target_year ?? 0,
            },
          };

          let data;
          switch (adjustmentCombination) {
            case MacroAdjustmentCombinations.Packaging_RecycledContent:
              data = await getAggregatedRecycledContentWeights(payload);
              setAggregatedWeights(data);
              break;
            case MacroAdjustmentCombinations.Packaging_NetLossRate:
              data = await getAggregatedNetLossWeights(payload);
              setAggregatedWeights(data);
              break;
            case MacroAdjustmentCombinations.Packaging_RecoveryRate:
              data = await getAggregatedRecoveryRateWeights(payload);
              setAggregatedWeightsForCountries(data);
              break;
            case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
              data = await getAggregatedManufacturingEur(payload);
              setAggregatedWeightsForCountries(data);
              break;
            case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
              data = await getAggregatedLogisticsDistanceTravelledData(payload);
              setAggregatedWeightsForCountries(data);
              break;
            default:
              data = await getAggregatedPackagingWeights({
                data: { ...payload.data, product: values.selectedProducts[0] },
              });
              setAggregatedWeights(data);
              break;
          }
        }
      } catch (e) {
        console.warn(e);
      }
    };

    fetchProductWeights();
  }, [
    adjustmentCombination,
    getAggregatedRecycledContentWeights,
    getAggregatedNetLossWeights,
    getAggregatedRecoveryRateWeights,
    getAggregatedManufacturingEur,
    getAggregatedLogisticsDistanceTravelledData,
    getAggregatedPackagingWeights,
    scenario,
    values.selectedProducts,
    values.selectedEntities,
  ]);

  useEffect(() => {
    const fetchOtherWeights = async () => {
      try {
        if (
          !values.selectedProducts?.length &&
          values.selectedCountries?.length
        ) {
          const payload = {
            data: {
              base_year: scenario?.inputs?.years?.base_year ?? 0,
              target_year: scenario?.inputs?.years?.target_year ?? 0,
              filters: {
                country: values.payload.filters.countries[0] as string,
              },
              entities: values.selectedEntities.map((entity) => entity?.id),
            },
          };

          let data;
          switch (adjustmentCombination) {
            case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
              data = await getAggregatedPackagingEFData(payload);
              break;
            case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
              data = await getAggregatedIngredientEFData(payload);
              break;
            case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
              data = await getAggregatedManufacturingEFData(payload);
              break;
            case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
              data = await getAggregatedRoadVehicleEFData(payload);
              break;
            case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
              data = await getAggregatedOtherVehicleEFData(payload);
              break;
            case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
              data = await getAggregatedCdeEnergyMixEfData({
                data: {
                  filters: {
                    countries: values.payload.filters.countries as string[],
                  },
                  base_year: scenario?.inputs?.years?.base_year as number,
                  target_year: scenario?.inputs?.years?.target_year as number,
                },
              });
              break;
            case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
              data = await getAggregatedCdeFleetBreakdownData({
                data: {
                  filters: {
                    countries: values.payload.filters.countries as string[],
                  },
                  base_year: scenario?.inputs?.years?.base_year as number,
                  target_year: scenario?.inputs?.years?.target_year as number,
                  entities: values.selectedEntities.map((entity) => entity.id),
                },
              });
              break;
            default:
              return;
          }

          setAggregatedWeights(data);
        }
      } catch (e) {
        console.warn(e);
      }
    };

    fetchOtherWeights();
  }, [
    adjustmentCombination,
    getAggregatedPackagingEFData,
    getAggregatedIngredientEFData,
    getAggregatedManufacturingEFData,
    getAggregatedRoadVehicleEFData,
    getAggregatedOtherVehicleEFData,
    getAggregatedCdeEnergyMixEfData,
    getAggregatedCdeFleetBreakdownData,
    scenario,
    values.payload.filters,
    values.selectedEntities,
    values.selectedProducts,
    values.selectedCountries?.length,
  ]);

  const renderAdjustmentComponent = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_RecoveryRate:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
        return (
          <MacroAdjustmentStepThreeAccordionArray
            aggregatedData={aggregatedWeightsForCountries}
            adjustmentCombination={adjustmentCombination}
            baseYear={scenario?.inputs?.years?.base_year as number}
            targetYear={scenario?.inputs?.years?.target_year as number}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />
        );
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        return (
          <MacroAdjustmentStepThreeAccordionArray
            aggregatedData={
              aggregatedWeights as AccordionArrayAggredatedDataType
            }
            adjustmentCombination={adjustmentCombination}
            baseYear={scenario?.inputs?.years?.base_year as number}
            targetYear={scenario?.inputs?.years?.target_year as number}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />
        );
      default:
        return (
          <MacroAdjustmentStepThreeBasicTable
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
            base_year={scenario?.inputs?.years?.base_year as number}
            target_year={scenario?.inputs?.years?.target_year as number}
            adjustmentCombination={adjustmentCombination}
            aggregatedWeights={
              aggregatedWeights as BasicTableAgreggatedWeightsType
            }
          />
        );
    }
  }, [
    adjustmentCombination,
    aggregatedWeights,
    aggregatedWeightsForCountries,
    scenario,
    setValidationErrors,
    validationErrors,
  ]);

  const renderActionsComponent = useMemo(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
      case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
        return (
          <Box my={1} display="flex" justifyContent="flex-end">
            <CustomSelect
              list={["percentage", "new_value"]}
              label={""}
              handlerFunction={(value) =>
                setFieldValue("selectedAction", value)
              }
              source={"macro"}
              sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
              initialValue={values.selectedAction ?? "new_value"}
              width={"200px"}
            />
          </Box>
        );
      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
        return (
          <Box my={1} display="flex" justifyContent="flex-end">
            <CustomSelect
              list={["percentage", "new_value"]}
              label={t("macro:adjustmentsPage.wizard.stepThree.actions.ef")}
              handlerFunction={(value) =>
                setFieldValue("payload.actions.ef", value)
              }
              source={"macro"}
              sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
              initialValue={
                (values.payload.actions as EmissionFactorActions)?.ef ??
                "percentage"
              }
              width={"200px"}
            />
          </Box>
        );
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        return (
          <Box
            my={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CustomSelect
              list={["percentage", "new_value"]}
              label={t(
                "macro:adjustmentsPage.wizard.stepThree.actions.virgin_ef",
              )}
              handlerFunction={(value) =>
                setFieldValue("payload.actions.virgin_ef", value)
              }
              source={"macro"}
              sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
              initialValue={
                (values.payload.actions as PackagingEfActions)?.virgin_ef ??
                "percentage"
              }
              width={"200px"}
            />
            <Box width="20px"></Box>
            <CustomSelect
              list={["percentage", "new_value"]}
              label={t(
                "macro:adjustmentsPage.wizard.stepThree.actions.recycled_ef",
              )}
              handlerFunction={(value) =>
                setFieldValue("payload.actions.recycled_ef", value)
              }
              source={"macro"}
              sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
              initialValue={
                (values.payload.actions as PackagingEfActions)?.recycled_ef ??
                "percentage"
              }
              width={"200px"}
            />
          </Box>
        );

      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        return (
          <Box
            my={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CustomSelect
              list={["percentage", "new_value"]}
              label={t(
                "macro:adjustmentsPage.wizard.stepThree.actions.number_of_units",
              )}
              handlerFunction={(value) =>
                setFieldValue("payload.actions.number_of_units", value)
              }
              source={"macro"}
              sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
              initialValue={
                (values.payload.actions as CdeFleetBreakdownActions)
                  ?.number_of_units ?? "percentage"
              }
              width={"200px"}
            />
            <Box width="20px"></Box>
            <CustomSelect
              list={["percentage", "new_value"]}
              label={t(
                "macro:adjustmentsPage.wizard.stepThree.actions.avg_energy_consumption",
              )}
              handlerFunction={(value) =>
                setFieldValue("payload.actions.avg_energy_consumption", value)
              }
              source={"macro"}
              sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
              initialValue={
                (values.payload.actions as CdeFleetBreakdownActions)
                  ?.avg_energy_consumption ?? "percentage"
              }
              width={"200px"}
            />
          </Box>
        );
      default:
        return null;
    }
  }, [
    adjustmentCombination,
    setFieldValue,
    t,
    values.payload.actions,
    values.selectedAction,
  ]);

  const isLoading: boolean = useMemo(() => {
    return (
      ([
        MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment,
        MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment,
        MacroAdjustmentCombinations.Packaging_RecycledContent,
        MacroAdjustmentCombinations.Packaging_NetLossRate,
        MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors,
        MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors,
        MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors,
        MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors,
        MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors,
      ].includes(adjustmentCombination as MacroAdjustmentCombinations) &&
        !aggregatedWeights) ||
      ([
        MacroAdjustmentCombinations.Packaging_RecoveryRate,
        MacroAdjustmentCombinations.Manufacturing_ManufacturingEur,
        MacroAdjustmentCombinations.Logistics_DistanceTravelled,
      ].includes(adjustmentCombination as MacroAdjustmentCombinations) &&
        !aggregatedWeightsForCountries)
    );
  }, [adjustmentCombination, aggregatedWeights, aggregatedWeightsForCountries]);

  return (
    <Box>
      <Box>
        <Box mb={1} tabIndex={0}>
          <Typography variant={"h2"} className="text-ellipsis">
            {t("adjustmentsPage.wizard.stepThree.title")}
          </Typography>
        </Box>
        <Box mb={2} width="70%" tabIndex={0}>
          <Typography variant={"body1"}>
            {t(`adjustmentsPage.wizard.stepThree.${descriptionKey}`)}
          </Typography>
        </Box>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          {renderActionsComponent}
          {renderAdjustmentComponent}
        </Box>
      )}
    </Box>
  );
};
