import {
  MRT_TableContainer as MaterialTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { OnChangeFn, VisibilityState } from "@tanstack/table-core";
import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentTableAccordion,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import {
  AverageEnergyConsumption,
  CdeEnergyMixEfEntityDto,
  CdeFleetBreakdownEntityDto,
  CountryCdeAverageEnergyConsumption,
  CountryCdeEnergyEmissionFactor,
  EnergySourceData,
  EnergyTypeEmissionFactor,
  IngredientEfEntityDto,
  IngredientEmissionFactor,
  LogisticsVehicleMixEfEntityDto,
  ManufacturingSourceMixEfEntityDto,
  PackagingEfEntityDto,
  PackagingEmissionFactor,
  PartMaterialEmissionFactor,
  RoadVehicleSourceData,
  SupplierData,
  VehicleMixEmissionFactor,
} from "orval/generated/models";
import { useFormikContext } from "formik";
import { AppConstant } from "constants/AppConstant";
import {
  formatNumberButNotIncreaseDecimalPlaces,
  getDecimalPlacesForDataStructureKey,
} from "utils/formatNumber/formatNumber";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { deepClone } from "utils";

export type TableRowType = {
  supplier?: string;
  vehicle?: string;
  fuel?: string;
  fuel_display_unit?: string;
  energy_source?: string;
  cde_group?: string;
  baseYear: {
    supplierShare?: number;
    usage_share?: number;
    virginEF?: number;
    recycledEF?: number;
    ef?: number;
    avg_energy_consumption?: number;
    number_of_units?: number;
  };
  targetYear: {
    supplierShare?: number;
    usage_share?: number;
    virginEF?: number;
    recycledEF?: number;
    ef?: number;
    avg_energy_consumption?: number;
    number_of_units?: number;
  };
  adjustedValues: {
    supplierShareAdjustedValue?: number | null;
    usageShareAdjustedValue?: number | null;
    virginEFAdjustedValue?: number | null;
    recycledEFAdjustedValue?: number | null;
    efAdjustedValue?: number | null;
    averageEnergyConsumptionAdjustedValue?: number | null;
    numberOfUnitsAdjustedValue?: number | null;
  };

  part_material_id?: number;
  ingredient_id?: number;
  energy_type_id?: number;
  transport_mode_id?: number;
  country?: string;
};

export type MacroAdjustmentStepThreeCompositeTableProps = {
  adjustmentCombination: MacroAdjustmentCombinations;
  aggregatedData: MacroAdjustmentTableAccordion;
  baseYear: number;
  targetYear: number;
  validationErrors: { [key: string]: string | undefined };
  setValidationErrors: (
    errors: (prev: { [key: string]: string | undefined }) => {
      [key: string]: string | undefined;
    },
  ) => void;
};

function MacroAdjustmentStepThreeCompositeTable({
  adjustmentCombination,
  aggregatedData,
  baseYear,
  targetYear,
  validationErrors,
  setValidationErrors,
}: MacroAdjustmentStepThreeCompositeTableProps) {
  const { setFieldValue, values } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const { t } = useTranslation(["macro", "common"]);
  const { palette } = useTheme();

  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean;
  }>({
    supplier: false,
    energy_source: false,
    vehicle: false,
    fuel: false,
    fuel_display_unit: false,
    cde_group: false,
    "baseYear.supplierShare": false,
    "baseYear.virginEF": false,
    "baseYear.recycledEF": false,
    "baseYear.ef": false,
    "baseYear.usage_share": false,
    "baseYear.number_of_units": false,
    "baseYear.avg_energy_consumption": false,
    "targetYear.supplierShare": false,
    "targetYear.virginEF": false,
    "targetYear.recycledEF": false,
    "targetYear.ef": false,
    "targetYear.usage_share": false,
    "targetYear.number_of_units": false,
    "targetYear.avg_energy_consumption": false,
    "adjustedValues.supplierShareAdjustedValue": false,
    "adjustedValues.virginEFAdjustedValue": false,
    "adjustedValues.recycledEFAdjustedValue": false,
    "adjustedValues.efAdjustedValue": false,
    "adjustedValues.usageShareAdjustedValue": false,
    "adjustedValues.numberOfUnitsAdjustedValue": false,
    "adjustedValues.averageEnergyConsumptionAdjustedValue": false,
  });

  const handleOnChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      row: any,
      adjustedValueType:
        | "virgin_ef"
        | "recycled_ef"
        | "supply_share"
        | "usage_share"
        | "ef"
        | "avg_energy_consumption"
        | "number_of_units",
    ) => {
      const updatedEntity = { ...row.original };

      const newValue = e.target.value === "" ? null : Number(e.target.value);
      switch (adjustmentCombination) {
        case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors: {
          const payloadEntities: PackagingEfEntityDto[] = deepClone(
            values.payload.entities,
          );

          const updatedEntities = payloadEntities.map(
            (entity: PackagingEfEntityDto) => {
              if (
                entity.part_material_id === updatedEntity.part_material_id &&
                entity.supplier === updatedEntity.supplier
              ) {
                return {
                  ...entity,
                  [adjustedValueType]: newValue,
                };
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.part_material_id === updatedEntity.part_material_id &&
                e.supplier === updatedEntity.supplier,
            )
          )
            updatedEntities.push({
              part_material_id: updatedEntity.part_material_id,
              supplier: updatedEntity.supplier,
              [adjustedValueType]: newValue,
            } as PackagingEfEntityDto);

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );

          break;
        }
        case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors: {
          const payloadEntities: IngredientEfEntityDto[] = deepClone(
            values.payload.entities,
          );

          const updatedEntities = payloadEntities.map(
            (entity: IngredientEfEntityDto) => {
              if (
                entity.ingredient_id === updatedEntity.ingredient_id &&
                entity.supplier === updatedEntity.supplier
              ) {
                return {
                  ...entity,
                  [adjustedValueType]: newValue,
                };
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.ingredient_id === updatedEntity.ingredient_id &&
                e.supplier === updatedEntity.supplier,
            )
          )
            updatedEntities.push({
              ingredient_id: updatedEntity.ingredient_id,
              supplier: updatedEntity.supplier,
              [adjustedValueType]: newValue,
            } as IngredientEfEntityDto);

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors: {
          const payloadEntities: ManufacturingSourceMixEfEntityDto[] =
            deepClone(values.payload.entities);

          const updatedEntities = payloadEntities.map(
            (entity: ManufacturingSourceMixEfEntityDto) => {
              if (
                entity.energy_type_id === updatedEntity.energy_type_id &&
                entity.energy_source === updatedEntity.energy_source
              ) {
                return {
                  ...entity,
                  [adjustedValueType]: newValue,
                };
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.energy_type_id === updatedEntity.energy_type_id &&
                e.energy_source === updatedEntity.energy_source,
            )
          )
            updatedEntities.push({
              energy_type_id: updatedEntity.energy_type_id,
              energy_source: updatedEntity.energy_source,
              [adjustedValueType]: newValue,
            } as ManufacturingSourceMixEfEntityDto);

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
        case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors: {
          const payloadEntities: LogisticsVehicleMixEfEntityDto[] = deepClone(
            values.payload.entities,
          );

          const updatedEntities = payloadEntities.map(
            (entity: LogisticsVehicleMixEfEntityDto) => {
              if (
                entity.transport_mode_id === updatedEntity.transport_mode_id &&
                entity.vehicle === updatedEntity.vehicle
              ) {
                return {
                  ...entity,
                  [adjustedValueType]: newValue,
                };
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.transport_mode_id === updatedEntity.transport_mode_id &&
                e.vehicle === updatedEntity.vehicle,
            )
          )
            updatedEntities.push({
              transport_mode_id: updatedEntity.transport_mode_id,
              vehicle: updatedEntity.vehicle,
              [adjustedValueType]: newValue,
            } as LogisticsVehicleMixEfEntityDto);

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors: {
          const payloadEntities: CdeEnergyMixEfEntityDto[] = deepClone(
            values.payload.entities,
          );

          const updatedEntities = payloadEntities.map(
            (entity: CdeEnergyMixEfEntityDto) => {
              if (
                entity.country === updatedEntity.country &&
                entity.energy_source === updatedEntity.energy_source
              ) {
                return {
                  ...entity,
                  [adjustedValueType]: newValue,
                };
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.country === updatedEntity.country &&
                e.energy_source === updatedEntity.energy_source,
            )
          )
            updatedEntities.push({
              country: updatedEntity.country,
              energy_source: updatedEntity.energy_source,
              [adjustedValueType]: newValue,
            } as CdeEnergyMixEfEntityDto);

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown: {
          const payloadEntities: CdeFleetBreakdownEntityDto[] = deepClone(
            values.payload.entities,
          );

          const updatedEntities = payloadEntities.map(
            (entity: CdeFleetBreakdownEntityDto) => {
              if (
                entity.country === updatedEntity.country &&
                entity.cde_group === updatedEntity.cde_group
              ) {
                return {
                  ...entity,
                  [adjustedValueType]: newValue,
                };
              }
              return entity;
            },
          );

          if (
            !updatedEntities.find(
              (e) =>
                e.country === updatedEntity.country &&
                e.cde_group === updatedEntity.cde_group,
            )
          )
            updatedEntities.push({
              country: updatedEntity.country,
              cde_group: updatedEntity.cde_group,
              [adjustedValueType]: newValue,
            } as CdeFleetBreakdownEntityDto);

          setFieldValue("payload.entities", updatedEntities).catch((e) =>
            console.warn(e),
          );
          break;
        }
        default:
          break;
      }
    },
    [adjustmentCombination, setFieldValue, values.payload.entities],
  );

  const getRegex = (decimalPlaces: number) => {
    return new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`);
  };

  const columns = useMemo<MRT_ColumnDef<TableRowType>[]>(
    () => [
      {
        accessorKey: "main",
        header: "",
        enableEditing: false,
        columns: [
          {
            accessorKey: "supplier",
            header: t("adjustmentsPage.wizard.stepThree.supplierColumnHeader"),
            enableEditing: false,
            Cell: ({ row }: { row: any }) => row.original.supplier,
          },
          {
            accessorKey: "energy_source",
            header: t("adjustmentsPage.wizard.stepThree.sourceColumnHeader"),
            enableEditing: false,
            Cell: ({ row }: { row: any }) => row.original.energy_source,
          },

          {
            accessorKey: "vehicle",
            header: t("adjustmentsPage.wizard.stepThree.vehicleColumnHeader"),
            enableEditing: false,
            Cell: ({ row }: { row: any }) => row.original.vehicle,
          },
          {
            accessorKey: "fuel",
            header: t("adjustmentsPage.wizard.stepThree.fuelColumnHeader"),
            enableEditing: false,
            Cell: ({ row }: { row: any }) => row.original.fuel,
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "fuel_display_unit",
            header: t(
              "adjustmentsPage.wizard.stepThree.fuelDisplayUnitColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }: { row: any }) => row.original.fuel_display_unit,
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "cde_group",
            header: t("adjustmentsPage.wizard.stepThree.cdeGroupColumnHeader"),
            enableEditing: false,
            Cell: ({ row }: { row: any }) => row.original.cde_group,
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
        ],
        muiTableHeadCellProps: () => ({
          sx: {
            borderRight: "1px solid #CBCBCB",
          },
        }),
      },

      {
        accessorKey: "baseYear",
        header: baseYear.toString(),
        enableEditing: false,
        columns: [
          {
            accessorKey: "baseYear.supplierShare",
            header: t(
              "adjustmentsPage.wizard.stepThree.supplierShareColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear.supplierShare
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear.supplierShare,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "baseYear.usage_share",
            header: t(
              "adjustmentsPage.wizard.stepThree.usageShareColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear.usage_share
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear.usage_share,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "baseYear.virginEF",
            header: t("adjustmentsPage.wizard.stepThree.virginEFColumnHeader"),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear.virginEF
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear.virginEF,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "baseYear.recycledEF",
            header: t(
              "adjustmentsPage.wizard.stepThree.recycledEFColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear.recycledEF
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear.recycledEF,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "baseYear.ef",
            header: t("adjustmentsPage.wizard.stepThree.efColumnHeader"),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear.ef
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear.ef,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "baseYear.number_of_units",
            header: t(
              "adjustmentsPage.wizard.stepThree.numberOfUnitsColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear?.number_of_units
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear?.number_of_units,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "baseYear.avg_energy_consumption",
            header: t(
              "adjustmentsPage.wizard.stepThree.averageEnergyConsumptionColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.baseYear?.avg_energy_consumption
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.baseYear?.avg_energy_consumption,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
        ],
        muiTableHeadCellProps: () => ({
          sx: {
            borderRight: "1px solid #CBCBCB",
          },
        }),
      },
      {
        accessorKey: "targetYear",
        header: targetYear.toString(),
        enableEditing: false,
        columns: [
          {
            accessorKey: "targetYear.supplierShare",
            header: t(
              "adjustmentsPage.wizard.stepThree.supplierShareColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear.supplierShare
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear.supplierShare,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "targetYear.usage_share",
            header: t(
              "adjustmentsPage.wizard.stepThree.usageShareColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear.usage_share
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear.usage_share,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "targetYear.virginEF",
            header: t("adjustmentsPage.wizard.stepThree.virginEFColumnHeader"),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear.virginEF
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear.virginEF,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "targetYear.recycledEF",
            header: t(
              "adjustmentsPage.wizard.stepThree.recycledEFColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear.recycledEF
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear.recycledEF,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "targetYear.ef",
            header: t("adjustmentsPage.wizard.stepThree.efColumnHeader"),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear.ef
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear.ef,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "targetYear.number_of_units",
            header: t(
              "adjustmentsPage.wizard.stepThree.numberOfUnitsColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear?.number_of_units
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear?.number_of_units,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "targetYear.avg_energy_consumption",
            header: t(
              "adjustmentsPage.wizard.stepThree.averageEnergyConsumptionColumnHeader",
            ),
            enableEditing: false,
            Cell: ({ row }) => {
              return row.original.targetYear?.avg_energy_consumption
                ? formatNumberButNotIncreaseDecimalPlaces(
                    row.original.targetYear?.avg_energy_consumption,
                    adjustmentCombination as string,
                  )
                : AppConstant.emptyCell;
            },
            muiTableHeadCellProps: () => ({
              sx: {
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
        ],
        muiTableHeadCellProps: () => ({
          sx: {
            borderRight: "1px solid #CBCBCB",
          },
        }),
      },
      {
        accessorKey: "adjustedValues",
        header: `${t("adjustmentsPage.wizard.stepThree.adjust")} ${targetYear}`,
        enableEditing: false,
        muiTableHeadCellProps: () => ({
          sx: {
            color: palette.primary.main,
          },
        }),
        columns: [
          {
            accessorKey: "adjustedValues.supplierShareAdjustedValue",
            header: t(
              `adjustmentsPage.wizard.stepThree.${adjustmentCombination === MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors ? "supplierShareColumnHeader" : "overrideSupplyOfShareColumnHeader"}`,
            ),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "supply_share");
              },
              error:
                !!validationErrors?.[
                  `${row.original.part_material_id || row.original.ingredient_id || row.original.country}-${row.original.supplier || row.original.energy_source}-supply_share`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.part_material_id || row.original.ingredient_id || row.original.country}-${row.original.supplier || row.original.energy_source}-supply_share`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;
                const value = Number(event.currentTarget.value);

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else if (value > 100) {
                  validationError = t(
                    "common:validation.lessThanOrEqualToOneHundred",
                  );
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.part_material_id || row.original.ingredient_id || row.original.country}-${row.original.supplier || row.original.energy_source}-supply_share`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "adjustedValues.usageShareAdjustedValue",
            header: t(
              `adjustmentsPage.wizard.stepThree.overrideUsageOfShareColumnHeader`,
            ),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "usage_share");
              },
              error:
                !!validationErrors?.[
                  `${row.original.transport_mode_id}-${row.original.vehicle}-usage_share`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.transport_mode_id}-${row.original.vehicle}-usage_share`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;
                const value = Number(event.currentTarget.value);

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else if (value > 100) {
                  validationError = t(
                    "common:validation.lessThanOrEqualToOneHundred",
                  );
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.transport_mode_id}-${row.original.supplier}-usage_share`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "adjustedValues.virginEFAdjustedValue",
            header: t("adjustmentsPage.wizard.stepThree.virginEFColumnHeader"),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "virgin_ef");
              },
              error:
                !!validationErrors?.[
                  `${row.original.part_material_id}-${row.original.supplier}-virgin_ef`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.part_material_id}-${row.original.supplier}-virgin_ef`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.part_material_id}-${row.original.supplier}-virgin_ef`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
                borderRight: "1px solid #CBCBCB",
              },
            }),
          },
          {
            accessorKey: "adjustedValues.recycledEFAdjustedValue",
            header: t(
              "adjustmentsPage.wizard.stepThree.recycledEFColumnHeader",
            ),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "recycled_ef");
              },
              error:
                !!validationErrors?.[
                  `${row.original.part_material_id}-${row.original.supplier}-recycled_ef`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.part_material_id}-${row.original.supplier}-recycled_ef`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.part_material_id}-${row.original.supplier}-recycled_ef`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
              },
            }),
          },
          {
            accessorKey: "adjustedValues.efAdjustedValue",
            header: t("adjustmentsPage.wizard.stepThree.efColumnHeader"),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "ef");
              },
              error:
                !!validationErrors?.[
                  `${row.original.part_material_id || row.original.energy_type_id || row.original.ingredient_id || row.original.transport_mode_id || row.original.country}-${row.original.supplier || row.original.energy_source}-ef`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.part_material_id || row.original.energy_type_id || row.original.ingredient_id || row.original.transport_mode_id || row.original.country}-${row.original.supplier || row.original.energy_source}-ef`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;
                const value = Number(event.currentTarget.value);

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else if (value < 0) {
                  validationError = t("common:validation.greaterThanZero");
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.part_material_id || row.original.energy_type_id || row.original.ingredient_id || row.original.transport_mode_id || row.original.country}-${row.original.supplier || row.original.energy_source}-ef`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
              },
            }),
          },
          {
            accessorKey: "adjustedValues.numberOfUnitsAdjustedValue",
            header: t(
              "adjustmentsPage.wizard.stepThree.numberOfUnitsColumnHeader",
            ),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "number_of_units");
              },
              error:
                !!validationErrors?.[
                  `${row.original.country}-${row.original.cde_group}-number_of_units`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.country}-${row.original.cde_group}-number_of_units`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;
                const value = Number(event.currentTarget.value);

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else if (value < 0) {
                  validationError = t("common:validation.greaterThanZero");
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.country}-${row.original.cde_group}-number_of_units`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
              },
            }),
          },
          {
            accessorKey: "adjustedValues.averageEnergyConsumptionAdjustedValue",
            header: t(
              "adjustmentsPage.wizard.stepThree.averageEnergyConsumptionColumnHeader",
            ),
            enableColumnActions: false,
            muiEditTextFieldProps: ({ row }: { row: any }) => ({
              type: "number",
              required: true,
              placeholder: AppConstant.emptyCell,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e, row, "avg_energy_consumption");
              },
              error:
                !!validationErrors?.[
                  `${row.original.country}-${row.original.cde_group}-avg_energy_consumption`
                ],
              helperText:
                validationErrors?.[
                  `${row.original.country}-${row.original.cde_group}-avg_energy_consumption`
                ],
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                let validationError: string | undefined = undefined;
                const value = Number(event.currentTarget.value);

                if (!event.currentTarget.value) {
                  validationError = t("common:validation.required");
                } else if (value < 0) {
                  validationError = t("common:validation.greaterThanZero");
                } else {
                  validationError = undefined;
                }

                setValidationErrors(
                  (prev: Record<number, string | undefined>) => ({
                    ...prev,
                    [`${row.original.country}-${row.original.cde_group}-avg_energy_consumption`]:
                      validationError,
                  }),
                );
              },
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (decimalPlaces === 0 && value.includes(".")) {
                  event.target.value = value.replace(".", "");
                } else if (!regex.test(value)) {
                  event.target.value = value.slice(0, -1);
                }
              },
              onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => {
                const paste = event.clipboardData.getData("text");
                const decimalPlaces = getDecimalPlacesForDataStructureKey(
                  adjustmentCombination as string,
                );
                const regex = getRegex(decimalPlaces);
                if (!regex.test(paste)) {
                  event.preventDefault();
                }
              },
            }),
            muiTableHeadCellProps: () => ({
              sx: {
                color: palette.primary.main,
              },
            }),
          },
        ],
      },
    ],
    [
      t,
      baseYear,
      targetYear,
      adjustmentCombination,
      palette.primary.main,
      validationErrors,
      handleOnChange,
      setValidationErrors,
    ],
  );

  const tableData = useMemo((): TableRowType[] => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors: {
        const tableRows: TableRowType[] = [];

        (aggregatedData as PartMaterialEmissionFactor).base_year.forEach(
          (supplier) => {
            const targetYear = (
              aggregatedData as PartMaterialEmissionFactor
            ).target_year.find(
              (s) => s.supplier === supplier.supplier,
            ) as PackagingEmissionFactor;

            const existingEntity = (
              values.payload.entities as PackagingEfEntityDto[]
            ).find(
              (entity) =>
                entity.part_material_id ===
                  (aggregatedData as PartMaterialEmissionFactor)
                    .part_material_id && entity.supplier === supplier.supplier,
            );

            tableRows.push({
              supplier: supplier.supplier,
              baseYear: {
                supplierShare: supplier.supply_share,
                virginEF: supplier.virgin_ef,
                recycledEF: supplier.recycled_ef,
              },
              targetYear: {
                supplierShare: targetYear?.supply_share,
                virginEF: targetYear?.virgin_ef,
                recycledEF: targetYear?.recycled_ef,
              },

              adjustedValues: {
                supplierShareAdjustedValue:
                  existingEntity?.supply_share ?? null,
                virginEFAdjustedValue: existingEntity?.virgin_ef ?? null,
                recycledEFAdjustedValue: existingEntity?.recycled_ef ?? null,
              },

              part_material_id: (aggregatedData as PartMaterialEmissionFactor)
                .part_material_id,
            });
          },
        );
        return tableRows;
      }
      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors: {
        const tableRows: TableRowType[] = [];

        (aggregatedData as IngredientEmissionFactor).base_year.forEach(
          (supplier) => {
            const targetYear = (
              aggregatedData as IngredientEmissionFactor
            ).target_year.find(
              (s) => s.supplier === supplier.supplier,
            ) as SupplierData;

            const existingEntity = (
              values.payload.entities as IngredientEfEntityDto[]
            ).find(
              (entity) =>
                entity.ingredient_id ===
                  (aggregatedData as IngredientEmissionFactor).ingredient_id &&
                entity.supplier === supplier.supplier,
            );

            tableRows.push({
              supplier: supplier.supplier,
              baseYear: {
                supplierShare: supplier.supply_share,
                ef: supplier.ef,
              },
              targetYear: {
                supplierShare: targetYear?.supply_share,
                ef: targetYear?.ef,
              },
              adjustedValues: {
                supplierShareAdjustedValue:
                  existingEntity?.supply_share ?? null,
                efAdjustedValue: existingEntity?.ef ?? null,
              },

              ingredient_id: (aggregatedData as IngredientEmissionFactor)
                .ingredient_id,
            });
          },
        );
        return tableRows;
      }
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors: {
        const tableRows: TableRowType[] = [];

        (aggregatedData as EnergyTypeEmissionFactor).base_year.forEach(
          (energy_source) => {
            const targetYear = (
              aggregatedData as EnergyTypeEmissionFactor
            ).target_year.find(
              (e) => e.energy_source === energy_source.energy_source,
            ) as EnergySourceData;

            const existingEntity = (
              values.payload.entities as ManufacturingSourceMixEfEntityDto[]
            ).find(
              (entity) =>
                entity.energy_type_id ===
                  (aggregatedData as EnergyTypeEmissionFactor).energy_type_id &&
                entity.energy_source === energy_source.energy_source,
            );

            tableRows.push({
              energy_source: energy_source.energy_source,
              baseYear: {
                supplierShare: energy_source.supply_share,
                ef: energy_source.ef,
              },
              targetYear: {
                supplierShare: targetYear?.supply_share,
                ef: targetYear?.ef,
              },

              adjustedValues: {
                supplierShareAdjustedValue:
                  existingEntity?.supply_share ?? null,
                efAdjustedValue: existingEntity?.ef ?? null,
              },

              energy_type_id: (aggregatedData as EnergyTypeEmissionFactor)
                .energy_type_id,
            });
          },
        );
        return tableRows;
      }
      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors: {
        const tableRows: TableRowType[] = [];

        (aggregatedData as VehicleMixEmissionFactor).base_year.forEach(
          (vehicle) => {
            const targetYear = (
              aggregatedData as VehicleMixEmissionFactor
            ).target_year.find(
              (v) => v.vehicle === vehicle.vehicle,
            ) as RoadVehicleSourceData;

            const existingEntity = (
              values.payload.entities as LogisticsVehicleMixEfEntityDto[]
            ).find(
              (entity) =>
                entity.transport_mode_id ===
                  (aggregatedData as VehicleMixEmissionFactor)
                    .transport_mode_id && entity.vehicle === vehicle.vehicle,
            );

            tableRows.push({
              vehicle: vehicle.vehicle,
              ...(adjustmentCombination ===
              MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors
                ? {
                    fuel: (vehicle as RoadVehicleSourceData).fuel,
                    fuel_display_unit: (vehicle as RoadVehicleSourceData)
                      .fuel_display_unit,
                  }
                : {}),
              baseYear: {
                usage_share: vehicle.usage_share,
                ef: vehicle.ef,
              },
              targetYear: {
                usage_share: targetYear?.usage_share,
                ef: targetYear?.ef,
              },

              adjustedValues: {
                usageShareAdjustedValue: existingEntity?.usage_share ?? null,
                efAdjustedValue: existingEntity?.ef ?? null,
              },

              transport_mode_id: (aggregatedData as VehicleMixEmissionFactor)
                .transport_mode_id,
            });
          },
        );
        return tableRows;
      }
      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors: {
        const tableRows: TableRowType[] = [];

        (aggregatedData as CountryCdeEnergyEmissionFactor).base_year.forEach(
          (energy_source) => {
            const targetYear = (
              aggregatedData as CountryCdeEnergyEmissionFactor
            ).target_year.find(
              (e) => e.energy_source === energy_source.energy_source,
            ) as EnergySourceData;

            const existingEntity = (
              values.payload.entities as CdeEnergyMixEfEntityDto[]
            ).find(
              (entity) =>
                entity.country ===
                  (aggregatedData as CountryCdeEnergyEmissionFactor).country &&
                entity.energy_source === energy_source.energy_source,
            );

            tableRows.push({
              energy_source: energy_source.energy_source,
              baseYear: {
                supplierShare: energy_source.supply_share,
                ef: energy_source.ef,
              },
              targetYear: {
                supplierShare: targetYear?.supply_share,
                ef: targetYear?.ef,
              },

              adjustedValues: {
                supplierShareAdjustedValue:
                  existingEntity?.supply_share ?? null,
                efAdjustedValue: existingEntity?.ef ?? null,
              },

              country: (aggregatedData as CountryCdeEnergyEmissionFactor)
                .country,
            });
          },
        );
        return tableRows;
      }
      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown: {
        const tableRows: TableRowType[] = [];

        (
          aggregatedData as CountryCdeAverageEnergyConsumption
        ).base_year.forEach((cde_group) => {
          const targetYear = (
            aggregatedData as CountryCdeAverageEnergyConsumption
          ).target_year.find(
            (e) => e.cde_group === cde_group.cde_group,
          ) as AverageEnergyConsumption;

          const existingEntity = (
            values.payload.entities as CdeFleetBreakdownEntityDto[]
          ).find(
            (entity) =>
              entity.country ===
                (aggregatedData as CountryCdeAverageEnergyConsumption)
                  .country && entity.cde_group === cde_group.cde_group,
          );

          tableRows.push({
            cde_group: cde_group.cde_group,
            baseYear: {
              avg_energy_consumption: cde_group?.avg_energy_consumption,
              number_of_units: cde_group?.number_of_units,
            },
            targetYear: {
              avg_energy_consumption: targetYear?.avg_energy_consumption,
              number_of_units: targetYear?.number_of_units,
            },

            adjustedValues: {
              averageEnergyConsumptionAdjustedValue:
                existingEntity?.avg_energy_consumption ?? null,
              numberOfUnitsAdjustedValue:
                existingEntity?.number_of_units ?? null,
            },

            country: (aggregatedData as CountryCdeAverageEnergyConsumption)
              .country,
          });
        });
        return tableRows;
      }
      default:
        return [];
    }
  }, [adjustmentCombination, aggregatedData, values.payload.entities]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    editDisplayMode: "table",
    enableEditing: true,
    enableColumnActions: false,
    enableSorting: false,
    enablePagination: true,
    enableColumnPinning: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    state: {
      columnVisibility,
      columnPinning: {
        left: ["main", "supplier", "energy_source", "vehicle", "cde_group"],
      },
    },
    onColumnVisibilityChange:
      setColumnVisibility as OnChangeFn<VisibilityState>,
    getRowId: (row: any) => row.id,
    muiTableContainerProps: {
      sx: {
        border: "1px solid #E0E0E0",
        overflowY: "auto",
      },
    },
  });

  useEffect(() => {
    switch (adjustmentCombination) {
      case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        setColumnVisibility({
          supplier: true,
          energy_source: false,
          vehicle: false,
          fuel: false,
          fuel_display_unit: false,
          cde_group: false,
          "baseYear.supplierShare": true,
          "baseYear.usage_share": false,
          "baseYear.virginEF": true,
          "baseYear.recycledEF": true,
          "baseYear.ef": false,
          "baseYear.number_of_units": false,
          "baseYear.avg_energy_consumption": false,
          "targetYear.supplierShare": true,
          "targetYear.usage_share": false,
          "targetYear.virginEF": true,
          "targetYear.recycledEF": true,
          "targetYear.ef": false,
          "targetYear.number_of_units": false,
          "targetYear.avg_energy_consumption": false,
          "adjustedValues.supplierShareAdjustedValue": true,
          "adjustedValues.usageShareAdjustedValue": false,
          "adjustedValues.virginEFAdjustedValue": true,
          "adjustedValues.recycledEFAdjustedValue": true,
          "adjustedValues.efAdjustedValue": false,
          "adjustedValues.numberOfUnitsAdjustedValue": false,
          "adjustedValues.averageEnergyConsumptionAdjustedValue": false,
        });
        break;
      case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
      case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
      case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
        setColumnVisibility({
          supplier: [
            MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors,
            MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors,
          ].includes(adjustmentCombination)
            ? false
            : true,
          energy_source: [
            MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors,
            MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors,
          ].includes(adjustmentCombination)
            ? true
            : false,
          vehicle: false,
          fuel: false,
          fuel_display_unit: false,
          cde_group: false,
          "baseYear.supplierShare": true,
          "baseYear.usage_share": false,
          "baseYear.virginEF": false,
          "baseYear.recycledEF": false,
          "baseYear.ef": true,
          "baseYear.number_of_units": false,
          "baseYear.avg_energy_consumption": false,
          "targetYear.supplierShare": true,
          "targetYear.usage_share": false,
          "targetYear.virginEF": false,
          "targetYear.recycledEF": false,
          "targetYear.ef": true,
          "targetYear.number_of_units": false,
          "targetYear.avg_energy_consumption": false,
          "adjustedValues.supplierShareAdjustedValue": true,
          "adjustedValues.usageShareAdjustedValue": false,
          "adjustedValues.virginEFAdjustedValue": false,
          "adjustedValues.recycledEFAdjustedValue": false,
          "adjustedValues.efAdjustedValue": true,
          "adjustedValues.numberOfUnitsAdjustedValue": false,
          "adjustedValues.averageEnergyConsumptionAdjustedValue": false,
        });
        break;
      case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
      case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        setColumnVisibility({
          supplier: false,
          energy_source: false,
          vehicle: true,
          fuel:
            adjustmentCombination ===
            MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors
              ? true
              : false,
          fuel_display_unit:
            adjustmentCombination ===
            MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors
              ? true
              : false,
          cde_group: false,
          "baseYear.supplierShare": false,
          "baseYear.usage_share": true,
          "baseYear.virginEF": false,
          "baseYear.recycledEF": false,
          "baseYear.ef": true,
          "baseYear.number_of_units": false,
          "baseYear.avg_energy_consumption": false,
          "targetYear.supplierShare": false,
          "targetYear.usage_share": true,
          "targetYear.virginEF": false,
          "targetYear.recycledEF": false,
          "targetYear.ef": true,
          "targetYear.number_of_units": false,
          "targetYear.avg_energy_consumption": false,
          "adjustedValues.supplierShareAdjustedValue": false,
          "adjustedValues.usageShareAdjustedValue": true,
          "adjustedValues.virginEFAdjustedValue": false,
          "adjustedValues.recycledEFAdjustedValue": false,
          "adjustedValues.efAdjustedValue": true,
          "adjustedValues.numberOfUnitsAdjustedValue": false,
          "adjustedValues.averageEnergyConsumptionAdjustedValue": false,
        });
        break;
      case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        setColumnVisibility({
          supplier: false,
          energy_source: false,
          vehicle: false,
          cde_group: true,
          fuel: false,
          fuel_display_unit: false,
          "baseYear.supplierShare": false,
          "baseYear.usage_share": false,
          "baseYear.virginEF": false,
          "baseYear.recycledEF": false,
          "baseYear.ef": false,
          "baseYear.number_of_units": true,
          "baseYear.avg_energy_consumption": true,
          "targetYear.supplierShare": false,
          "targetYear.usage_share": false,
          "targetYear.virginEF": false,
          "targetYear.recycledEF": false,
          "targetYear.ef": false,
          "targetYear.number_of_units": true,
          "targetYear.avg_energy_consumption": true,
          "adjustedValues.supplierShareAdjustedValue": false,
          "adjustedValues.usageShareAdjustedValue": false,
          "adjustedValues.virginEFAdjustedValue": false,
          "adjustedValues.recycledEFAdjustedValue": false,
          "adjustedValues.efAdjustedValue": false,
          "adjustedValues.numberOfUnitsAdjustedValue": true,
          "adjustedValues.averageEnergyConsumptionAdjustedValue": true,
        });
        break;

      default:
        break;
    }
  }, [adjustmentCombination]);

  return <MaterialTable className="macro-accordion" table={table} />;
}

export default MacroAdjustmentStepThreeCompositeTable;
