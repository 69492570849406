import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import { useModal } from "components/common/Modal";
import ReportIssueModalContent from "./ReportIssueModalContent";
import { CustomIcon, Icons } from "components/common";

export default function ReportIssue() {
  const { t } = useTranslation("common");
  const { openModal } = useModal();

  return (
    <Tooltip title={t("reportIssue.title")}>
      <Box
        onClick={() => {
          openModal(<ReportIssueModalContent />, true);
        }}
        data-testid="report-icon-btn"
        sx={{ cursor: "pointer", display: "flex", alignItems: "center", px: 2 }}
        tabIndex={0}
      >
        <CustomIcon
          name={Icons.HELP_OUTLINE}
          width={"32px"}
          height={"28px"}
          fill={"#474747"}
        />
      </Box>
    </Tooltip>
  );
}
