import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThreeDotMenu from "components/common/ThreeDotMenu/ThreeDotMenu";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import ConfirmationModal from "components/common/ConfirmationModal/ConfirmationModal";
import { useModal } from "components/common/Modal";
import {
  CountryViewModel,
  MacroScenarioAdjustmentOverview,
} from "orval/generated/models";
import {
  getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey,
  useDeleteByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdDelete,
  useDisableMacroAdjustmentScenariosMacroScenarioIdAdjustmentsAdjustmentIdDisablePut,
  useEnableMacroAdjustmentScenariosMacroScenarioIdAdjustmentsAdjustmentIdEnablePut,
} from "orval/generated/endpoint";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { useGlobalLoader } from "components/common";
import { useNavigate } from "react-router-dom";
import { MacroToolRoutesConfig } from "features/macro-tool/navigation";
import { AxiosError } from "axios";

export type MacroAdjustmentRowProps = {
  adjustmentDetails: MacroScenarioAdjustmentOverview;
  scenarioCountries: CountryViewModel[];
  scenarioId: number;
  isLastRow: boolean;
  changeOrderIsPending: boolean;
  changeOrderIsEnabled: boolean;
  handleChangeOrder: (move: "up" | "down", adjustmentId: number) => void;
};

const MacroAdjustmentRow = ({
  adjustmentDetails,
  scenarioCountries,
  scenarioId,
  isLastRow,
  changeOrderIsPending,
  changeOrderIsEnabled,
  handleChangeOrder,
}: MacroAdjustmentRowProps) => {
  const { t } = useTranslation(["macro", "common"]);
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const { showGlobalLoader } = useGlobalLoader();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const { mutateAsync: deleteMacroAdjustment } =
    useDeleteByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdDelete({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey:
              getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
                scenarioId,
              ),
          });

          showGlobalLoader(false);

          showSnackbar(
            t("macro:adjustmentsPage.confirmationModal.deletedAdjustment"),
            "success",
          );
        },
        onError: (error: AxiosError) => {
          showSnackbar(
            t("macro:adjustmentsPage.confirmationModal.failedToDelete"),
            "error",
          );
          console.warn(error);
          return error;
        },
      },
    });

  const {
    mutateAsync: enableAdjustment,
    isPending: isEnableAdjustmentPending,
  } =
    useEnableMacroAdjustmentScenariosMacroScenarioIdAdjustmentsAdjustmentIdEnablePut(
      {
        mutation: {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey:
                getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
                  scenarioId,
                ),
            });

            showGlobalLoader(false);

            showSnackbar(
              t("macro:adjustmentsPage.enableAdjustmentSuccess"),
              "success",
            );
          },
          onError: (error: AxiosError) => {
            showSnackbar(t("macro:adjustmentsPage.failedToEnable"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const {
    mutateAsync: disableAdjustment,
    isPending: isDisableAdjustmentPending,
  } =
    useDisableMacroAdjustmentScenariosMacroScenarioIdAdjustmentsAdjustmentIdDisablePut(
      {
        mutation: {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey:
                getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
                  scenarioId,
                ),
            });

            showGlobalLoader(false);

            showSnackbar(
              t("macro:adjustmentsPage.disableAdjustmentSuccess"),
              "success",
            );
          },
          onError: (error: AxiosError) => {
            showSnackbar(t("macro:adjustmentsPage.failedToDisable"), "error");
            console.warn(error);
            return error;
          },
        },
      },
    );

  const handleToggle = useCallback(async () => {
    try {
      if (!adjustmentDetails.is_enabled) {
        await enableAdjustment({
          scenarioId: scenarioId,
          adjustmentId: adjustmentDetails.id,
        });
      } else {
        await disableAdjustment({
          scenarioId: scenarioId,
          adjustmentId: adjustmentDetails.id,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [adjustmentDetails, disableAdjustment, enableAdjustment, scenarioId]);

  const handleConfirmDeletion = useCallback(async () => {
    try {
      await deleteMacroAdjustment({
        scenarioId: scenarioId,
        adjustmentId: adjustmentDetails.id,
      });
    } catch (e) {
      console.error(e);
    }

    closeModal();
  }, [adjustmentDetails.id, closeModal, deleteMacroAdjustment, scenarioId]);

  const threeDotsMenuItems = useMemo(() => {
    return [
      {
        label: t("common:actions.edit"),
        onClick: () => {
          navigate(
            MacroToolRoutesConfig.editAdjustment
              .replace(":id", String(scenarioId))
              .replace(":adjustmentId", String(adjustmentDetails.id)),
          );
        },
        disabled: false,
      },
      {
        label: t("common:actions.delete"),
        onClick: () => {
          openModal(
            <ConfirmationModal
              title={t("macro:adjustmentsPage.confirmationModal.title")}
              description={t(
                "macro:adjustmentsPage.confirmationModal.description",
              )}
              actionTitle={t(
                "macro:adjustmentsPage.confirmationModal.actionTitle",
              )}
              confirmAction={handleConfirmDeletion}
              cancelAction={() => {
                closeModal();
              }}
            />,
          );
        },
      },
    ];
  }, [
    adjustmentDetails.id,
    closeModal,
    handleConfirmDeletion,
    navigate,
    openModal,
    scenarioId,
    t,
  ]);

  const countriesMap = useMemo(() => {
    const hashMap: { [key: string]: string } = {};

    scenarioCountries.forEach(({ country, display_name }) => {
      if (!hashMap[country]) {
        hashMap[country] = display_name;
      }
    });

    return hashMap;
  }, [scenarioCountries]);

  const countriesChipLabel = useMemo(() => {
    const { countries } = adjustmentDetails;
    return countries.length === 1
      ? countriesMap[countries[0]]
      : `${countries.length} ${t("macro:adjustmentsPage.countries")}`;
  }, [adjustmentDetails, countriesMap, t]);

  const countriesTooltipTitle = useMemo(() => {
    const { countries } = adjustmentDetails;
    if (countries.length === 1) {
      return countriesMap[countries[0]];
    }
    return countries.length > 10 ? (
      countries.join(", ")
    ) : (
      <ul style={{ margin: 0, padding: 0 }}>
        {countries.sort().map((country, index) => (
          <li key={index}>{countriesMap[country]}</li>
        ))}
      </ul>
    );
  }, [adjustmentDetails, countriesMap]);

  return (
    <Box
      sx={{
        border: `1px solid #CBCBCB`,
        borderRadius: "8px",
        maxWidth: "100%",
        padding: "20px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      data-testid="adjustment-row"
    >
      <Box display="flex" alignItems="center">
        <Box
          pr={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {adjustmentDetails.position !== 1 && changeOrderIsEnabled && (
            <Tooltip title={t("macro:adjustmentsPage.moveUp")}>
              <IconButton
                disabled={changeOrderIsPending}
                onClick={() => handleChangeOrder("up", adjustmentDetails.id)}
              >
                <ExpandLessIcon />
              </IconButton>
            </Tooltip>
          )}

          <Box display="flex" alignItems="center">
            <Switch
              checked={adjustmentDetails.is_enabled}
              onChange={() => handleToggle()}
              disabled={isEnableAdjustmentPending || isDisableAdjustmentPending}
            />
            {(isEnableAdjustmentPending || isDisableAdjustmentPending) && (
              <CircularProgress size={10} sx={{ ml: 1 }} />
            )}
          </Box>

          {!isLastRow && changeOrderIsEnabled && (
            <Tooltip title={t("macro:adjustmentsPage.moveDown")}>
              <IconButton
                disabled={changeOrderIsPending}
                onClick={() => handleChangeOrder("down", adjustmentDetails.id)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          pr="30px"
        >
          <Box>
            <Typography mb={1} variant={"h3"}>
              {adjustmentDetails.name}
            </Typography>
            <Typography variant={"body2"}>
              {`${adjustmentDetails.pillar} - ${adjustmentDetails.input_area}`}
            </Typography>

            {adjustmentDetails.description && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                {`${adjustmentDetails.description}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Tooltip title={countriesTooltipTitle}>
          <Chip
            variant="filled"
            label={countriesChipLabel}
            sx={{
              backgroundColor: `${palette.textColor.light} !important`,
              fontWeight: "500 !important",
              color: `${palette.textColor.dark} !important`,
              border: "0px",
              borderRadius: "4px !important",
            }}
          />
        </Tooltip>
        <ThreeDotMenu menuItems={threeDotsMenuItems} />
      </Box>
    </Box>
  );
};
export default MacroAdjustmentRow;
