import {
  Box,
  Button,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CustomIcon, Icons, NoData, SearchBar } from "components/common";
import SelectDropdown from "../../../../components/common/SelectDropdown/SelectDropdown";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MacroAdjustmentRow from "../MacroAdjustmentRow/MacroAdjustmentRow";
import {
  CountryViewModel,
  MacroScenarioAdjustmentOverview,
  ViewMacroScenarioDto,
} from "orval/generated/models";
import { deepClone } from "utils";
import { debounce } from "lodash";
import {
  getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey,
  useGetAllScenariosMacroScenarioIdAdjustmentsGet,
  usePrioritizeMacroAdjustmentScenariosMacroScenarioIdAdjustmentsOrderPut,
} from "orval/generated/endpoint";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import LoadingList from "components/common/LoadingList/LoadingList";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

export type MacroAdjustmentsPageTableProps = {
  scenario: ViewMacroScenarioDto;
};

const MacroAdjustmentsPageTable = ({
  scenario,
}: MacroAdjustmentsPageTableProps) => {
  const { t } = useTranslation("macro");
  const showSnackbar = useSnackbar();
  const { palette } = useTheme();
  const queryClient = useQueryClient();

  const {
    data: adjustments,
    isPending: isFetchingAdjustments,
    isError: errorFetchingAdjustments,
  } = useGetAllScenariosMacroScenarioIdAdjustmentsGet(scenario.id as number);

  const { mutateAsync: changeOrder, isPending: changeOrderIsPending } =
    usePrioritizeMacroAdjustmentScenariosMacroScenarioIdAdjustmentsOrderPut({
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetAllScenariosMacroScenarioIdAdjustmentsGetQueryKey(
              scenario.id,
            ),
          });

          setChangeOrderIsEnabled(false);
          showSnackbar(
            t("macro:adjustmentsPage.changeOrderSuccess"),
            "success",
          );
        },
        onError: (error: AxiosError) => {
          showSnackbar(t("macro:adjustmentsPage.failedToChangeOrder"), "error");
          console.warn(error);
          return error;
        },
      },
    });

  const [sortedData, setSortedData] = useState<
    MacroScenarioAdjustmentOverview[]
  >([]);

  const [changeOrderIsEnabled, setChangeOrderIsEnabled] =
    useState<boolean>(false);

  const handleChangeOrder = useCallback(
    (move: "up" | "down", adjustmentId: number) => {
      const targetAdjustment = sortedData.find(
        (adjustment) => adjustment.id === adjustmentId,
      ) as MacroScenarioAdjustmentOverview;

      const targetAdjustmentNewPostion =
        move === "up"
          ? targetAdjustment.position - 1
          : targetAdjustment.position + 1;

      setSortedData((prev) =>
        prev
          ?.map((adjustment) => ({
            ...adjustment,
            position: (adjustment.id === adjustmentId
              ? targetAdjustmentNewPostion
              : adjustment.position === targetAdjustmentNewPostion
                ? targetAdjustment.position
                : adjustment.position) as number,
          }))
          .sort(
            (
              a: MacroScenarioAdjustmentOverview,
              b: MacroScenarioAdjustmentOverview,
            ) => a.position - b.position,
          ),
      );
    },
    [sortedData],
  );

  const handleChangeOrderSave = useCallback(async () => {
    try {
      await changeOrder({
        scenarioId: scenario.id,
        data: sortedData.map(({ position, id }) => ({
          adjustment_id: id,
          position,
        })),
      });
    } catch (e) {
      console.log(e);
    }
  }, [changeOrder, scenario.id, sortedData]);

  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchValue(value);
      }, 300),
    [],
  );

  const handleCountriesTypeChange = useCallback(
    (newSelectedCountries: string[]) => {
      setSelectedCountries(newSelectedCountries);
    },
    [],
  );

  useEffect(() => {
    if (adjustments) {
      if (adjustments.length) {
        let sortedItems: MacroScenarioAdjustmentOverview[] = [];
        //search
        const words = searchValue
          .replace(",", " ")
          .trim()
          .toLocaleLowerCase()
          .split(" ");
        const _adjustments: MacroScenarioAdjustmentOverview[] = deepClone(
          adjustments,
        ).sort(
          (
            a: MacroScenarioAdjustmentOverview,
            b: MacroScenarioAdjustmentOverview,
          ) => a.position - b.position,
        );

        if (words.length) {
          _adjustments.forEach((adjustment) => {
            words.forEach((word) => {
              if (
                `${adjustment.name}${adjustment.pillar}${adjustment.input_area}`
                  .toLocaleLowerCase()
                  .includes(word)
              )
                if (
                  !sortedItems.find(
                    (_adjustment) => _adjustment.id === adjustment.id,
                  )
                ) {
                  sortedItems.push(adjustment);
                }
            });
          });
        } else {
          sortedItems = _adjustments;
        }

        //filters
        if (selectedCountries.length) {
          sortedItems = sortedItems.filter((adjustment) =>
            adjustment.countries.some((country: string) =>
              selectedCountries.includes(
                scenario.inputs?.countries?.find(
                  (_country) => _country.country === country,
                )?.display_name as string,
              ),
            ),
          );
        }

        setSortedData(sortedItems);
      } else {
        setSortedData([]);
      }
    }
  }, [adjustments, scenario.inputs?.countries, searchValue, selectedCountries]);

  useEffect(() => {
    if (errorFetchingAdjustments) {
      showSnackbar(t("adjustmentsPage.failedToFetchAdjustments"), "error");
    }
  }, [errorFetchingAdjustments, showSnackbar, t]);

  const enabledAdjustmentsCount = useMemo(
    () => adjustments?.filter((adjustment) => adjustment.is_enabled).length,
    [adjustments],
  );

  return (
    <Box>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid size={{ xs: 12, lg: 7 }}>
          <SearchBar
            handleSearch={debouncedHandleSearch}
            disabled={!adjustments?.length}
          />
        </Grid>

        <Grid
          size={{ xs: 4, lg: 2 }}
          mt={"4px"}
          data-testid="coutries-dropdown"
        >
          {scenario?.inputs?.countries && (
            <SelectDropdown
              listItems={scenario.inputs?.countries
                ?.filter((country) => {
                  return (
                    adjustments &&
                    adjustments.some((adjustment) =>
                      adjustment.countries.includes(country.country),
                    )
                  );
                })
                .map((country) => country.display_name)}
              savedSelectedItems={selectedCountries}
              onSave={handleCountriesTypeChange}
              title={t("adjustmentsPage.countriesDropdownTitle")}
              compact
              showSearchBar={false}
              selectAll={true}
              disabled={!adjustments?.length}
            ></SelectDropdown>
          )}
        </Grid>
      </Grid>
      {isFetchingAdjustments ? (
        <LoadingList />
      ) : !adjustments?.length ? (
        <Box mt={2}>
          <NoData
            label={t("adjustmentsPage.noAdjustmentsAvailableLabel")}
            label2={t("adjustmentsPage.noAdjustmentsAvailableDescription")}
          />
        </Box>
      ) : !sortedData?.length ? (
        <Box mt={2}>
          <NoData label={t("adjustmentsPage.noAdjustmentsMatch")} />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            py={1}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h3" sx={{ color: palette.primary.main }}>
                {enabledAdjustmentsCount}/{adjustments.length}{" "}
                {t("macro:adjustmentsPage.enabled")}
              </Typography>
              <Tooltip
                title={`${t("macro:adjustmentsPage.enabledAdjustmentsToolTipPartOne")} ${enabledAdjustmentsCount} ${t("macro:adjustmentsPage.enabledAdjustmentsToolTipPartTwo")} ${scenario.inputs?.years?.target_year} ${t("macro:adjustmentsPage.enabledAdjustmentsToolTipPartThree")}`}
              >
                <IconButton>
                  <CustomIcon
                    name={Icons.INFORMATION}
                    fill={palette.primary.main}
                    width={25}
                    height={25}
                  />
                </IconButton>
              </Tooltip>
            </Box>

            {adjustments.length > 1 && (
              <Button
                variant={changeOrderIsEnabled ? "contained" : "outlined"}
                disabled={changeOrderIsPending}
                onClick={
                  changeOrderIsEnabled
                    ? handleChangeOrderSave
                    : () => {
                        setSearchValue("");
                        setSelectedCountries([]);
                        setChangeOrderIsEnabled(true);
                      }
                }
              >
                {changeOrderIsEnabled
                  ? t("macro:adjustmentsPage.saveOrder")
                  : t("macro:adjustmentsPage.changeOrder")}
                {changeOrderIsPending && (
                  <CircularProgress size={10} sx={{ ml: 1 }} />
                )}
              </Button>
            )}
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            mb={2}
          >
            {sortedData.map((adjustment) => (
              <Grid maxWidth="100% !important" size={12} key={adjustment.id}>
                <MacroAdjustmentRow
                  adjustmentDetails={adjustment}
                  scenarioId={scenario.id as number}
                  scenarioCountries={
                    scenario.inputs?.countries as CountryViewModel[]
                  }
                  isLastRow={adjustment.position === sortedData.length}
                  changeOrderIsPending={changeOrderIsPending}
                  changeOrderIsEnabled={changeOrderIsEnabled}
                  handleChangeOrder={handleChangeOrder}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};
export default MacroAdjustmentsPageTable;
