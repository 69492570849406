import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CustomIcon } from "../../common";
import { useTranslation } from "react-i18next";
import { usePermissions } from "core/navigation/usePermissions";
import CustomNavigationMenuLink from "./CustomNavigationMenuLink/CustomNavigationMenuLink";
import { NavigationMenuOptionType } from "./NavigationMenuConstants";

export type NavigationMenuOptionProps = {
  option: NavigationMenuOptionType;
};

const NavigationMenuOption = ({ option }: NavigationMenuOptionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const { useHasPermission } = usePermissions();

  const hasPermission = useHasPermission(option.permission);

  return (
    <>
      {hasPermission || !option.permission ? (
        <Tooltip
          key={option.title}
          data-testid={`${option.title.replace("navigationMenu.options.", "")}-navigationMenu-item`}
          title={isLg ? null : t(option.title)}
        >
          <Box key={option.title}>
            <CustomNavigationMenuLink to={option.redirectURL}>
              <Box
                key={option.title}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CustomIcon name={option.icon} width={"32px"} height={"28px"} />
                <Typography
                  sx={{
                    display: { xs: "none", lg: "block" },
                    pr: "5px",
                    fontSize: { xs: ".9rem", lg: ".95rem", xl: "1rem" },
                  }}
                >
                  {t(option.title)}
                </Typography>
              </Box>
            </CustomNavigationMenuLink>
          </Box>
        </Tooltip>
      ) : null}
    </>
  );
};

export default NavigationMenuOption;
