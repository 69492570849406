import {
  LinkProps,
  NavLink,
  To,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { Box } from "@mui/material";

export default function CustomNavigationMenuLink({
  children,
  to,
  ...props
}: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  const className = match ? "active" : "";
  const navigate = useNavigate();
  const handleClick = (route: To) => {
    navigate(route);
  };

  return (
    <Box
      onClick={() => handleClick(to)}
      className={`${className} navigation-menu-item`}
      sx={{
        "&.MuiBox-root .MuiTypography-root": {
          color: match ? "#d04a02" : "#474747",
        },
        px: { xs: "15px", lg: "7px", xl: "15px" },
      }}
    >
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    </Box>
  );
}
