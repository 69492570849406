import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentStep,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import { CustomIcon, Icons } from "components/common";

export type MacroAdjustmentsWizardHeaderAndStepperProps = {
  handleNavigateToAdjustmentsPage: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => void;
  formikProps: FormikProps<MacroAdjustmentWizardFormikValues>;
  headersIndent: string;
  activeStep: number;
  setActiveStep: Function;
  adjustmentCombination: MacroAdjustmentCombinations | undefined;
  steps: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => MacroAdjustmentStep[];
};

const MacroAdjustmentsWizardHeaderAndStepper = ({
  handleNavigateToAdjustmentsPage,
  formikProps,
  headersIndent,
  activeStep,
  setActiveStep,
  adjustmentCombination,
  steps,
}: MacroAdjustmentsWizardHeaderAndStepperProps) => {
  const { t } = useTranslation("macro");
  const { palette } = useTheme();
  const { values } = formikProps;

  const handleStepClick = useCallback(
    (index: number) => {
      const canProceedToStep2 =
        adjustmentCombination &&
        values.selectedPillar &&
        values.selectedInputArea &&
        values.selectedAdjustmentType &&
        values.payload.name?.length >= 3;

      const canProceedToStep3 =
        values.selectedEntities.length &&
        ((adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment &&
          values.payload.filters.brands?.length) ||
          (adjustmentCombination ===
            MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment &&
            values.payload.filters.countries?.length) ||
          ([
            MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment,
            MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment,
            MacroAdjustmentCombinations.Packaging_RecycledContent,
            MacroAdjustmentCombinations.Packaging_NetLossRate,
            MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors,
            MacroAdjustmentCombinations.Packaging_RecoveryRate,
          ].includes(adjustmentCombination as MacroAdjustmentCombinations) &&
            values.selectedProducts?.length));
      if (
        (index === 1 && canProceedToStep2) ||
        (index === 2 && canProceedToStep3) ||
        index === 0
      ) {
        setActiveStep(index);
      }
    },
    [adjustmentCombination, setActiveStep, values],
  );

  return (
    <Box>
      <Box tabIndex={0}>
        <Button
          variant="text"
          onClick={() => handleNavigateToAdjustmentsPage(formikProps)}
          startIcon={
            <CustomIcon
              name={Icons.CHEVRON_LEFT}
              width={24}
              height={24}
              fill={palette.primary.main}
            />
          }
        >
          {t("macro:adjustmentsPage.wizard.backToAdjustmentsPage")}
        </Button>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justify-content={"center"}
        mb={2}
      >
        {steps(formikProps)[activeStep].stepTitle && (
          <Box tabIndex={0}>
            <Typography
              className="text-ellipsis"
              variant={"h1"}
              sx={{
                paddingX: headersIndent,
                transition: "padding 0.3s ease-in-out",
              }}
            >
              {steps(formikProps)[activeStep].stepTitle}
            </Typography>
          </Box>
        )}

        {steps(formikProps)[activeStep].stepSubtitle && (
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <FiberManualRecordIcon
                sx={{
                  m: 1,
                  fontSize: "5px",
                  color: "grey",
                }}
              />
            </Box>
            <Box>
              <Typography
                className="text-ellipsis"
                variant={"h2"}
                sx={{
                  paddingX: headersIndent,
                  transition: "padding 0.3s ease-in-out",
                }}
              >
                {steps(formikProps)[activeStep].stepSubtitle}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box mb={4}>
        <Stepper activeStep={activeStep}>
          {Array.isArray(steps(formikProps)) &&
            steps(formikProps).map((step) => (
              <Step key={step.index}>
                <StepLabel
                  sx={{ cursor: "pointer !important" }}
                  onClick={() => handleStepClick(step.index)}
                  tabIndex={activeStep === step.index ? 0 : -1}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default MacroAdjustmentsWizardHeaderAndStepper;
