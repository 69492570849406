import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "./MicroViewAllScenariosPage.scss";
import { useNavigate } from "react-router-dom";
import { NoData, SearchBar } from "components/common";
import { SortConfig, SortValues } from "utils/SortConfig";
import Sort from "../../../../components/common/Sort/Sort";
import {
  ListMicroScenarioDto,
  ViewMicroScenarioDto,
} from "orval/generated/models";
import { AppConstant } from "constants/AppConstant";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { AxiosError } from "axios";
import {
  getGetAllMicroScenariosScenariosMicroGetQueryKey,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useCreateMicroScenarioScenariosMicroPost,
  useGetAllMicroScenariosScenariosMicroGet,
} from "orval/generated/endpoint";
import { STATUS_CODES } from "utils/HTTPUtils/constants";
import LoadingList from "components/common/LoadingList/LoadingList";
import MicroSingleScenario from "./MicroSingleScenario";
import useDocumentHead from "utils/useDocumentHead";

function MicroViewAllScenariosPage() {
  const { t } = useTranslation(["micro", "common"]);
  useDocumentHead({
    titleAsTranslationKey: t("common:documentHeads:microViewAllScenarios"),
  });
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [filteredScenarios, setFilteredScenarios] = useState<
    ListMicroScenarioDto[]
  >([]);
  const defaultSortValue =
    parseInt(
      localStorage.getItem(AppConstant.localStorageKeys.sortValue) || "0",
    ) || SortValues.DATE_UPDATED_DESCENDING;
  const [sortValue, setSortValue] = useState<SortValues>(defaultSortValue);
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();

  const { data: microScenariosData, isPending: microScenarioDataIsPending } =
    useGetAllMicroScenariosScenariosMicroGet();

  useEffect(() => {
    if (!microScenarioDataIsPending) {
      const lowerCaseSearchString = searchString.toLowerCase();
      const _scenarios = microScenariosData || [];
      if (Array.isArray(_scenarios)) {
        const filtered = _scenarios.filter(
          (_scenario) =>
            _scenario.name.toLowerCase().includes(lowerCaseSearchString) ||
            (_scenario.description?.toLowerCase() ?? "").includes(
              lowerCaseSearchString,
            ),
        );

        const sortedData = [...filtered].sort((a, b) => {
          switch (sortValue as number) {
            case SortValues.DATE_UPDATED_ASCENDING:
              return (
                new Date(a.updated_at || "").getTime() -
                new Date(b.updated_at || "").getTime()
              );
            case SortValues.DATE_UPDATED_DESCENDING:
              return (
                new Date(b.updated_at || "").getTime() -
                new Date(a.updated_at || "").getTime()
              );
            case SortValues.NAME_ASCENDING:
              return a.name.localeCompare(b.name);
            case SortValues.NAME_DESCENDING:
              return b.name.localeCompare(a.name);
            default:
              return 0;
          }
        });
        setFilteredScenarios(sortedData);
      }
    }
  }, [searchString, microScenariosData, sortValue, microScenarioDataIsPending]);

  useEffect(() => {
    localStorage.setItem(
      AppConstant.localStorageKeys.sortValue,
      sortValue.toString(),
    );
  }, [sortValue]);

  function handleSearch(value: string): void {
    setSearchString(value);
  }

  const { mutate: createMicroScenario, isPending: createMicroIsPending } =
    useCreateMicroScenarioScenariosMicroPost({
      mutation: {
        onSuccess: (microScenario: ViewMicroScenarioDto) => {
          const queryKey =
            getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
              microScenario.id,
            );
          queryClient.setQueryData(queryKey, microScenario);

          navigate(`/product-level-analytics/scenario/${microScenario.id}`, {
            state: { firstVisit: true },
          });
          queryClient.setQueryData(
            getGetAllMicroScenariosScenariosMicroGetQueryKey(),
            undefined,
          );
        },
        onError: (createMicroError: AxiosError) => {
          if (createMicroError.status !== STATUS_CODES.UNAVAILABLE)
            showSnackbar("micro:errorMessages.createScenario", "error");
        },
      },
    });

  return (
    <Box
      sx={{
        position: "sticky",
        top: 64,
        backgroundColor: "white",
        zIndex: 1,
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography fontWeight="500" tabIndex={0} variant={"h1"}>
            {t("micro:microViewAllScenariosPage.title")}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              createMicroScenario();
            }}
            disabled={createMicroIsPending}
          >
            {t("micro:microViewAllScenariosPage.labels.createNewScenarioBtn")}
            {createMicroIsPending ? (
              <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
            ) : null}
          </Button>
        </Box>
      </Box>

      <Box mb={4} mt={2}>
        <Typography tabIndex={0} variant={"body1"}>
          {t("description")}
        </Typography>
      </Box>

      {microScenariosData && microScenariosData?.length > 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <SearchBar
            handleSearch={handleSearch}
            searchBarAriaLabel={`${t("common:a11y.searchMicroScenarios")}`}
          />
          <Sort
            sortValue={sortValue}
            setSortValue={setSortValue}
            values={SortConfig}
          />
        </Box>
      ) : null}

      <Box mb={4}>
        {microScenarioDataIsPending ? (
          <LoadingList />
        ) : !microScenariosData?.length ? (
          <Box mt={2}>
            <NoData
              label={t("common:noData.emptyStateMessage1")}
              label2={t("common:noData.emptyStateMessage2")}
            />
          </Box>
        ) : !filteredScenarios.length ? (
          <NoData label={t("common:noData.noSearchMatchMessage")} />
        ) : (
          filteredScenarios.map((scenario: ListMicroScenarioDto) => (
            <MicroSingleScenario key={scenario.id} scenario={scenario} />
          ))
        )}
      </Box>
    </Box>
  );
}

export default MicroViewAllScenariosPage;
