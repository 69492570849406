import { YearsBaseYear } from "orval/generated/models";

export enum TableResultsType {
  BY_PACK_TYPE = "by_pack_type",
  BY_CATEGORY = "by_category",
  BY_PILLAR = "by_pillar",
}

export enum TableResultsFilterDataType {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
}

export enum TableResultsFilterDataAccessorKey {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
}

export interface CombinedDataItem {
  category: string;
  base?: YearsBaseYear | string | number;
  glide?: string | number;
  scenario?: string | number;
  ingredients: string;
  packaging: string;
  manufacturing: string;
  logistics: string;
  cde: string;
  total: string;
}

export enum MacroResultsAccordionEnum {
  Overview = "overview",
  OverviewByCategories = "overview_categories",
  OverviewByPackType = "overview_pack_type",
  OverviewByPillar = "overview_pillar",
  PillarDeepDive = "pillar_deep_dive",
  PillarDeepDiveByCategories = "pillar_deep_dive_categories",
  PillarDeepDiveByPackType = "pillar_deep_dive_pack_type",
  EmissionsHeatMap = "emissions_heat_map",
  TimeEvolution = "time_evolution",
  TimeEvolutionByCategory = "time_evolution_category",
  TimeEvolutionByPackType = "time_evolution_pack_type",
  Waterfall = "waterfall",
  WaterfallByCategory = "waterfall_category",
  WaterfallByPackType = "waterfall_pack_type",
  DataVisualisations = "data_visualisations",
  Results = "results",
}
