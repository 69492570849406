import { Box, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "./Footer.scss";
import "../../sections/RootPage/RootPage.scss";
import { useTermsContext } from "features/terms-and-conditions/components/TermsContext";
import { AppConstant } from "constants/AppConstant";

type FooterProps = {
  applicationVersion: string | undefined;
  masterDatasetVersion: string | undefined;
};

const Footer = ({ applicationVersion, masterDatasetVersion }: FooterProps) => {
  const { isTermsAccepted } = useTermsContext();
  const [appVersion, setAppVersion] = useState<string | undefined>(
    () =>
      localStorage.getItem(AppConstant.localStorageKeys.appVersion) ||
      applicationVersion,
  );
  const [masterDataVersion, setMasterDataVersion] = useState<
    string | undefined
  >(() => {
    const storedVersion = localStorage.getItem(
      AppConstant.localStorageKeys.masterDataVersion,
    );
    return storedVersion ? storedVersion : masterDatasetVersion;
  });

  const footerLinks = [
    {
      href: "https://www.pwc.co.uk/who-we-are/legal-disclaimer.html",
      labelTranslationKey: "footer.legalDisclaimer",
    },
    {
      href: "/legal/terms-of-use",
      labelTranslationKey: "footer.terms",
    },
    {
      href: "/legal/privacy-statement",
      labelTranslationKey: "footer.privacy",
    },
    {
      href: "/legal/cookie-statement",
      labelTranslationKey: "footer.cookies",
    },
  ];

  const { t } = useTranslation("common");

  useEffect((): void => {
    if (isTermsAccepted) {
      if (appVersion !== applicationVersion) {
        localStorage.setItem(
          AppConstant.localStorageKeys.appVersion,
          applicationVersion ?? "",
        );
        setAppVersion(applicationVersion);
      }
      if (masterDataVersion !== (masterDatasetVersion ?? 0)) {
        localStorage.setItem(
          AppConstant.localStorageKeys.masterDataVersion,
          (masterDatasetVersion ?? 0).toString(),
        );
        setMasterDataVersion(masterDatasetVersion);
      }
    }
  }, [
    isTermsAccepted,
    applicationVersion,
    masterDatasetVersion,
    appVersion,
    masterDataVersion,
  ]);

  const current_year = new Date().getFullYear();

  return (
    <Box className="footer" tabIndex={0} aria-label={t("common:a11y.footer")}>
      <Box display={"flex"} justifyContent="space-between">
        <Box display={"flex"} flexDirection="column" width="auto">
          <Box display={"flex"} mb={1}>
            {footerLinks.map((link, i) => (
              <Box
                key={link.labelTranslationKey}
                sx={
                  i === footerLinks.length - 1
                    ? {}
                    : { borderRight: "1px solid #cbcbcb" }
                }
                pl={i === 0 ? 0 : 1}
                pr={1}
                alignItems="flex-start"
              >
                <Link href={link.href} underline="none" className="footer-text">
                  {t(link.labelTranslationKey)}
                </Link>
              </Box>
            ))}
          </Box>

          <Box tabIndex={0}>
            <Typography className={"footer-text"}>
              © {current_year} PwC. All rights reserved. PwC refers to the PwC
              network and/or one or more of its member firms, each of which is a
              separate legal entity. Please see{" "}
              <Link href="https://pwc.com/structure">
                how we are structured
              </Link>{" "}
              for further details.
            </Typography>
          </Box>
        </Box>

        <Box
          data-testid={"app-version-area"}
          tabIndex={0}
          aria-label={t("common:a11y.appVersion")}
        >
          {appVersion && masterDataVersion ? (
            <Typography className="footer-text version">{`${t("footer.mdVersion")}${masterDataVersion} · ${t("footer.toolVersion")}${appVersion}`}</Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
