import { useTranslation } from "react-i18next";
import { Typography, Box, FormControl, Select, MenuItem } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

export type SortProps<T> = {
  sortValue: T;
  setSortValue: Dispatch<SetStateAction<T>>;
  values: { key: string; value: T }[];
  disabled?: boolean;
};

const Sort = <T,>({
  sortValue,
  setSortValue,
  values,
  disabled,
}: SortProps<T>) => {
  const { t } = useTranslation(["macro", "micro", "common"]);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"center"}
      minWidth={"max-content"}
      px={2}
    >
      <Box>
        <Typography>{t("common:sorting.sort")}</Typography>
      </Box>
      <FormControl
        variant="standard"
        sx={{ marginLeft: "5px", borderBottom: "none" }}
      >
        <Select
          variant="standard"
          sx={{
            color: "#D04A02",
            marginTop: "2px",
          }}
          labelId="sort"
          id="sort"
          value={sortValue}
          disableUnderline={true}
          onChange={(e) => {
            const selectedValue = values[e.target.value as number];
            if (selectedValue !== undefined) {
              setSortValue(selectedValue.value);
            }
          }}
          inputProps={{
            MenuProps: { disableScrollLock: true },
            "aria-label": t("common:a11y.sortBy"),
          }}
          data-testid="sort-dropdown"
          disabled={disabled}
          tabIndex={0}
        >
          {values.map((data) => (
            <MenuItem
              key={data.key}
              value={data.value as string}
              data-testid={`sort-item-${data.key}`}
            >
              {t(data.key)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Sort;
