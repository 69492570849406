import { Box, Button, Typography } from "@mui/material";
import "../../../assets/styles/base/heading.scss";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import { IconName, Icons } from "components/common/CustomIcon/types";
import { useTranslation } from "react-i18next";
import LoadingList from "components/common/LoadingList/LoadingList";
import {
  ListMacroScenarioDto,
  ListMicroScenarioDto,
} from "orval/generated/models";
import { NoData } from "components/common";
import MicroSingleScenario from "features/micro-tool/routes/MicroViewAllScenariosPage/MicroSingleScenario";
import MacroSingleScenarioInScenarioList from "features/macro-tool/components/MacroScenarioList/MacroSingleScenarioInScenarioList/MacroSingleScenarioInScenarioList";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import {
  getGetAllMicroScenariosScenariosMicroGetQueryKey,
  getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey,
  useCreateMacroScenarioScenariosMacroPost,
  useCreateMicroScenarioScenariosMicroPost,
} from "orval/generated/endpoint";
import { AxiosError } from "axios";
import { STATUS_CODES } from "utils/HTTPUtils/constants";
import { useNavigate } from "react-router-dom";

export type LatestScenariosSectionProps = {
  icon: IconName;
  title: string;
  description: string;
  isPending: boolean;
  scenariosList: ListMicroScenarioDto[] | ListMacroScenarioDto[] | undefined;
  openButtonURL: string;
  scenarioType: "micro" | "macro";
};

export function LatestScenariosSection({
  icon,
  title,
  description,
  scenarioType,
  isPending,
  scenariosList,
  openButtonURL,
}: LatestScenariosSectionProps) {
  const { t } = useTranslation(["home", "common", "micro"]);
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  const { mutate: createMicroScenario } =
    useCreateMicroScenarioScenariosMicroPost({
      mutation: {
        onSuccess: (microScenario) => {
          const queryKey =
            getGetMicroScenarioByIdScenariosMicroScenarioIdGetQueryKey(
              microScenario.id,
            );
          queryClient.setQueryData(queryKey, microScenario);

          navigate(`/product-level-analytics/scenario/${microScenario.id}`, {
            state: { firstVisit: true },
          });
          queryClient.setQueryData(
            getGetAllMicroScenariosScenariosMicroGetQueryKey(),
            undefined,
          );
        },
        onError: (createMicroError: AxiosError) => {
          if (createMicroError.status !== STATUS_CODES.UNAVAILABLE)
            showSnackbar("micro:errorMessages.createScenario", "error");
        },
      },
    });

  const { mutate: createMacroScenario } =
    useCreateMacroScenarioScenariosMacroPost({
      mutation: {
        onSuccess: (macroScenario) => {
          navigate(`/country-level-analytics/scenario/${macroScenario.id}`, {
            state: { id: macroScenario.id },
          });
        },
        onError: (createMacroError: AxiosError) => {
          if (createMacroError.status !== STATUS_CODES.UNAVAILABLE)
            showSnackbar("micro:errorMessages.createScenario", "error");
        },
      },
    });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomIcon
            name={icon}
            width={"50px"}
            height={"45px"}
            fill={"#d04a02"}
          />
          <Typography variant={"h1"} sx={{ marginLeft: 1 }}>
            {title}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => navigate(openButtonURL)}
          aria-label={`${t("common:actions.open")} ${title}`}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ color: "#d04a02" }}>
              {t("common:actions.open")}
            </Typography>
            <CustomIcon
              name={Icons.ARROW_RIGHT}
              width={"30px"}
              fill={"#d04a02"}
            />
          </Box>
        </Button>
      </Box>
      <Typography my={2} height={50}>
        {description}
      </Typography>

      <Box my={2}>
        {isPending ? (
          <LoadingList length={3} />
        ) : !scenariosList?.length ? (
          <NoData
            label={t("home:noScenariosMessage")}
            label2={t("home:getStartedMessage")}
            actionButtonLabel={"home:createScenario"}
            onActionButtonClick={() => {
              scenarioType === "micro"
                ? createMicroScenario()
                : createMacroScenario();
            }}
          />
        ) : (
          <Box tabIndex={0}>
            <Typography sx={{ color: "#696969" }}>
              {t("home:mostRecentScenarios")}
            </Typography>

            {scenariosList.map((data) => (
              <Box key={data.id}>
                {scenarioType === "micro" ? (
                  <MicroSingleScenario
                    scenario={data as ListMicroScenarioDto}
                    showDescription={false}
                  />
                ) : (
                  <MacroSingleScenarioInScenarioList
                    scenario={data}
                    showDescription={false}
                  />
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
}
