import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { getEnvironmentVariableValue } from "utils";
import { EnvironmentVariable } from "constants/EnvironmentVariables";
import MacroScenarioList from "../../components/MacroScenarioList/MacroScenarioList";
import { useState } from "react";
import {
  AlertStatusEnum,
  AlertViewModel,
  ViewMacroScenarioDto,
} from "orval/generated/models";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { useNavigate } from "react-router-dom";
import { useCreateMacroScenarioScenariosMacroPost } from "orval/generated/endpoint";
import { AxiosError } from "axios";
import { STATUS_CODES } from "utils/HTTPUtils/constants";
import useDocumentHead from "utils/useDocumentHead";
import BannerAlert, {
  specialBannerInstance,
} from "components/common/BannerAlert/BannerAlert";
import { AppConstant } from "constants/AppConstant";

const sandboxMacroScenarioIdKey = "sandbox_macro_scenario_id";

function MacroToolPage() {
  const { t } = useTranslation("macro");
  useDocumentHead({
    titleAsTranslationKey: t("common:documentHeads:macroViewAllScenarios"),
  });
  const [sandboxMacroScenarioID, setSandboxMacroScenarioID] = useState<
    string | null
  >(null);

  const alert: AlertViewModel = {
    id: 1000,
    message:
      "Please note that the Macro Tool is a summation of all the product level carbon footprints and will not equal corporate reporting numbers. This is due to different methodology (e.g. this Tool excludes business travel and central office emissions) and different assumptions (e.g. currently a lower aluminium EF is used in the Tool vs. reporting)",
    type: AlertStatusEnum.information,
  };
  const activeNotification = new Date() <= new Date("2025-03-31");

  // NO LONGER IN USE BUT KEEP FOR NOW (OCT 2024)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hideFeature = getEnvironmentVariableValue(
    EnvironmentVariable.featureToggle.hideMacroTool,
  );

  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  const { mutate: createMacroScenario, isPending: createMacroIsPending } =
    useCreateMacroScenarioScenariosMacroPost({
      mutation: {
        onSuccess: (macroScenario: ViewMacroScenarioDto) => {
          navigate(`/country-level-analytics/scenario/${macroScenario.id}`, {
            state: { id: macroScenario.id },
          });
        },
        onError: (createMacroError: AxiosError) => {
          if (createMacroError.status !== STATUS_CODES.UNAVAILABLE)
            showSnackbar("micro:errorMessages.createScenario", "error");
        },
      },
    });

  return (
    <>
      <Box display="flex">
        <Box>
          <Stack
            direction={{ sm: "column", md: "row" }}
            justifyContent={{ sm: "center", md: "space-between" }}
            alignItems={{ sm: "flex-start", md: "center" }}
            spacing={2}
          >
            <Box>
              <Typography variant={"h1"} tabIndex={0}>
                {t("macroToolTitle")}
              </Typography>
            </Box>
            <Box>
              <Stack direction={"row"} spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => createMacroScenario()}
                >
                  {t("buttonLabel.createScenario")}
                  {createMacroIsPending ? (
                    <CircularProgress
                      size={24}
                      sx={{ color: "white", ml: 1 }}
                    />
                  ) : null}
                </Button>
              </Stack>
            </Box>
          </Stack>

          <Box my={2}>
            <Typography variant={"body1"} tabIndex={0}>
              {t("macroToolDescription")}
            </Typography>
          </Box>

          {activeNotification ? (
            <Box mb={2}>
              <BannerAlert
                activeAlert={alert}
                localStorageKey={
                  AppConstant.localStorageKeys.dismissedMacroNotification
                }
                specialInstanceType={specialBannerInstance.MACRO}
              />
            </Box>
          ) : null}

          <MacroScenarioList
            setSandboxMacroScenarioID={setSandboxMacroScenarioID}
            sandboxMacroScenarioIdKey={sandboxMacroScenarioIdKey}
            sandboxMacroScenarioID={sandboxMacroScenarioID}
          />
        </Box>
      </Box>
    </>
  );
}

export default MacroToolPage;
