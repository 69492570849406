import { useEffect } from "react";
import { t } from "i18next";

type UseDocumentHeadProps = {
  titleAsTranslationKey?: string;
  titleAsTranslatedString?: string;
};

const useDocumentHead = ({
  titleAsTranslationKey,
  titleAsTranslatedString,
}: UseDocumentHeadProps) => {
  useEffect(() => {
    document.title = titleAsTranslationKey
      ? t(titleAsTranslationKey)
      : titleAsTranslatedString || "Product Emissions Forecaster";
  }, [titleAsTranslationKey, titleAsTranslatedString]);
};

export default useDocumentHead;
