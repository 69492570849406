import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { ProductConfigurationsMicro } from "../../MicroAdjustmentsTable/types";
import { useTranslation } from "react-i18next";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import {
  SKUAdjustmentAccordionDetails,
  ViewMicroScenarioDto,
} from "orval/generated/models";
import { CustomIcon, Icons } from "components/common";
import AdjustmentCountChip from "features/micro-tool/components/MicroAdjustmentsSection/MicroCustomAccordion/AdjustmentCountChip/AdjustmentCountChip";
import Box from "@mui/material/Box";

export type CustomAccordionProps = {
  title: string;
  details: ReactNode;
  accordion?: SKUAdjustmentAccordionDetails;
  hasSubAccordions?: boolean;
  scenario: ViewMicroScenarioDto | undefined;
};

function MicroCustomAccordion({
  title,
  details,
  hasSubAccordions,
  scenario,
}: CustomAccordionProps) {
  const { t } = useTranslation("micro");
  const { palette } = useTheme();

  function convertToTitleCase(snakeCaseString: string): string {
    return snakeCaseString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const getTitle = (title: string) => {
    switch (title) {
      case ProductConfigurationsMicro.SERVING_SIZE:
        return t("servingSizeSection.title");
      case ProductConfigurationsMicro.INGREDIENTS:
        return t("ingredientsSection.title");
      case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
        return t("ingredientsEmissionFactorsSection.title");
      case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
        return t("bodyAndLidEmissionFactorsSection.title");
      case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
        return t("packagingSection.title");
      case ProductConfigurationsMicro.LOGISTICS:
        return t("logisticsSection.title");
      case ProductConfigurationsMicro.MANUFACTURING:
        return t("manufacturingSection.title");
      case ProductConfigurationsMicro.OTHER_PACKAGING:
        return t(`otherPackagingSection.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_PRIMARY:
        return t(`otherPackagingSection.Primary.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_SECONDARY:
        return t(`otherPackagingSection.Secondary.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_TERTIARY:
        return t(`otherPackagingSection.Tertiary.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS:
        return t(`otherPackagingSection.titleEmissionFactors`);
      default:
        return convertToTitleCase(title);
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <CustomIcon
              name={Icons.CHEVRON_RIGHT}
              width={18}
              height={18}
              fill={palette.primary.main}
            />
          }
          className="dropdown-left"
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant={"h3"}>{getTitle(title)}</Typography>
            {scenario ? (
              <AdjustmentCountChip
                scenario={scenario}
                accordionType={title as ProductConfigurationsMicro}
              />
            ) : (
              <Box></Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={hasSubAccordions ? { marginLeft: SUB_ACCORDION_INDENT } : {}}
        >
          {details}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default MicroCustomAccordion;
