import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { generateTableData, TableData } from "./HelperService";
import { MekkoTable } from "./MekkoTable";
import {
  MacroResults,
  MekkoBaseGlideScenario,
  MekkoResult,
} from "orval/generated/models";

type MekkoUnderlyingDataProps = {
  mekkoData: MekkoResult | undefined;
  scenarioResults: MacroResults | null;
  selectedYear: string;
};

export function MekkoTableUnderlyingData({
  mekkoData,
  scenarioResults,
  selectedYear,
}: MekkoUnderlyingDataProps) {
  const [tablesData, setTablesData] = useState<TableData | undefined>();
  useEffect(() => {
    if (mekkoData && scenarioResults) {
      const tableData = generateTableData(
        mekkoData,
        selectedYear as keyof MekkoBaseGlideScenario,
      );
      setTablesData(tableData);
    }
  }, [mekkoData, scenarioResults, selectedYear]);
  return (
    <Box>
      {mekkoData && scenarioResults && tablesData && (
        <MekkoTable columns={tablesData.columns} data={tablesData.data} />
      )}
    </Box>
  );
}
