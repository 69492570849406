import { Typography, Box, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { useModal } from "components/common/Modal";
import { MemberDetails } from "orval/generated/models";
import {
  getGetAllMembersMembersGetQueryKey,
  useDeleteMemberByIdMembersMemberIdDelete,
} from "orval/generated/endpoint";
import { AxiosError } from "axios";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { CustomIcon, Icons } from "components/common";

export interface DeleteUserModalProps {
  userInformation: MemberDetails;
}

const DeleteUserModal = (props: DeleteUserModalProps) => {
  const { t } = useTranslation("toolConfiguration");
  const { closeModal } = useModal();
  const showSnackbar = useSnackbar();
  const { palette } = useTheme();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteUser } = useDeleteMemberByIdMembersMemberIdDelete({
    mutation: {
      onSuccess: async () => {
        closeModal();
        await queryClient.invalidateQueries({
          queryKey: getGetAllMembersMembersGetQueryKey(),
        });
        showSnackbar(
          t("userManagementPage.deleteUser.successMessage"),
          "success",
        );
      },
      onError: (error: AxiosError) => {
        console.error(error);
        showSnackbar(error as AxiosError, "error");
      },
    },
  });

  return (
    <Box maxWidth={500} data-testid="delete-user-modal">
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={closeModal}
          data-testid="close-delete-user-modal-icon"
        >
          <CustomIcon
            name={Icons.CLOSE}
            width={24}
            height={24}
            fill={palette.text.primary}
          />
        </IconButton>
      </Box>
      <Box paddingX={5} paddingBottom={4}>
        <Typography textAlign="center" variant="h4" marginBottom={3}>
          {t("userManagementPage.deleteUser.title")}
        </Typography>
        <Typography textAlign="center" variant="body2" marginBottom={3}>
          {t("userManagementPage.deleteUser.description")}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={closeModal}
            data-testid="delete-user-modal-cancel-button"
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={async () => {
              await deleteUser({ memberId: props.userInformation.id });
            }}
            data-testid="delete-user-modal-delete-button"
          >
            {t("common:actions.delete")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteUserModal;
