import {
  Typography,
  Box,
  Button,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ListMacroScenarioDto,
  ListMicroScenarioDto,
  UpdateMacroScenarioDto,
  UpdateScenarioValues,
  ViewMicroScenarioDto,
} from "orval/generated/models";
import { useSnackbar } from "../Notification/showSnackbar";
import * as yup from "yup";
import { useFormik } from "formik";
import { useModal } from "components/common/Modal";
import { CustomIcon, Icons } from "components/common/CustomIcon";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";

export interface UpdateDetailsModalModalProps {
  open: boolean;
  scenario: ListMicroScenarioDto | ListMacroScenarioDto;
  updateMicroScenarioDetails?: UseMutateAsyncFunction<
    ViewMicroScenarioDto,
    AxiosError,
    {
      scenarioId: number;
      data: UpdateScenarioValues;
    },
    unknown
  >;
  updateMacroScenarioDetails?: (
    scenarioId: number,
    data: UpdateMacroScenarioDto,
  ) => Promise<boolean>;
}

const UpdateDetailsModal = ({
  open,
  scenario,
  updateMicroScenarioDetails,
  updateMacroScenarioDetails,
}: UpdateDetailsModalModalProps) => {
  const { t } = useTranslation(["micro", "common"]);
  const updateNameInputRef = useRef<HTMLInputElement>(null);
  const showSnackbar = useSnackbar();
  const [microOrMacro, setMicroOrMacro] = useState<string | null>(null);
  const { palette } = useTheme();
  const { closeModal } = useModal();
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const isMicroScenario = updateMicroScenarioDetails !== undefined;
    setMicroOrMacro(isMicroScenario ? "micro" : "macro");
  }, [scenario, updateMicroScenarioDetails]);

  const scenarioUpdateDetailsModalSchema = yup.object().shape({
    name: yup
      .string()
      .max(60, "Name must be at most 60 characters")
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
    description: yup
      .string()
      .max(255, "Description must be at most 255 characters"),
  });

  useEffect(() => {
    if (open && updateNameInputRef.current) {
      updateNameInputRef.current.focus();
    }
  }, [open]);

  const handleUpdateMacroScenarioDetails = useCallback(
    async (
      scenario: ListMacroScenarioDto,
      name: string,
      description: string,
      initialName: string,
      initialDescription: string,
    ) => {
      const updatePayload: UpdateMacroScenarioDto = {
        name: initialName === name ? undefined : name,
        description:
          initialDescription === description ? undefined : description,
      };
      if (updateMacroScenarioDetails && scenario.id) {
        try {
          await updateMacroScenarioDetails(scenario.id, updatePayload);
          setIsPending(false);
          closeModal();
        } catch (error) {
          showSnackbar(
            t(
              "micro:microViewAllScenariosPage.updateDetailsModal.duplicateError",
            ),
            "error",
          );
          console.warn(error);
        }
      }
    },
    [closeModal, showSnackbar, t, updateMacroScenarioDetails],
  );

  const formik = useFormik<UpdateScenarioValues>({
    initialValues: {
      description: scenario?.description ?? "",
      name: scenario?.name ?? "",
    },
    validationSchema: scenarioUpdateDetailsModalSchema,
    onSubmit: (values) => {
      setIsPending(true);
      if (microOrMacro === "macro") {
        values.name &&
          handleUpdateMacroScenarioDetails(
            scenario,
            values.name,
            values.description ?? "",
            formik.initialValues.name ?? "",
            formik.initialValues.description ?? "",
          );
      }
      if (scenario.id !== undefined && updateMicroScenarioDetails) {
        const _updateScenarioPayload: UpdateScenarioValues = {
          name:
            formik.initialValues.name === formik.values.name
              ? undefined
              : formik.values.name,
          description:
            formik.initialValues.description === formik.values.description
              ? undefined
              : formik.values.description,
        };

        updateMicroScenarioDetails({
          scenarioId: scenario.id,
          data: _updateScenarioPayload,
        }).then(() => {
          setIsPending(false);
          closeModal();
        });
      }
    },
  });

  return (
    <Box>
      <Box marginRight={1} marginTop={1} display="flex" justifyContent="right">
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1, float: "right" }}
          onClick={closeModal}
          autoFocus={true}
        >
          <CustomIcon
            name={Icons.CLOSE}
            width={24}
            height={24}
            fill={palette.text.primary}
          />
        </IconButton>
      </Box>
      <Box paddingX={5}>
        <Typography
          textAlign="center"
          variant="h2"
          marginBottom={3}
          tabIndex={0}
        >
          {t("micro:microViewAllScenariosPage.updateDetailsModal.title")}
        </Typography>
        <Typography
          textAlign="center"
          variant="body1"
          marginBottom={3}
          tabIndex={0}
        >
          {t("micro:microViewAllScenariosPage.updateDetailsModal.description")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              id="name"
              label={t(
                "micro:microViewAllScenariosPage.updateDetailsModal.labels.name",
              )}
              variant="outlined"
              required
              sx={{ marginBottom: 2 }}
              inputRef={updateNameInputRef}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              data-testid={"update-name-input"}
            />
            <TextField
              id="description"
              label={t(
                "micro:microViewAllScenariosPage.updateDetailsModal.labels.description",
              )}
              multiline
              rows={4}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              data-testid={"update-description-input"}
              sx={{ width: "500px" }}
            />
          </Box>
          <Box display="flex" justifyContent="center" my={3}>
            <Button
              sx={{ mr: 2 }}
              variant="outlined"
              onClick={closeModal}
              data-testid={"cancel-update-scenario-button"}
              disabled={isPending}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              type="submit"
              disabled={!formik.dirty || !formik.isValid || isPending}
              data-testid={"update-scenario-button"}
            >
              {t("common:actions.update")}
              {isPending ? (
                <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
              ) : null}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default UpdateDetailsModal;
