import {
  Typography,
  Box,
  SelectChangeEvent,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import "./DataExtraction.scss";
import {
  exportDataExtractDataManagementExportPost,
  useGetCategoriesCategoriesGet,
  useGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet,
  useGetDataManagementNumericDataDataManagementExportAvailableDataNumericGet,
  useGetMemberCountriesMembersCountriesGet,
  useGetPackTypesPackTypesGet,
  useGetYearsYearsGet,
} from "orval/generated/endpoint";
import { withCustomAxios } from "orval/mutator/custom-instance";

import { useSnackbar } from "components/common/Notification/showSnackbar";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ApiResponse,
  DataExtractionFormArrayType,
  DataTypeSelectValue,
} from "./Types";
import DataExtractionFormPartOne from "./DataExtractionFormPartOne/DataExtractionFormPartOne";
import DataExtractionFormPartTwo from "./DataExtractionFormPartTwo/DataExtractionFormPartTwo";
import DataExtractionFormPartThree from "./DataExtractionFormPartThree/DataExtractionFormPartThree";
import { DataExtractRequest } from "orval/generated/models";
import { AxiosError } from "axios";
import getFormattedDateForDownloads from "utils/getFormattedDateForDownloads";
import downloadBlob from "../../../../utils/downloadBlob/downloadBlob";
import useDocumentHead from "utils/useDocumentHead";

export default function DataExtraction() {
  const { t } = useTranslation("productConfiguration");
  useDocumentHead({
    titleAsTranslationKey: t(
      "common:documentHeads:dataManagementDataExtraction",
    ),
  });
  const [dataTypeSelectValue, setDataTypeSelectValue] =
    useState<DataTypeSelectValue>(DataTypeSelectValue.None);
  const [accordionData, setAccordionData] = useState<ApiResponse>();
  const showSnackbar = useSnackbar();
  const [reset, setReset] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [downloadIsPending, setDownloadIsPending] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const handleExpandCollapseAll = () => {
    setExpandAll((prev) => !prev);
  };

  const createDataExtractionValidationSchema = yup.object({
    tables: yup
      .array()
      .of(yup.string().required("Tables are required"))
      .min(1, "At least one table is required")
      .required("Tables are required"),
    filters: yup.object({
      pack_type: yup.array().of(yup.number()),
      country: yup.array().of(yup.string()),
      year: yup.array().of(yup.number()),
      category: yup.array().of(yup.number()),
    }),
  });

  const formik = useFormik<DataExtractRequest>({
    initialValues: {
      tables: [],
      filters: {
        pack_type: [],
        country: [],
        year: [],
        category: [],
      },
    },
    validationSchema: createDataExtractionValidationSchema,
    onSubmit: async (values) => {
      setDownloadIsPending(true);
      try {
        const response: typeof exportDataExtractDataManagementExportPost =
          await withCustomAxios({
            url: "/data-management/export",
            method: "POST",
            data: values,
            responseType: "blob",
          });

        const fileName = `PEF-DataExtraction-${getFormattedDateForDownloads()}.xlsx`;

        downloadBlob(response as unknown as BlobPart, fileName);

        showSnackbar(
          t("dataManagementPage.dataExtraction.downloadSuccess"),
          "success",
        );
      } catch (error) {
        console.warn(error);
        showSnackbar(
          t("dataManagementPage.dataExtraction.downloadError"),
          "error",
        );
      } finally {
        setDownloadIsPending(false);
      }
    },
  });

  const handleDataTypeSelect = (
    event: SelectChangeEvent<DataTypeSelectValue>,
  ) => {
    formik.resetForm();
    event.target.value &&
      setDataTypeSelectValue(event.target.value as DataTypeSelectValue);
  };

  const handleAccordionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (checked) {
      formik.setFieldValue("tables", [...formik.values.tables, id]);
    } else {
      formik.setFieldValue(
        "tables",
        formik.values.tables.filter((table) => table !== id),
      );
    }
  };

  const handleReset = () => {
    formik.resetForm();
    setReset(true);
    setTimeout(() => setReset(false), 0);
    setDataTypeSelectValue(DataTypeSelectValue.None);
  };

  const {
    data: numericDropdownsData,
    isPending: numericDropdownsDataIsPending,
    error: numericDropdownsDataError,
  } = useGetDataManagementNumericDataDataManagementExportAvailableDataNumericGet();

  const {
    data: configurationDropdownsData,
    isPending: configurationDropdownsDataIsPending,
    error: configurationDropdownsDataError,
  } = useGetDataManagementConfigurationDataDataManagementExportAvailableDataConfigurationGet();

  const {
    data: countryDropdownData,
    isPending: countryDropdownDataIsPending,
    error: countryDropdownDataError,
  } = useGetMemberCountriesMembersCountriesGet();

  const {
    data: yearsDropdownData,
    isPending: yearsDropdownDataIsPending,
    error: yearsDropdownDataError,
  } = useGetYearsYearsGet();

  const {
    data: categoriesDropdownData,
    isPending: categoriesDropdownDataIsPending,
    error: categoriesDropdownDataError,
  } = useGetCategoriesCategoriesGet();

  const {
    data: packTypesDropdownData,
    isPending: packTypesDropdownDataIsPending,
    error: packTypesDropdownDataError,
  } = useGetPackTypesPackTypesGet();

  useEffect(() => {
    if (dataTypeSelectValue === DataTypeSelectValue.ConfigurationData) {
      configurationDropdownsData &&
        setAccordionData(configurationDropdownsData as ApiResponse);
    } else if (dataTypeSelectValue === DataTypeSelectValue.NumericData) {
      setAccordionData(numericDropdownsData as ApiResponse);
    }
  }, [numericDropdownsData, configurationDropdownsData, dataTypeSelectValue]);

  useEffect(() => {
    numericDropdownsDataIsPending ||
    configurationDropdownsDataIsPending ||
    countryDropdownDataIsPending ||
    yearsDropdownDataIsPending ||
    categoriesDropdownDataIsPending ||
    packTypesDropdownDataIsPending
      ? setIsPending(true)
      : setIsPending(false);
  }, [
    numericDropdownsDataIsPending,
    configurationDropdownsDataIsPending,
    countryDropdownDataIsPending,
    yearsDropdownDataIsPending,
    categoriesDropdownDataIsPending,
    packTypesDropdownDataIsPending,
  ]);

  useEffect(() => {
    if (
      numericDropdownsDataError ||
      configurationDropdownsDataError ||
      countryDropdownDataError ||
      yearsDropdownDataError ||
      categoriesDropdownDataError ||
      packTypesDropdownDataError
    ) {
      const error =
        numericDropdownsDataError || configurationDropdownsDataError;
      showSnackbar(error as AxiosError, "error");
    }
  }, [
    numericDropdownsDataError,
    configurationDropdownsDataError,
    showSnackbar,
    countryDropdownDataError,
    yearsDropdownDataError,
    categoriesDropdownDataError,
    packTypesDropdownDataError,
  ]);

  const formArray: DataExtractionFormArrayType[] = [
    {
      number: 1,
      title: t("dataManagementPage.dataExtraction.formTitles.dataTypeTitle"),
      description: t(
        "dataManagementPage.dataExtraction.formDescriptions.dataTypeDescription",
      ),
      additionalReactComponent: (
        <DataExtractionFormPartOne
          handleDataTypeSelect={handleDataTypeSelect}
          dataTypeSelectValue={dataTypeSelectValue}
          isPending={isPending}
        ></DataExtractionFormPartOne>
      ),
    },
    {
      number: 2,
      title: t("dataManagementPage.dataExtraction.formTitles.tableTypesTitle"),
      description: t(
        "dataManagementPage.dataExtraction.formDescriptions.tableTypesDescription",
      ),
      additionalReactComponent: accordionData ? (
        <DataExtractionFormPartTwo
          dataTypeSelectValue={dataTypeSelectValue}
          accordionData={accordionData}
          handleAccordionChange={handleAccordionChange}
          formik={formik}
          reset={reset}
          expandAll={expandAll}
        ></DataExtractionFormPartTwo>
      ) : (
        <></>
      ),
    },
    {
      number: 3,
      title: t(
        "dataManagementPage.dataExtraction.formTitles.optionalSectionTitle",
      ),
      description: t(
        "dataManagementPage.dataExtraction.formDescriptions.optionalSectionDescription",
      ),
      additionalReactComponent: (
        <DataExtractionFormPartThree
          countryDropdownData={countryDropdownData}
          categoriesDropdownData={categoriesDropdownData}
          packTypesDropdownData={packTypesDropdownData}
          yearsDropdownData={yearsDropdownData}
          formik={formik}
        ></DataExtractionFormPartThree>
      ),
    },
  ];

  return (
    <Box mt={4}>
      <form onSubmit={formik.handleSubmit}>
        {formArray.map((item, index) => (
          <Box my={3} key={index}>
            <Box display={"flex"} alignItems={"center"} my={1}>
              <Box
                className={
                  dataTypeSelectValue === "" && item.number !== 1
                    ? "disabledStyling"
                    : ""
                }
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  color: "white",
                  border: "2px solid rgb(208, 74, 2)",
                }}
                mr={1}
              >
                <Typography
                  variant={"h5"}
                  className={
                    dataTypeSelectValue === "" && item.number !== 1
                      ? "disabledStyling"
                      : ""
                  }
                >
                  {item.number}
                </Typography>
              </Box>
              <Box tabIndex={0}>
                <Typography
                  className={`${dataTypeSelectValue === "" && item.number !== 1 ? "disabledStyling" : ""} header-H2`}
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
            <Box
              my={2}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography
                  className={
                    dataTypeSelectValue === "" && item.number !== 1
                      ? "disabledStyling"
                      : ""
                  }
                >
                  {item.description}
                </Typography>
              </Box>
              {item.number === 2 && dataTypeSelectValue !== "" ? (
                <Button onClick={handleExpandCollapseAll}>
                  {expandAll
                    ? t("common:actions.collapseAll")
                    : t("common:actions.expandAll")}
                </Button>
              ) : (
                <Box></Box>
              )}
            </Box>
            {item.number === 1 || dataTypeSelectValue !== "" ? (
              <Box>{item.additionalReactComponent}</Box>
            ) : null}
          </Box>
        ))}
        <Box display="flex" justifyContent="space-between" my={4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleReset}
            disabled={!formik.dirty || !formik.isValid || downloadIsPending}
          >
            {t("common:actions.reset")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.dirty || !formik.isValid || downloadIsPending}
          >
            {t("common:actions.download")}
            {downloadIsPending ? (
              <CircularProgress size={24} sx={{ color: "white", ml: 1 }} />
            ) : null}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
