import Box from "@mui/material/Box";
import {
  AdjustmentEntities,
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentPillars,
  MacroAdjustmentTypes,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import {
  CountryAverageDistanceTravelled,
  CountryAverageManufacturingEur,
  CountryCdeAverageEnergyConsumption,
  CountryCdeEnergyEmissionFactor,
  CountryPackagingAverageRecoveryRate,
  CountryViewModel,
  EnergyTypeEmissionFactor,
  IngredientEmissionFactor,
  GetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGetParams,
  IngredientWeightEntity,
  IngredientWeightMacroFilters,
  MacroFilter,
  MacroFilters,
  MacroFiltersAndFilteredRTDLForProducts,
  MacroFiltersForProducts,
  MacroFilterValue,
  PackagingWeightEntityDto,
  PartMaterialEmissionFactor,
  RoadVehicleSourceData,
  VehicleMixEmissionFactor,
  ViewMacroScenarioAdjustment,
  ViewMacroScenarioDto,
  PackagingRecycledContentEntityDto,
  PackagingNetLossRateEntityDto,
  LogisticsDistanceEntityWithCountryDto,
  ManufacturingEurEntityWithCountryDto,
  IngredientEfEntityDto,
  PackagingRecoveryRateEntityWithCountryDto,
  PackagingEfEntityDto,
  ManufacturingSourceMixEfEntityDto,
  LogisticsVehicleMixEfEntityDto,
  CdeEnergyMixEfEntityDto,
  CdeFleetBreakdownEntityDto,
} from "orval/generated/models";
import { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import {
  useGetAggregatedCdeEnergyEfScenariosMacroAdjustmentsAggregatedCdeEnergyMixEfPost,
  useGetAggregatedCdeFleetBreakdownScenariosMacroAdjustmentsAggregatedCdeFleetBreakdownPost,
  useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost,
  useGetAggregatedIngredientSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedIngredientsEmissionFactorPost,
  useGetAggregatedLogisticsOtherVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsOtherVehicleMixEfPost,
  useGetAggregatedLogisticsRoadVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsRoadVehicleMixEfPost,
  useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost,
  useGetAggregatedManufacturingSourceMixEfScenariosMacroAdjustmentsAggregatedManufacturingSourceMixEfPost,
  useGetAggregatedPackagingSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedPackagingEmissionFactorPost,
  useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost,
  useGetMacroFiltersAndFilteredRtdlForProductsScenariosMacroFiltersForProductsPost,
  useGetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGet,
  useGetTotalRtdlScenariosMacroTotalRtdlGet,
} from "orval/generated/endpoint";
import SalesVolumeChartArea from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/SalesVolumeChartArea/SalesVolumeChartArea";
import EntitiesDropdownStepTwo from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/EntitiesDropdownStepTwo/EntitiesDropdownStepTwo";
import ProductSelectionView from "features/macro-tool/components/ProductSelectionView/ProductSelectionView";
import ApplyFiltersView from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/ApplyFiltersView/ApplyFiltersView";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type MacroAdjustmentStepTwoProps = {
  adjustmentCombination: MacroAdjustmentCombination | null;
  scenario: ViewMacroScenarioDto | undefined;
  macroFiltersParams: GetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGetParams;
  setMacroFiltersParams: Function;
  adjustmentDetails: ViewMacroScenarioAdjustment | undefined;
};

export const MacroAdjustmentStepTwo = ({
  adjustmentCombination,
  scenario,
  macroFiltersParams,
  setMacroFiltersParams,
  adjustmentDetails,
}: MacroAdjustmentStepTwoProps) => {
  const { values, setFieldValue } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const { t } = useTranslation("macro");

  const showSnackbar = useSnackbar();

  const { data: totalRTDL } = useGetTotalRtdlScenariosMacroTotalRtdlGet({
    countries:
      scenario?.inputs?.countries?.map((country) => country.country) || [],
    target_year: scenario?.inputs?.years?.target_year || 2030,
  });

  const {
    data: filtersData,
    isPending: filtersDataIsPending,
    error: errorFetchingFiltersData,
  } = useGetMacroFiltersAndFilteredRtdlScenariosMacroFiltersGet(
    macroFiltersParams,
    {
      query: {
        enabled:
          Boolean(macroFiltersParams.countries?.length) &&
          Boolean(macroFiltersParams?.target_year),
      },
    },
  );

  const [filteredByProducts, setFilteredByProducts] =
    useState<MacroFiltersAndFilteredRTDLForProducts | null>(null);

  const { mutateAsync: getFilteredRTDLByProducts } =
    useGetMacroFiltersAndFilteredRtdlForProductsScenariosMacroFiltersForProductsPost();

  const [aggregatedWeights, setAggregatedWeights] = useState<
    | PartMaterialEmissionFactor[]
    | IngredientEmissionFactor[]
    | EnergyTypeEmissionFactor[]
    | VehicleMixEmissionFactor[]
    | CountryCdeEnergyEmissionFactor[]
    | CountryCdeAverageEnergyConsumption[]
    | null
  >(null);

  const [aggregatedWeightsForCountries, setAggregatedWeightsForCountries] =
    useState<
      | CountryPackagingAverageRecoveryRate[]
      | CountryAverageManufacturingEur[]
      | CountryAverageDistanceTravelled[]
      | null
    >(null);

  const { mutateAsync: getAggregatedRecoveryRateWeights } =
    useGetAggregatedPackagingWeightedAvgRecoveryRateScenariosMacroAdjustmentsAggregatedPackagingRecoveryRatePost();

  const { mutateAsync: getAggregatedManufacturingEur } =
    useGetAggregatedManufacturingEurScenariosMacroAdjustmentsAggregatedManufacturingEurPost();

  const { mutateAsync: getAggregatedLogisticsDistanceTravelledData } =
    useGetAggregatedDistanceTravelledScenariosMacroAdjustmentsAggregatedLogisticsDistanceTravelledPost();

  const { mutateAsync: getAggregatedPackagingEFData } =
    useGetAggregatedPackagingSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedPackagingEmissionFactorPost();

  const { mutateAsync: getAggregatedIngredientEFData } =
    useGetAggregatedIngredientSupplierEmissionFactorScenariosMacroAdjustmentsAggregatedIngredientsEmissionFactorPost();

  const { mutateAsync: getAggregatedManufacturingEFData } =
    useGetAggregatedManufacturingSourceMixEfScenariosMacroAdjustmentsAggregatedManufacturingSourceMixEfPost();

  const { mutateAsync: getAggregatedRoadVehicleEFData } =
    useGetAggregatedLogisticsRoadVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsRoadVehicleMixEfPost();

  const { mutateAsync: getAggregatedOtherVehicleEFData } =
    useGetAggregatedLogisticsOtherVehicleMixEfScenariosMacroAdjustmentsAggregatedLogisticsOtherVehicleMixEfPost();
  const { mutateAsync: getAggregatedCdeEnergyMixEfData } =
    useGetAggregatedCdeEnergyEfScenariosMacroAdjustmentsAggregatedCdeEnergyMixEfPost();
  const { mutateAsync: getAggregatedCdeFleetBreakdownData } =
    useGetAggregatedCdeFleetBreakdownScenariosMacroAdjustmentsAggregatedCdeFleetBreakdownPost();

  useEffect(() => {
    if (values.selectedProducts?.length) {
      const payload = {
        products: values.selectedProducts,
        entities: values.selectedEntities.map((entity) => entity?.id),
        base_year: scenario?.inputs?.years?.base_year as number,
        target_year: scenario?.inputs?.years?.target_year as number,
      };

      const fetchAggregatedWeights = async () => {
        let data;
        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
            data = await getAggregatedRecoveryRateWeights({ data: payload });
            setAggregatedWeightsForCountries(data);
            break;
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
            data = await getAggregatedManufacturingEur({ data: payload });
            setAggregatedWeightsForCountries(data);
            break;
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            data = await getAggregatedLogisticsDistanceTravelledData({
              data: payload,
            });
            setAggregatedWeightsForCountries(data);
            break;

          default:
            return;
        }
      };

      fetchAggregatedWeights().catch((e) => console.warn(e));
    }
  }, [
    adjustmentCombination,
    getAggregatedLogisticsDistanceTravelledData,
    getAggregatedManufacturingEur,
    getAggregatedPackagingEFData,
    getAggregatedRecoveryRateWeights,
    scenario?.inputs?.years?.base_year,
    scenario?.inputs?.years?.target_year,
    values.payload.filters.countries,
    values.selectedEntities,
    values.selectedProducts,
  ]);

  useEffect(() => {
    if (values.payload.filters.countries.length) {
      const fetchAggregatedWeights = async () => {
        const payload = {
          data: {
            filters: {
              country: values.payload.filters.countries[0] as string,
            },
            entities: values.selectedEntities.map((entity) => entity?.id),
            base_year: scenario?.inputs?.years?.base_year as number,
            target_year: scenario?.inputs?.years?.target_year as number,
          },
        };

        let data;

        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
            data = await getAggregatedPackagingEFData(payload);
            break;
          case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
            data = await getAggregatedIngredientEFData(payload);
            break;

          case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
            data = await getAggregatedManufacturingEFData(payload);
            break;

          case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
            data = await getAggregatedRoadVehicleEFData(payload);
            break;

          case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
            data = await getAggregatedOtherVehicleEFData(payload);
            break;

          case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
            data = await getAggregatedCdeEnergyMixEfData({
              data: {
                filters: {
                  countries: values.payload.filters.countries as string[],
                },
                base_year: scenario?.inputs?.years?.base_year as number,
                target_year: scenario?.inputs?.years?.target_year as number,
              },
            });
            break;

          case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
            data = await getAggregatedCdeFleetBreakdownData({
              data: {
                filters: {
                  countries: values.payload.filters.countries as string[],
                },
                base_year: scenario?.inputs?.years?.base_year as number,
                target_year: scenario?.inputs?.years?.target_year as number,
                entities: values.selectedEntities.map((entity) => entity.id),
              },
            });
            break;

          default:
            return;
        }

        setAggregatedWeights(data);
      };

      fetchAggregatedWeights().catch((e) => console.warn(e));
    }
  }, [
    adjustmentCombination?.combo,
    getAggregatedCdeEnergyMixEfData,
    getAggregatedCdeFleetBreakdownData,
    getAggregatedIngredientEFData,
    getAggregatedManufacturingEFData,
    getAggregatedOtherVehicleEFData,
    getAggregatedPackagingEFData,
    getAggregatedRoadVehicleEFData,
    scenario?.inputs?.years,
    values.payload.filters.countries,
    values.selectedEntities,
  ]);

  useEffect(() => {
    if (
      Boolean(values.selectedProducts.length) &&
      (values.selectedPillar === MacroAdjustmentPillars.Packaging ||
        values.selectedPillar === MacroAdjustmentPillars.Manufacturing ||
        values.selectedPillar === MacroAdjustmentPillars.Logistics)
    ) {
      const fetchRtdl = async () => {
        try {
          const filtered = await getFilteredRTDLByProducts({
            data: values.selectedProducts,
          });

          if (filtered) {
            const checkEntities: MacroFilter[] = [];
            const entityIds = new Set<number>();
            let macroFilterType: keyof MacroFiltersForProducts =
              "part_materials";

            switch (values.selectedPillar) {
              case MacroAdjustmentPillars.Manufacturing:
                macroFilterType = "energy_types";
                break;
              case MacroAdjustmentPillars.Logistics:
                macroFilterType = "transport_modes";
                break;
              default:
                break;
            }

            values.selectedEntities.forEach((entity) => {
              const record = filtered.filters[macroFilterType].find(
                (e: { id: number }) => e.id === entity.id,
              );
              if (record && !entityIds.has(entity.id)) {
                checkEntities.push(entity);
                entityIds.add(entity.id);
              }
            });

            if (checkEntities.length !== values.selectedEntities.length) {
              setFieldValue("selectedEntities", checkEntities).catch((e) =>
                console.warn(e),
              );
            }
            setFilteredByProducts(filtered);
          }
        } catch (e) {
          console.warn(e);
        }
      };

      fetchRtdl().catch((error) => {
        console.warn(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFilteredRTDLByProducts,
    setFieldValue,
    values.selectedPillar,
    values.selectedProducts,
  ]);

  const handleCountriesSave = useCallback(
    (countries: CountryViewModel[]) => {
      let selectedCountryCodes: string[] = [];
      if (!countries.length) {
        selectedCountryCodes =
          scenario?.inputs?.countries?.map((country) => country.country) || [];
      } else {
        selectedCountryCodes = countries?.map((country) => country.country);
      }
      setFieldValue("payload.filters", {
        ...values.payload.filters,
        countries: selectedCountryCodes,
      }).catch((error) => {
        console.warn(error);
      });

      setMacroFiltersParams((prev: any) => ({
        ...prev,
        countries: selectedCountryCodes,
      }));
    },
    [
      scenario?.inputs?.countries,
      setFieldValue,
      setMacroFiltersParams,
      values.payload.filters,
    ],
  );

  const handleEntitiesSave = useCallback(
    (
      selectedDropdownItems: MacroFilterValue[],
      entityType: keyof MacroFilters | keyof MacroFiltersForProducts,
    ) => {
      let selectedEntities: MacroFilter[] = [];

      switch (adjustmentCombination?.combo) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
        case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
        case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
        case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
          selectedEntities =
            filtersData?.filters[entityType].filter((item: MacroFilter) =>
              selectedDropdownItems.includes(item.value),
            ) || [];
          break;

        default:
          selectedEntities =
            filteredByProducts?.filters[
              entityType as keyof MacroFiltersForProducts
            ].filter((item: MacroFilter) =>
              selectedDropdownItems.includes(item.value),
            ) || [];
          break;
      }

      if (selectedEntities?.length) {
        setFieldValue("selectedEntities", selectedEntities).catch((error) => {
          console.warn(error);
        });
      } else {
        setFieldValue("selectedEntities", []).catch((error) => {
          console.warn(error);
        });
      }
    },
    [
      adjustmentCombination,
      filteredByProducts?.filters,
      filtersData?.filters,
      setFieldValue,
    ],
  );

  const handleResetPageTwo = useCallback(() => {
    setFieldValue("payload.filters", {
      categories: [],
      brand_groups: [],
      brands: [],
      ingredients: [],
      countries: [],
    }).catch((error) => {
      console.warn(error);
    });

    setFieldValue("selectedCountries", []).catch((error) => {
      console.warn(error);
    });
    setFieldValue("selectedEntities", []).catch((error) => {
      console.warn(error);
    });
    setMacroFiltersParams({
      countries:
        scenario?.inputs?.countries?.map((country) => country.country) || [],
      target_year: scenario?.inputs?.years?.target_year || 2045,
    });
  }, [
    scenario?.inputs?.countries,
    scenario?.inputs?.years?.target_year,
    setFieldValue,
    setMacroFiltersParams,
  ]);

  const updateSelectedProducts = useCallback(
    (newSelectedProducts: number[]) => {
      setFieldValue("selectedProducts", newSelectedProducts).catch((e) =>
        console.log(e),
      );
    },
    [setFieldValue],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    errorFetchingFiltersData
      ? showSnackbar("Error fetching filters data", "error")
      : null;
  }, [errorFetchingFiltersData, showSnackbar]);

  useEffect(() => {
    if (filtersData) {
      Object.keys(values.payload.filters ?? {}).forEach((filter) => {
        if (
          filtersData.filters[filter as keyof MacroFilters]?.length <
          (values.payload.filters[filter as keyof IngredientWeightMacroFilters]
            ?.length as number)
        ) {
          setFieldValue(
            `payload.filters.${filter}`,
            filtersData.filters[filter as keyof MacroFilters]?.map(
              (_filter) => _filter.id,
            ),
          ).catch((error) => {
            console.warn(error);
          });

          setMacroFiltersParams((prev: any) => ({
            ...prev,
            [filter]: filtersData.filters[filter as keyof MacroFilters]?.map(
              (_filter) => _filter.id,
            ),
          }));
        }
      });

      if (adjustmentDetails && !values.selectedEntities.length) {
        const tempSelectedEntities =
          (adjustmentDetails?.entities.map((entity) => {
            switch (adjustmentDetails.pillar) {
              case MacroAdjustmentPillars.Packaging:
                return filtersData?.filters.part_materials.find(
                  (part_material) => {
                    return part_material.id === entity.part_material_id;
                  },
                );
              case MacroAdjustmentPillars.Manufacturing:
                return filtersData?.filters.energy_types.find((energy_type) => {
                  return energy_type.id === entity.energy_type_id;
                });
              case MacroAdjustmentPillars.Logistics:
                return filtersData?.filters.transport_modes.find(
                  (transport_mode) => {
                    return transport_mode.id === entity.transport_mode_id;
                  },
                );
              case MacroAdjustmentPillars.CDE:
                return filtersData?.filters.cde_groups.find((cde_group) => {
                  return cde_group.value === entity.cde_group;
                });
              default:
                return filtersData?.filters.ingredients.find((ingredient) => {
                  return ingredient.id === entity.ingredient_id;
                });
            }
          }) as MacroFilter[]) || [];
        const uniqueEntities: MacroFilter[] = [];

        tempSelectedEntities.forEach((entity) => {
          if (
            !uniqueEntities.find(
              (uniqueEntity) => uniqueEntity.id === entity.id,
            )
          ) {
            uniqueEntities.push(entity);
          }
        });
        setFieldValue("selectedEntities", uniqueEntities);
      }

      if (adjustmentDetails && !values.payload.entities.length)
        setFieldValue(
          "payload.entities",
          adjustmentDetails?.entities.map((entity) => {
            switch (adjustmentCombination?.combo) {
              case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
              case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
                return {
                  ingredient_id: entity.ingredient_id,
                  weight: entity.weight,
                } as IngredientWeightEntity;
              case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
                return {
                  ingredient_id: entity.ingredient_id,
                  ef: entity.ef,
                  supplier: entity.supplier,
                  supply_share: entity.supply_share,
                } as IngredientEfEntityDto;
              case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
              case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
                return {
                  part_material_id: entity.part_material_id,
                  weight: entity.weight,
                } as PackagingWeightEntityDto;
              case MacroAdjustmentCombinations.Packaging_RecycledContent:
                return {
                  part_material_id: entity.part_material_id,
                  recycled_content: entity.recycled_content,
                } as PackagingRecycledContentEntityDto;
              case MacroAdjustmentCombinations.Packaging_NetLossRate:
                return {
                  part_material_id: entity.part_material_id,
                  net_loss_rate: entity.net_loss_rate,
                } as PackagingNetLossRateEntityDto;
              case MacroAdjustmentCombinations.Packaging_RecoveryRate:
                return {
                  part_material_id: entity.part_material_id,
                  recovery_rate: entity.recovery_rate,
                  country: entity.country,
                } as PackagingRecoveryRateEntityWithCountryDto;
              case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
                return {
                  part_material_id: entity.part_material_id,
                  recycled_ef: entity.recycled_ef,
                  supplier: entity.supplier,
                  supply_share: entity.supply_share,
                  virgin_ef: entity.virgin_ef,
                } as PackagingEfEntityDto;
              case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
                return {
                  energy_type_id: entity.energy_type_id,
                  country: entity.country,
                  eur: entity.eur,
                } as ManufacturingEurEntityWithCountryDto;
              case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
                return {
                  ef: entity.ef,
                  energy_source: entity.energy_source,
                  energy_type_id: entity.energy_type_id,
                  supply_share: entity.supply_share,
                } as ManufacturingSourceMixEfEntityDto;
              case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
                return {
                  transport_mode_id: entity.transport_mode_id,
                  distance: entity.distance,
                  country: entity.country,
                } as LogisticsDistanceEntityWithCountryDto;
              case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
              case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
                return {
                  ef: entity.ef,
                  transport_mode_id: entity.transport_mode_id,
                  usage_share: entity.usage_share,
                  vehicle: entity.vehicle,
                } as LogisticsVehicleMixEfEntityDto;
              case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
                return {
                  ef: entity.ef,
                  country: entity.country,
                  energy_source: entity.energy_source,
                  supply_share: entity.supply_share,
                } as CdeEnergyMixEfEntityDto;
              case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
                return {
                  avg_energy_consumption: entity.avg_energy_consumption,
                  cde_group: entity.cde_group,
                  country: entity.country,
                  number_of_units: entity.number_of_units,
                } as CdeFleetBreakdownEntityDto;
              default:
                return {};
            }
          }) || [],
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adjustmentDetails,
    filtersData,
    setFieldValue,
    setMacroFiltersParams,
    values.payload.filters,
  ]);

  useEffect(() => {
    if (values.selectedEntities?.length !== values.payload.entities.length) {
      switch (adjustmentCombination?.combo) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
          setFieldValue(
            "payload.entities",
            values.selectedEntities?.map((entity) => {
              const payloadEntities: IngredientWeightEntity[] = values.payload
                .entities as IngredientWeightEntity[];
              const existing = payloadEntities.find(
                (e) => e.ingredient_id === entity.id,
              );

              if (existing) {
                return existing;
              } else {
                return {
                  ingredient_id: entity.id,
                  weight: null,
                };
              }
            }) as AdjustmentEntities,
          ).catch((error) => {
            console.warn(error);
          });
          break;

        case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors: {
          if (aggregatedWeights?.length) {
            const suppliers = (
              aggregatedWeights as IngredientEmissionFactor[]
            )[0].base_year.map((c) => c.supplier);

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: AdjustmentEntities =
                    values.payload.entities;

                  const existingEntities = (
                    payloadEntities as PackagingEfEntityDto[]
                  ).filter((e) => e.part_material_id === entity.id);
                  if (existingEntities.length > 0) {
                    return existingEntities;
                  } else {
                    return suppliers.map((supplier) => ({
                      part_material_id: entity.id,
                      supplier,
                      supply_share: null,
                      virgin_ef: null,
                      recycled_ef: null,
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }

          break;
        }

        case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors: {
          if (aggregatedWeights?.length) {
            const suppliers = (
              aggregatedWeights as IngredientEmissionFactor[]
            )[0].base_year.map((c) => c.supplier);

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: AdjustmentEntities =
                    values.payload.entities;

                  const existingEntities = (
                    payloadEntities as IngredientEfEntityDto[]
                  ).filter((e) => e.ingredient_id === entity.id);
                  if (existingEntities.length > 0) {
                    return existingEntities;
                  } else {
                    return suppliers.map((supplier) => ({
                      ingredient_id: entity.id,
                      supplier,
                      supply_share: null,
                      ef: null,
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }

          break;
        }

        case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors: {
          if (aggregatedWeights?.length) {
            const energySources = (
              aggregatedWeights as EnergyTypeEmissionFactor[]
            )[0].base_year.map((c) => c.energy_source);

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: AdjustmentEntities =
                    values.payload.entities;

                  const existingEntities = (
                    payloadEntities as ManufacturingSourceMixEfEntityDto[]
                  ).filter((e) => e.energy_type_id === entity.id);
                  if (existingEntities.length > 0) {
                    return existingEntities;
                  } else {
                    return energySources.map((energy_source) => ({
                      energy_type_id: entity.id,
                      energy_source,
                      supply_share: null,
                      ef: null,
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }

          break;
        }

        case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
        case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors: {
          if (aggregatedWeights?.length) {
            const vehicles = (
              aggregatedWeights as VehicleMixEmissionFactor[]
            )[0].base_year.map((c) => ({
              vehicle: c.vehicle,
              fuel_display_unit: (c as RoadVehicleSourceData).fuel_display_unit,
              fuel: (c as RoadVehicleSourceData).fuel,
            }));

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: AdjustmentEntities =
                    values.payload.entities;

                  const existingEntities = (
                    payloadEntities as LogisticsVehicleMixEfEntityDto[]
                  ).filter((e) => e.transport_mode_id === entity.id);
                  if (existingEntities.length > 0) {
                    return existingEntities;
                  } else {
                    return vehicles.map((vehicle) => ({
                      transport_mode_id: entity.id,
                      vehicle: vehicle.vehicle,
                      usage_share: null,
                      ef: null,
                      ...(adjustmentCombination.combo ===
                      MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors
                        ? { fuel: null, fuel_display_unit: null }
                        : {}),
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }
          break;
        }

        case MacroAdjustmentCombinations.Packaging_RecycledContent: {
          setFieldValue(
            "payload.entities",
            values.selectedEntities?.map((entity) => {
              const payloadEntities: PackagingRecycledContentEntityDto[] =
                values.payload.entities as PackagingRecycledContentEntityDto[];

              const existing = payloadEntities.find(
                (e) => e.part_material_id === entity.id,
              );

              if (existing) {
                return existing;
              } else {
                return {
                  part_material_id: entity.id,
                  recycled_content: null,
                };
              }
            }),
          ).catch((error) => {
            console.warn(error);
          });
          break;
        }

        case MacroAdjustmentCombinations.Packaging_NetLossRate: {
          setFieldValue(
            "payload.entities",
            values.selectedEntities?.map((entity) => {
              const payloadEntities: PackagingNetLossRateEntityDto[] = values
                .payload.entities as PackagingNetLossRateEntityDto[];

              const existing = payloadEntities.find(
                (e) => e.part_material_id === entity.id,
              );

              if (existing) {
                return existing;
              } else {
                return {
                  part_material_id: entity.id,
                  net_loss_rate: null,
                };
              }
            }),
          ).catch((error) => {
            console.warn(error);
          });
          break;
        }

        default:
          if (
            ![
              MacroAdjustmentCombinations.CDE_CDEFleetBreakdown,
              MacroAdjustmentCombinations.Packaging_RecoveryRate,
              MacroAdjustmentCombinations.Manufacturing_ManufacturingEur,
              MacroAdjustmentCombinations.Logistics_DistanceTravelled,
            ].includes(
              adjustmentCombination?.combo as MacroAdjustmentCombinations,
            )
          )
            setFieldValue(
              "payload.entities",
              values.selectedEntities?.map((entity) => {
                const payloadEntities: PackagingWeightEntityDto[] = values
                  .payload.entities as PackagingWeightEntityDto[];

                const existing = payloadEntities.find(
                  (e) => e.part_material_id === entity.id,
                );

                if (existing) {
                  return existing;
                } else {
                  return {
                    part_material_id: entity.id,
                    weight: null,
                  };
                }
              }),
            ).catch((error) => {
              console.warn(error);
            });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adjustmentCombination?.combo,
    aggregatedWeights,
    aggregatedWeightsForCountries,
    setFieldValue,
    values.selectedEntities,
  ]);

  useEffect(() => {
    if (values.selectedEntities.length) {
      switch (adjustmentCombination?.combo) {
        case MacroAdjustmentCombinations.Packaging_RecoveryRate: {
          if (aggregatedWeightsForCountries?.length) {
            const countries: string[] = aggregatedWeightsForCountries?.map(
              (c) => c.country,
            ) as string[];

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: AdjustmentEntities =
                    values.payload.entities;
                  const existingPartMaterialEntities = (
                    payloadEntities as PackagingRecoveryRateEntityWithCountryDto[]
                  ).filter((e) => e.part_material_id === entity.id);
                  if (existingPartMaterialEntities.length > 0) {
                    return existingPartMaterialEntities;
                  } else {
                    return countries.map((country) => ({
                      part_material_id: entity.id,
                      recovery_rate: null,
                      action: "new_value",
                      country,
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }

          break;
        }
        case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur: {
          if (aggregatedWeightsForCountries?.length) {
            const countries: string[] = aggregatedWeightsForCountries?.map(
              (c) => c.country,
            ) as string[];

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: ManufacturingEurEntityWithCountryDto[] =
                    values.payload
                      .entities as ManufacturingEurEntityWithCountryDto[];
                  const existingEnergyTypeEntities = payloadEntities.filter(
                    (e) => e.energy_type_id === entity.id,
                  );
                  if (existingEnergyTypeEntities.length > 0) {
                    return existingEnergyTypeEntities;
                  } else {
                    return countries?.map((country) => ({
                      energy_type_id: entity.id,
                      eur: null,
                      country,
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }
          break;
        }

        case MacroAdjustmentCombinations.Logistics_DistanceTravelled: {
          if (aggregatedWeightsForCountries?.length) {
            const countries: string[] = aggregatedWeightsForCountries?.map(
              (c) => c.country,
            ) as string[];

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: LogisticsDistanceEntityWithCountryDto[] =
                    values.payload
                      .entities as LogisticsDistanceEntityWithCountryDto[];

                  const existingTransportModeEntities = payloadEntities.filter(
                    (e) => e.transport_mode_id === entity.id,
                  );
                  if (existingTransportModeEntities.length > 0) {
                    return existingTransportModeEntities;
                  } else {
                    return countries.map((country) => ({
                      transport_mode_id: entity.id,
                      distance: null,
                      country,
                    }));
                  }
                })
                .flat(),
            ).catch((error) => {
              console.warn(error);
            });
          }
          break;
        }

        case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
          if (aggregatedWeights?.length) {
            const countries: string[] = (
              aggregatedWeights as CountryCdeAverageEnergyConsumption[]
            ).map((c) => c.country) as string[];

            const cdeGroups = (
              aggregatedWeights as CountryCdeAverageEnergyConsumption[]
            )[0].base_year.map((c) => c.cde_group);

            setFieldValue(
              "payload.entities",
              values.selectedEntities
                ?.map((entity) => {
                  const payloadEntities: AdjustmentEntities =
                    values.payload.entities;

                  const existingEntities = (
                    payloadEntities as CdeFleetBreakdownEntityDto[]
                  ).filter((e) => e.cde_group === entity.value);
                  if (existingEntities.length > 0) {
                    return existingEntities;
                  } else {
                    return countries.map((country) =>
                      cdeGroups.map((cde_group) => ({
                        country,
                        cde_group,
                        number_of_units: null,
                        avg_energy_consumption: null,
                      })),
                    );
                  }
                })
                .flat(3),
            ).catch((error) => {
              console.warn(error);
            });
          }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adjustmentCombination?.combo,
    aggregatedWeights,
    setFieldValue,
    values.selectedEntities,
  ]);

  useEffect(() => {
    if (
      Boolean(values.selectedProducts.length) &&
      (values.selectedPillar === MacroAdjustmentPillars.Packaging ||
        values.selectedPillar === MacroAdjustmentPillars.Manufacturing ||
        values.selectedPillar === MacroAdjustmentPillars.Logistics)
    ) {
      const fetchRtdl = async () => {
        try {
          const filtered = await getFilteredRTDLByProducts({
            data: values.selectedProducts,
          });

          if (filtered) {
            const checkEntities: MacroFilter[] = [];
            const entityIds = new Set<number>();
            let macroFilterType: keyof MacroFiltersForProducts =
              "part_materials";

            switch (values.selectedPillar) {
              case MacroAdjustmentPillars.Manufacturing:
                macroFilterType = "energy_types";
                break;
              case MacroAdjustmentPillars.Logistics:
                macroFilterType = "transport_modes";
                break;
              default:
                break;
            }

            values.selectedEntities.forEach((entity) => {
              const record = filtered.filters[macroFilterType].find(
                (e: { id: number }) => e.id === entity.id,
              );
              if (record && !entityIds.has(entity.id)) {
                checkEntities.push(entity);
                entityIds.add(entity.id);
              }
            });

            if (checkEntities.length !== values.selectedEntities.length) {
              setFieldValue("selectedEntities", checkEntities).catch((e) =>
                console.warn(e),
              );
            }
            setFilteredByProducts(filtered);
          }
        } catch (e) {
          console.warn(e);
        }
      };

      fetchRtdl().catch((error) => {
        console.warn(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFilteredRTDLByProducts,
    setFieldValue,
    values.selectedPillar,
    values.selectedProducts,
  ]);

  return (
    <Box>
      {(() => {
        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_MultiSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_PackagingWeights_SpecificSKUAdjustment:
          case MacroAdjustmentCombinations.Packaging_RecycledContent:
          case MacroAdjustmentCombinations.Packaging_NetLossRate:
          case MacroAdjustmentCombinations.Packaging_RecoveryRate:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingEur:
          case MacroAdjustmentCombinations.Logistics_DistanceTravelled:
            return (
              <Box pb={2}>
                <Box>
                  <Box mt={2} mb={1} tabIndex={0}>
                    <Typography variant={"h2"} className="text-ellipsis">
                      {t(
                        `adjustmentsPage.wizard.stepTwo.${values.selectedAdjustmentType !== MacroAdjustmentTypes.SpecificSKUAdjustment ? "selectProducts" : "selectProduct"}`,
                      )}
                    </Typography>
                  </Box>
                  <Box mb={2} tabIndex={0}>
                    <Typography variant={"body1"}>
                      {t(
                        `adjustmentsPage.wizard.stepTwo.${values.selectedAdjustmentType !== MacroAdjustmentTypes.SpecificSKUAdjustment ? "selectProductsDescription" : "selectProductDescription"}`,
                      )}
                    </Typography>
                  </Box>
                </Box>
                <ProductSelectionView
                  countriesList={scenario?.inputs?.countries || []}
                  targetYear={scenario?.inputs?.years?.target_year as number}
                  initialSelectedProducts={values.selectedProducts}
                  showBaseProducts={false}
                  updateSelectedProducts={updateSelectedProducts}
                  selectionType={
                    values.selectedAdjustmentType !==
                    MacroAdjustmentTypes.SpecificSKUAdjustment
                      ? "multiple"
                      : "single"
                  }
                  clearAllProductsHandler={() => {
                    setFieldValue("payload.filters.countries", []).catch((e) =>
                      console.log(e),
                    );
                  }}
                />
              </Box>
            );
          default:
            return (
              <ApplyFiltersView
                handleResetPageTwo={handleResetPageTwo}
                adjustmentCombination={adjustmentCombination || undefined}
                filtersData={filtersData}
                setMacroFiltersParams={setMacroFiltersParams}
                scenario={scenario}
                handleCountriesSave={handleCountriesSave}
              />
            );
        }
      })()}

      {(() => {
        switch (adjustmentCombination?.combo) {
          case MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors:
          case MacroAdjustmentCombinations.CDE_CDEFleetBreakdown:
          case MacroAdjustmentCombinations.Ingredients_IngredientsEmissionFactors:
          case MacroAdjustmentCombinations.Manufacturing_ManufacturingSourceMixAndEmissionFactors:
          case MacroAdjustmentCombinations.Logistics_LogisticsOtherVehicleMixAndEmissionFactors:
          case MacroAdjustmentCombinations.Logistics_LogisticsRoadVehicleMixAndEmissionFactors:
          case MacroAdjustmentCombinations.Packaging_PackagingEmissionFactors:
            return null;
          default:
            return (
              <SalesVolumeChartArea
                totalRTDL={totalRTDL || 0}
                filteredRTDL={
                  (values.selectedPillar === MacroAdjustmentPillars.Packaging ||
                  values.selectedPillar ===
                    MacroAdjustmentPillars.Manufacturing ||
                  values.selectedPillar === MacroAdjustmentPillars.Logistics
                    ? values.selectedProducts.length === 0
                      ? 0
                      : filteredByProducts?.filtered_rtdl
                    : filtersData?.filtered_rtdl) ??
                  (filtersData?.filtered_rtdl || 0)
                }
                targetYear={scenario?.inputs?.years?.target_year || 2030}
                filtersDataIsPending={filtersDataIsPending}
              />
            );
        }
      })()}

      {adjustmentCombination?.combo !==
        MacroAdjustmentCombinations.CDE_CDEEnergyMixAndEmissionFactors && (
        <EntitiesDropdownStepTwo
          adjustmentCombination={adjustmentCombination || undefined}
          filtersData={filtersData}
          filtersByProducts={filteredByProducts?.filters}
          handleEntitiesSave={handleEntitiesSave}
        />
      )}
    </Box>
  );
};
