import { Box, Button, Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { useMemberInfo } from "core/MemberInfoProvider/MemberInfoProvider";
import { CustomIcon, Icons, useGlobalLoader } from "components/common";
import { useEffect, useMemo } from "react";
import { usePermissions } from "core/navigation/usePermissions";
import homePageImage from "../../../assets/images/home-page-img.png";
import {
  useGetAllMacroScenariosScenariosMacroGet,
  useGetAllMicroScenariosScenariosMicroGet,
  useGetVersionHistoryVersionHistoryGet,
} from "orval/generated/endpoint";
import { Permission } from "core/navigation/permission";
import { MicroToolRoutesConfig } from "features/micro-tool";
import { MacroToolRoutesConfig } from "features/macro-tool";
import { LatestScenariosSection } from "../components/LatestScenariosSection";
import LoadingList from "components/common/LoadingList/LoadingList";
import { DataManagementRoutesConfig } from "features/data-management/navigation";
import { useNavigate } from "react-router-dom";
import useDocumentHead from "utils/useDocumentHead";

function HomePage() {
  const { t } = useTranslation(["home"]);
  useDocumentHead({
    titleAsTranslationKey: t("common:documentHeads:homepage"),
  });
  const { memberInfo, memberInfoDataIsPending } = useMemberInfo();
  const { showGlobalLoader } = useGlobalLoader();
  const { hasFunctionalAccess } = usePermissions();
  const { useHasPermission } = usePermissions();
  const navigate = useNavigate();
  const hasMicroAccessPermission = useHasPermission(
    Permission.ProductLevelAnalytics,
  );
  const hasMacroAccessPermission = useHasPermission(
    Permission.CountryLevelAnalytics,
  );

  const hasDataManagementPermission = useHasPermission(
    Permission.DataManagement,
  );

  const { data: macroScenariosData, isPending: macroScenariosDataIsPending } =
    useGetAllMacroScenariosScenariosMacroGet();

  const { data: microScenariosData, isPending: microScenarioDataIsPending } =
    useGetAllMicroScenariosScenariosMicroGet();

  const { data: versionHistoryData, isLoading: isVersionHistoryDataLoading } =
    useGetVersionHistoryVersionHistoryGet();

  const macroScenarioList = useMemo(
    () =>
      macroScenariosData
        ?.sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
        )
        .slice(0, 3) || [],
    [macroScenariosData],
  );

  const microScenarioList = useMemo(
    () =>
      microScenariosData
        ?.sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
        )
        .slice(0, 3) || [],
    [microScenariosData],
  );

  useEffect(() => {
    memberInfoDataIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [memberInfoDataIsPending, showGlobalLoader]);

  return (
    <>
      {hasFunctionalAccess && memberInfo ? (
        <Box>
          <Grid container alignItems="center" mb={4}>
            <Grid size={6}>
              <Box>
                <Typography variant="h1">
                  <span style={{ fontWeight: 400 }}>
                    {`${memberInfo.first_name}, `}
                  </span>
                  {t("home:welcomeTo")}
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "2.4rem",
                    lineHeight: "50px",
                    fontWeight: 500,
                  }}
                >
                  {t("home:appName")}
                </Typography>
              </Box>
            </Grid>
            <Grid size={6}>
              <Box sx={{ mr: "-48px", mt: "-24px" }}>
                <img
                  width="100%"
                  height="228px"
                  alt="home-page-image"
                  src={homePageImage}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              border: "1px solid #C5C5C5",
              p: 4,
              borderRadius: "15px",
            }}
          >
            <Grid container spacing={0} borderBottom="1px solid #C5C5C5" pb={2}>
              {hasMicroAccessPermission && (
                <Grid
                  size={{ sm: 12, xl: 6 }}
                  borderRight={{ sm: "none", xl: "1px solid #C5C5C5" }}
                  borderBottom={{ sm: "1px solid #C5C5C5", xl: "none" }}
                  pr={{ sm: 0, xl: 4 }}
                  pb={{ sm: 4, xl: 0 }}
                >
                  <LatestScenariosSection
                    title={t("home:microToolSection.title")}
                    description={t("home:microToolSection.description")}
                    icon={Icons.TOGGLE}
                    isPending={microScenarioDataIsPending}
                    openButtonURL={MicroToolRoutesConfig.productLevelAnalytics}
                    scenarioType="micro"
                    scenariosList={microScenarioList}
                  />
                </Grid>
              )}

              {hasMacroAccessPermission && (
                <Grid
                  size={{ sm: 12, xl: 6 }}
                  pl={{ sm: 0, xl: 4 }}
                  pt={{ sm: 4, xl: 0 }}
                >
                  <LatestScenariosSection
                    title={t("home:macroToolSection.title")}
                    description={t("home:macroToolSection.description")}
                    icon={Icons.GROW_RESPONSE}
                    isPending={macroScenariosDataIsPending}
                    openButtonURL={MacroToolRoutesConfig.countryLevelAnalytics}
                    scenarioType="macro"
                    scenariosList={macroScenarioList}
                  />
                </Grid>
              )}
            </Grid>
            <Box display="flex" justifyContent="space-between" pt={4}>
              {isVersionHistoryDataLoading ? (
                <LoadingList length={1} />
              ) : (
                <>
                  <Box display="flex">
                    <CustomIcon
                      name={Icons.DATABASE}
                      width="50px"
                      height="45px"
                      fill="#D04A02"
                      aria-hidden="true"
                    />

                    <Box>
                      <Box display="flex" alignItems="center" mb={2} mt={1}>
                        <Typography variant="h2" mr={2}>
                          {versionHistoryData?.[0].title}
                        </Typography>
                        <Chip
                          color="primary"
                          label={
                            <Typography
                              sx={{
                                fontSize: "1.1rem !important",
                                color: "#fff",
                              }}
                            >
                              {versionHistoryData?.[0].version}
                            </Typography>
                          }
                          size="medium"
                          sx={{ py: 2, borderRadius: "20px !important" }}
                        />
                      </Box>

                      <Typography sx={{ color: "#6A6A6A" }}>
                        {`${versionHistoryData?.[0].uploaded_by} .
                  ${versionHistoryData?.[0].uploaded_on}`}
                      </Typography>
                      <Typography sx={{ color: "#6A6A6A" }}>
                        {versionHistoryData?.[0].description}
                      </Typography>
                    </Box>
                  </Box>

                  {hasDataManagementPermission && (
                    <Button
                      variant="outlined"
                      sx={{ height: "40px" }}
                      endIcon={
                        <CustomIcon
                          name={Icons.ARROW_RIGHT}
                          width={"30px"}
                          fill={"#d04a02"}
                        />
                      }
                      onClick={() =>
                        navigate(DataManagementRoutesConfig.dataManagementPage)
                      }
                    >
                      {t("ExploreUnderlingData")}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default HomePage;
